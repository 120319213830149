import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import ReactModal from 'react-responsive-modal';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { AddOverlayBanner } from '../atoms/OverlayBanner';
import { FormList, FormListItem } from '../atoms/List';
import { Input } from '../atoms/Input';
import { PrimaryButton } from '../atoms/Button';
import Range from '../atoms/Range';
import { DropZone } from '../atoms/DropZone';
import ColorPicker from '../atoms/ColorPicker';
import { Link, useNavigation } from 'react-navi';
import { IdentityStore } from '../../store/IdentityProjectStore';
import { inject, observer } from 'mobx-react';
import { isApiError } from '../../helper/ResponseHelper';
import LeftArrowSvg from '../../assets/arrow_left.svg';
import { useMedia } from 'react-media';
import { MEDIA_QUERIES } from '../../constants/MediaQueries';
import ReactModalStyle from '../../constants/ReactModalStyle';
import { ReactComponent as CrossSvg } from '../../assets/crossIcon.svg';
import { Color } from '../atoms/Color';

const Modal = styled.div`
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 895px;
  padding: 35px 105px 75px;
  background-color: #3e464c;
  border-radius: 5px;
  border: none;
  box-shadow: 2px 1px 8px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  box-sizing: border-box;
`;

const WindowLeft = styled.div`
  z-index: 1;
  width: 370px;
`;

const CloseModalButton = styled.button`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  top: 15px;
  right: 15px;
  width: 15px;
  height: 15px;
  padding: 5px;
  background: no-repeat;
  border: none;
  cursor: pointer;
`;

const Image = styled.img`
  display: block;
  margin: 0 auto;
`;

const WindowRight = styled.div`
  position: absolute;
  z-index: 2;
  top: 60px;
  right: -60px;
  width: 380px;
  max-height: 655px;
  padding-bottom: 250px;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 7px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); ;
`;

const WindowRightContent = styled.div`
  padding: 0 30px;
  border-color: #ffffff;
`;

const Logo = styled.figure`
  position: relative;
  max-height: 35px;
  margin: 0;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const LeftArrowIcon = styled.img`
  margin-right: 8px;
`;

const ListItemStyles = {
  width: 'calc(50% - 15px)',
};

const BackButtonStyles = {
  display: 'flex',
  alignItems: 'center',
  marginRight: '40px',
  marginLeft: '70px',
  color: '#ffffff',
  fontWeight: 600,
  textDecoration: 'none',
};

interface Props {
  isOpen?: boolean;
  onClose?: (rpId?: string) => void;
  organizationId: string;
  identityStore?: IdentityStore;
}

type State = {
  name: string;
  origin: string;
  fileUrl?: string;
  selectedFile?: File;
};

const Header = styled.div`
  width: 100%;
  padding: 15px 30px;
  box-sizing: border-box;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const Title = styled.h2`
  margin: 50px 0 35px;
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
`;

const PreviewTitle = styled.div`
  margin-top: 165px;
  margin-bottom: 28px;
  font-size: 25px;
  font-weight: 700;
`;

const PreviewTextbox = styled.input`
  width: 100%;
  padding: 10px 40px 12px 16px;
  box-sizing: border-box;
  border: 1px solid #36596f;
  border-radius: 2px;
  font-size: 13px;
`;

const PreviewTextboxWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const ButtonCross = styled.div`
  position: absolute;
  display: flex;
  top: 50%;
  right: 12px;
  width: 12px;
  height: 12px;
  padding: 7px;
  transform: translateY(-50%);
  cursor: pointer;
`;

export const RelyingPartyBrandComponent = (props: Props) => {
  const [state, setState] = useState<State>({
    name: '',
    origin: '',
    fileUrl: undefined,
  });
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [name, setName] = useState('');
  const [brandColor, setBrandColor] = useState('#707070');
  const [secondaryColor, setSecondaryColor] = useState('#c1c1c1');
  const [textColor, setTextColor] = useState('#000000');
  const [roundedCorners, setRoundedCorners] = useState(2);
  const [logoFileUrl, setLogoFileUrl] = useState<string | undefined>(undefined);
  const [faviconFileUrl, setFaviconFileUrl] = useState<string | undefined>(undefined);
  const [buttonTextColor, setButtonTextColor] = useState<'light' | 'dark'>('light');
  const [logoFileSelected, setLogoFileSelected] = useState<File | undefined>(undefined);
  // @ts-ignore
  const [faviconFileSelected, setFaviconFileSelected] = useState<File | undefined>(undefined);
  const [hasError, setHasError] = useState({
    name: false,
    logo: false,
  });

  const { organizationId } = props;
  const navigation = useNavigation();
  const isSmallScreen = useMedia({ query: MEDIA_QUERIES.tablet });

  const handleTextboxClear = () => {
    inputRef.current.value = '';
    return inputRef.current.focus();
  };

  const onLogoFileChanged = (file?: File, fileUrl?: string) => {
    setLogoFileUrl(fileUrl);
    setLogoFileSelected(file);
  };

  const onFaviconFileChanged = (file?: File, fileUrl?: string) => {
    setFaviconFileUrl(fileUrl);
    setFaviconFileSelected(file);
  };

  const onClose = () => {
    setState({
      ...state,
      name: '',
      origin: '',
      fileUrl: undefined,
      selectedFile: undefined,
    });

    navigation.navigate(`/organization/${organizationId}/relyingParty`);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setHasError({
      name: false,
      logo: false,
    });

    if (name.length > 0 && logoFileUrl && logoFileUrl.length > 0) {
      const logoImageResponse = await props.identityStore?.uploadImage(logoFileSelected!);
      if (isApiError(logoImageResponse)) {
        logoImageResponse?.code === 413
          ? toast.error(`Logo too large: max. 1 MB please`)
          : toast.error('Error creating Identity');
        return;
      }
      let faviconImageUrl: string | undefined = undefined;
      if (faviconFileUrl !== undefined && faviconFileSelected !== undefined) {
        const faviconImageResponse = await props.identityStore?.uploadImage(faviconFileSelected!);
        if (isApiError(faviconImageResponse)) {
          faviconImageResponse?.code === 413
            ? toast.error(`Favicon too large: max. 1 MB please`)
            : toast.error('Error creating Identity');
          return;
        }
        faviconImageUrl = faviconImageResponse!.url;
      }

      const response = await props.identityStore?.create(organizationId, {
        name,
        brand: {
          brand_color: brandColor,
          secondary_color: secondaryColor,
          text_color: textColor,
          border_radius: roundedCorners,
          logo_url: logoImageResponse?.url,
          favicon_url: faviconImageUrl,
        },
      });
      if (!isApiError(response)) {
        navigation.navigate(`/organization/${organizationId}/relyingParty/${response?.id}/dashboard`);
      } else {
        toast.error('Error creating Identity');
      }
    } else {
      setHasError({
        name: !!!name,
        logo: !!!logoFileSelected,
      });
    }
  };

  useEffect(() => {
    const color = brandColor.substring(1); // strip #
    const rgb = parseInt(color, 16); // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff; // extract red
    const g = (rgb >> 8) & 0xff; // extract green
    const b = (rgb >> 0) & 0xff; // extract blue
    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

    if (luma < 200) {
      setButtonTextColor('light');
    } else {
      setButtonTextColor('dark');
    }
  }, [brandColor]);

  return (
    <ReactModal open={true} onClose={onClose} showCloseIcon={false} center={true} styles={ReactModalStyle({isSmallScreen, customStyles: {width: 'auto'}})} blockScroll>
      <AddOverlayBanner />
      <Modal>
        <WindowLeft>
          <CloseModalButton onClick={onClose}>
            <CrossSvg fill={Color.iconGrey} width={15} height={15} />
          </CloseModalButton>
          <Title>Create new Identity project</Title>
          <form onSubmit={() => {}}>
            <FormList style={{ margin: '0' }}>
              <FormListItem>
                <Input
                  label="Project name*"
                  placeholder="Name"
                  name="projectName"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                  error={hasError.name ? 'Required' : undefined}
                />
              </FormListItem>
              <ListWrapper>
                <FormListItem style={ListItemStyles}>
                  <DropZone
                    label="Logo*"
                    onFileChanged={onLogoFileChanged}
                    fileUrl={logoFileUrl}
                    error={hasError.logo && 'Required'}
                  />
                </FormListItem>
                <FormListItem style={ListItemStyles}>
                  <DropZone
                    label="Favicon"
                    onFileChanged={onFaviconFileChanged}
                    fileUrl={faviconFileUrl}
                    acceptTypes={['image/png', 'image/jpeg', '.ico']}
                  />
                </FormListItem>
                <FormListItem style={ListItemStyles}>
                  <ColorPicker defaultColor="#707070" label="Brand color" onChange={(color) => setBrandColor(color)} />
                </FormListItem>
                <FormListItem style={ListItemStyles}>
                  <ColorPicker
                    defaultColor="#c1c1c1"
                    label="Secondary color"
                    onChange={(color) => setSecondaryColor(color)}
                  />
                </FormListItem>
                <FormListItem style={ListItemStyles}>
                  <ColorPicker defaultColor="#000000" label="Text color" onChange={(color) => setTextColor(color)} />
                </FormListItem>
                <FormListItem style={ListItemStyles}>
                  <Range
                    label="Rounded corners"
                    start={0}
                    end={25}
                    defaultValue={2}
                    onChange={(val) => setRoundedCorners(val)}
                    customStyles={{ marginBottom: '20px' }}
                  />
                </FormListItem>
              </ListWrapper>
              <FormListItem style={{ display: 'flex' }}>
                <Link href={`/organization/${organizationId}/relyingParty`} style={BackButtonStyles}>
                  <LeftArrowIcon src={LeftArrowSvg} alt="left arrow icon" />
                  Back
                </Link>
                <PrimaryButton onClick={handleSubmit}>Create</PrimaryButton>
              </FormListItem>
            </FormList>
          </form>
        </WindowLeft>
        <WindowRight>
          <Header style={{ height: '65px', boxSizing: 'border-box', backgroundColor: secondaryColor }}>
            {logoFileUrl ? (
              <Logo>
                <img src={logoFileUrl} alt="Logo" style={{ maxWidth: '100%', height: '35px', objectFit: 'contain' }} />
              </Logo>
            ) : null}
          </Header>
          <WindowRightContent>
            <PreviewTitle style={{ color: textColor }}>Sign in to {name}</PreviewTitle>
            <PreviewTextboxWrapper>
              <PreviewTextbox
                type="text"
                defaultValue="john.doe@mail.com"
                ref={inputRef}
                style={{ color: textColor, borderRadius: roundedCorners, borderColor: textColor, height: '50px' }}
              />
              <ButtonCross onClick={handleTextboxClear}>
                <CrossSvg fill={textColor} />
              </ButtonCross>
            </PreviewTextboxWrapper>
            <PrimaryButton
              style={{
                borderRadius: `${roundedCorners}px`,
                backgroundColor: brandColor,
                boxShadow: 'none',
                color: buttonTextColor === 'light' ? '#ffffff' : '#000000',
              }}
            >
              Sign in
            </PrimaryButton>
          </WindowRightContent>
        </WindowRight>
      </Modal>
    </ReactModal>
  );
};

export const RelyingPartyBrand = inject('identityStore')(observer(RelyingPartyBrandComponent));
