import React, { useEffect, useState } from 'react';
import { useNavigation } from 'react-navi';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { NoStylingButton, PrimaryButton } from '../../../atoms/Button';
import { SettingsContainer } from '../../../atoms/Container';
import { Color } from '../../../atoms/Color';
import { ReactComponent as ArrowIcon } from '../../../../assets/arrow_left_thin.svg';
import { FormList, FormListItem } from '../../../atoms/List';
import AlertBox from '../../../atoms/AlertBox';
import { Input } from '../../../atoms/Input';
import { inject, observer } from 'mobx-react';
import { OidcClientStore } from '../../../../store/OidcClientStore';
import { isApiError } from '../../../../helper/ResponseHelper';
import { SettingsDescriptionLink } from '../../../atoms/Link';
import { CopyableText } from '../../../atoms/CopyableText';
import { CustomDropdown } from '../../../atoms/CustomDropdown';

type Props = {
  appId: string;
  organizationId: string;
  relyingPartyId: string;
  oidcClientStore?: OidcClientStore;
};

const Container = styled(SettingsContainer)`
  width: 100%;
  margin-bottom: 25px;
  padding: 35px 30px;
  box-sizing: border-box;
  color: #ffffff;
  text-align: left;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const BackButtonContainer = styled(NoStylingButton)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90px;
  padding: 7px 22px 8px 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
  background: ${Color.darkBlue};
  border-radius: 20px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
`;

const LeftArrowIcon = styled(ArrowIcon)``;

const ContainerTitle = styled.h2`
  margin: 0 0 20px;
  font-size: 18px;
  font-weight: 700;
`;

const LeftColumn = styled.div`
  min-width: 315px;
  max-width: 315px;
  margin-right: 25px;
  font-size: 14px;
  line-height: 22px;
`;

const Textbox = styled(Input)`
  width: 100%;
`;

const SubmitButton = styled(PrimaryButton)`
  display: block;
  max-width: 160px;
  margin-left: auto;
`;

const RightColumn = styled.div`
  width: 100%;
  max-width: 500px;
`;

const List = styled(FormList)`
  margin: 0;

  & > {
    padding: 0;
  }
`;

const ClientIdWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const ClientIdLabel = styled.span`
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
`;

const InputFootnote = styled.aside`
  margin-top: 10px;
  color: #bbc4cc;
  font-size: 14px;
  line-height: 22px;
`;

const EndpointWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 35px;
`;

const EndpointLabel = styled.span`
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
`;

const DeleteAppContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px;
  border: 2px solid ${Color.primaryRed};
`;

const DeleteAppButton = styled(PrimaryButton)`
  position: relative;
  max-width: 160px;
`;

const DeleteAppTitle = styled(ContainerTitle)`
  margin: 0;
`;

const TagWrapper = styled.div`
  margin-bottom: 20px;
`;

const TagsLabel = styled.div`
  margin-bottom: 10px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
`;

const Tags = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const Tag = styled.span`
  margin-right: 7px;
  margin-bottom: 10px;
  padding: 2px 8px;
  background: #272f34;
  border-radius: 3px;
  color: #bbc4cc;
  font-family: 'IBM Plex Mono';
  font-size: 14px;
  font-weight: 400;
`;

const IdentityUserManagementCredentialDetailsComponent = ({
  appId,
  organizationId,
  relyingPartyId,
  oidcClientStore,
}: Props) => {
  const navigation = useNavigation();
  const [tokenEndpoint, setTokenEndpoint] = useState<string>();
  const [audience, setAudience] = useState<string>();
  const [appInfoError, setAppInfoError] = useState<string>();
  const [newCredentialName, setNewCredentialName] = useState<string>();
  const [isAppInfoLoading, setIsAppInfoLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [tokenEndpointAuth, setTokenEndpointAuth] = useState<string>();
  const [newGrantTypes, setNewGrantTypes] = useState<string[]>();

  const tokenEndpointAuthenticationMethod = [
    {
      label: 'client_secret_basic',
      value: 'client_secret_basic',
    },
    {
      label: 'client_secret_post',
      value: 'client_secret_post',
    },
  ];

  const handleGoBack = () => {
    const appsPath = navigation.getCurrentValue().url.pathname.split('/um/')[0];

    navigation.navigate(`${appsPath}/um`);
  };

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsAppInfoLoading(true);
    const result = await oidcClientStore?.update(organizationId, relyingPartyId, appId, {
      client_name: newCredentialName,
      token_endpoint_auth_method: tokenEndpointAuth,
    });
    if (isApiError(result)) {
      setAppInfoError('Error updating details');
    } else {
      toast.success('Settings updated successful');
    }
    setIsAppInfoLoading(false);
  };

  const isAppConfigChanged = () => {
    return (
      newCredentialName !== oidcClientStore?.client?.client_name ||
      tokenEndpointAuth !== oidcClientStore?.client?.token_endpoint_auth_method
    );
  };

  const handleDeleteApp = async () => {
    // TODO: ask the user if he definitely wants to delete the app/oidc client
    setIsDeleteLoading(true);
    const result = await oidcClientStore?.delete(organizationId, relyingPartyId, appId);
    setIsDeleteLoading(false);
    if (!isApiError(result)) {
      handleGoBack();
    } else {
      setAppInfoError('Could not delete OIDC client');
    }
  };

  useEffect(() => {
    oidcClientStore
      ?.get(organizationId, relyingPartyId, appId)
      .then((result) => {
        if (!isApiError(result)) {
          setTokenEndpointAuth(result.token_endpoint_auth_method);
          setNewGrantTypes(result?.grant_types);
          setNewCredentialName(result?.client_name);
          setAudience(result?.audience !== undefined && result.audience.length > 0 ? result.audience[0] : undefined);
        } else {
          console.error(result.message);
          // handleGoBack()
        }
      })
      .catch((error) => {
        console.error(error);
        handleGoBack();
      });
  }, []);

  useEffect(() => {
    oidcClientStore
      ?.getOidcConfig(organizationId, relyingPartyId)
      .then((result) => {
        if (!isApiError(result)) {
          setTokenEndpoint(result.token_endpoint);
        } else {
          console.error(result.message);
          // handleGoBack()
        }
      })
      .catch((error) => {
        console.error(error);
        handleGoBack();
      });
  }, []);

  return (
    <>
      <BackButtonContainer onClick={handleGoBack}>
        <LeftArrowIcon fill="#ffffff" />
        Back
      </BackButtonContainer>
      <Container>
        <ContainerTitle>User Management API: Credential details</ContainerTitle>
        <FlexContainer>
          <LeftColumn>
            The User Management API can be accessed with these API credentials. Please refer to the{' '}
            <SettingsDescriptionLink href="https://docs.hanko.io/identity/um#admin" target="_blank">
              docs
            </SettingsDescriptionLink>{' '}
            on how to use the API.
          </LeftColumn>
          <RightColumn>
            <List style={{ position: 'relative', padding: 0 }}>
              {!!appInfoError ? (
                <FormListItem>
                  <AlertBox type="error">{appInfoError}</AlertBox>
                </FormListItem>
              ) : null}

              <FormListItem>
                <Textbox
                  label="Credential name"
                  name="newCredentialName"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewCredentialName(e.target.value)}
                  placeholder="Name"
                  value={newCredentialName || oidcClientStore?.client?.client_name}
                />
              </FormListItem>
              <FormListItem>
                <ClientIdWrapper>
                  <ClientIdLabel>Client ID</ClientIdLabel>
                  <CopyableText style={{ height: '45px' }}>{oidcClientStore?.client?.client_id || ''}</CopyableText>
                </ClientIdWrapper>
                <InputFootnote>
                  The client secret has been shown when the credentials were created and cannot be retrieved anymore. If
                  you have lost the secret, please create new credentials.
                </InputFootnote>
              </FormListItem>
              <FormListItem>
                <CustomDropdown
                  label="Token endpoint authentication method"
                  name="tokenEndpointAuth"
                  onChange={(option) => setTokenEndpointAuth(option.value)}
                  options={tokenEndpointAuthenticationMethod}
                  style={{ marginTop: '35px' }}
                  value={
                    tokenEndpointAuth === undefined
                      ? undefined
                      : tokenEndpointAuthenticationMethod.find((method) => method.value === tokenEndpointAuth)
                  }
                />
                <InputFootnote>
                  Defines whether the the client credentials have to be included in the HTTP Authorization header
                  (basic) or in the request body (post)
                </InputFootnote>
              </FormListItem>
              <FormListItem>
                <EndpointWrapper>
                  <EndpointLabel>Token endpoint</EndpointLabel>
                  <CopyableText style={{ height: '45px' }}>{tokenEndpoint || ''}</CopyableText>
                </EndpointWrapper>
              </FormListItem>
              <FormListItem>
                <EndpointWrapper>
                  <EndpointLabel>Audience</EndpointLabel>
                  <CopyableText style={{ height: '45px' }}>{audience || ''}</CopyableText>
                </EndpointWrapper>
              </FormListItem>
              <FormListItem>
                <TagWrapper>
                  <TagsLabel>Supported grant types</TagsLabel>
                  <Tags>
                    {newGrantTypes
                      ? newGrantTypes.map((responseTypes, index) => <Tag key={index}>{responseTypes}</Tag>)
                      : null}
                  </Tags>
                </TagWrapper>
              </FormListItem>
              <FormListItem>
                <SubmitButton
                  type="submit"
                  onClick={handleSave}
                  disabled={!isAppConfigChanged() || isDeleteLoading}
                  isLoading={isAppInfoLoading}
                >
                  Save changes
                </SubmitButton>
              </FormListItem>
            </List>
          </RightColumn>
        </FlexContainer>
      </Container>
      <DeleteAppContainer>
        <DeleteAppTitle>Delete credential</DeleteAppTitle>
        <DeleteAppButton
          type="button"
          onClick={handleDeleteApp}
          disabled={isAppInfoLoading}
          isLoading={isDeleteLoading}
        >
          Delete credential
        </DeleteAppButton>
      </DeleteAppContainer>
    </>
  );
};

export const IdentityUserManagementCredentialDetails = inject('oidcClientStore')(
  observer(IdentityUserManagementCredentialDetailsComponent)
);
