import React, { useState } from 'react';
import { useMedia } from 'react-media';
import ReactModal from 'react-responsive-modal';
import styled from 'styled-components';
import { MEDIA_QUERIES } from '../../constants/MediaQueries';
import ReactModalStyle from '../../constants/ReactModalStyle';
import { NoStylingButton, PrimaryButton } from '../atoms/Button';

const Wrapper = styled.div`
  width: 400px;
  box-sizing: border-box;
  color: #ffffff;
`;

const Title = styled.h2`
  margin: 0 0 35px 0;
  font-size: 24px;
  font-weight: 700;
`;

const Text = styled.p`
  color: #ffffff;
  margin-bottom: 50px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const CancelButton = styled(NoStylingButton)`
  margin-right: 30px;
  background: none;
  border: none;
  color: #e1e7ec;
  font-weight: 600;
`;

const SubmitButton = styled(PrimaryButton)`
  max-width: 215px;
`;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

const DeleteAppConfirmationModal = ({ isOpen, onClose, onSubmit }: Props) => {
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const isSmallScreen = useMedia({ query: MEDIA_QUERIES.tablet });

  const handleOnSubmit = () => {
    setIsDeleteLoading(true);
    onSubmit();
  };

  return (
    <ReactModal
      open={isOpen}
      onClose={onClose}
      center={true}
      styles={ReactModalStyle({ isSmallScreen, customStyles: { width: '750px', padding: '80px 125px' } })}
      blockScroll
    >
      <Wrapper>
        <Title>Delete app</Title>
        <Text>Are you sure you want to delete this app?</Text>
        <ButtonWrapper>
          <CancelButton type="button" onClick={onClose}>
            Cancel
          </CancelButton>
          <SubmitButton type="submit" onClick={handleOnSubmit} isLoading={isDeleteLoading}>
            Delete
          </SubmitButton>
        </ButtonWrapper>
      </Wrapper>
    </ReactModal>
  );
};

DeleteAppConfirmationModal.defaultProps = {
  isOpen: false,
};

export { DeleteAppConfirmationModal };
