import React from "react";
import styled from "styled-components";
import { NoStylingButton } from './Button'

const BannerBackground = styled.div({
    borderRadius: '49px',
    height: 'fit-content',
    width: 'fit-content',
    padding: '5px 15px 5px 5px',
    display: 'flex',
    alignItems: "center",
})

export const Badge = styled.span({
    borderRadius: '30px',
    padding: '3px 11px',
    color: '#FFFFFF',
    fontSize: '13px',
    height: 'fit-content'
})

const Description = styled.span({
    fontSize: '13px',
    color: '#FFFFFF',
    marginLeft: '7px',
    marginRight: '14px',
})

const Button = styled(NoStylingButton)({
    color: '#FFFFFF',
    fontSize: '13px',
    fontWeight: 500,
    textDecoration: 'underline'
})

interface BadgeProps {
    style?: React.CSSProperties
}

export const ProBadge = (props: BadgeProps) => {
    return <Badge style={{backgroundColor: '#50C9BA', ...props.style}}>PRO</Badge>
}
export const FreeBadge = (props: BadgeProps) => {
    return <Badge style={{backgroundColor: '#818A93', ...props.style}}>FREE</Badge>
}


interface CurrentSubscriptionProps {
    isPro: boolean
    onClick: () => void
    style?: React.CSSProperties
}

export const UpgradeBanner = (props: CurrentSubscriptionProps) => {
    return <BannerBackground style={{backgroundColor: '#545B61', ...props.style}}>
        <FreeBadge/>
        <Description>Limited to 100 users.</Description>
        <Button onClick={props.onClick}>Upgrade now</Button>
    </BannerBackground>
}

export const CurrentSubscriptionBanner = (props: CurrentSubscriptionProps) => {
    const backgroundColor = props.isPro ? "rgba(80, 201, 186, 0.3)" : "#545B61"
    const badge = props.isPro ? <ProBadge/> : <FreeBadge/>
    const descriptionText = props.isPro ? "Subscription" : "No subscription"
    const buttonText = props.isPro ? "Go to billing portal" : "Upgrade now"
    return <BannerBackground style={{backgroundColor: backgroundColor, ...props.style}}>
        {badge}
        <Description>{descriptionText}</Description>
        <Button onClick={props.onClick}>{buttonText}</Button>
    </BannerBackground>
}