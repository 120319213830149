import React, { useEffect, useState } from 'react';
import ReactModal from 'react-responsive-modal';
import { useNavigation } from 'react-navi';
import { AppDetailsResponse } from '../../types/relying_party/RelyingPartyApps';
import { Color } from '../atoms/Color';

import NewAppForm from './add_new_app/NewAppForm';
import NewAppSuccess from './add_new_app/NewAppSuccess';
import { OidcClientStore } from '../../store/OidcClientStore';
import { inject, observer } from 'mobx-react';
import { isApiError } from '../../helper/ResponseHelper';
import { useMedia } from 'react-media';
import { MEDIA_QUERIES } from '../../constants/MediaQueries';
import ReactModalStyle from '../../constants/ReactModalStyle';

interface Props {
  appDetails?: {
    appId: string;
    appUrl: string;
    name: string;
    clientUri: string;
    appType: string;
  };
  isOpen: boolean;
  updateSecretId?: string;
  onClose: () => void;
  onNewAppSuccess: () => void;
  organizationId: string;
  relyingPartyId: string;
  oidcClientStore?: OidcClientStore;
}

type NewAppDetails = {
  clientId: string;
  clientSecret: string;
};

type HandleCreateApp = {
  appName: string;
  appType: string;
  appUrl: string;
  redirectUri: string[];
};

const AddNewAppModalComponent = (props: Props) => {
  const { isOpen, onNewAppSuccess, organizationId, relyingPartyId } = props;
  const [currentStep, setCurrentStep] = useState<'APP_FORM' | 'APP_SUCCESS'>(
    props.updateSecretId ? 'APP_SUCCESS' : 'APP_FORM'
  );
  const [newAppDetails, setNewAppDetails] = useState<NewAppDetails>();
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const navigation = useNavigation();
  const isSmallScreen = useMedia({ query: MEDIA_QUERIES.tablet });

  const onCancel = () => {
    props.onClose();
  };

  const handleCreateApp = async ({ appName, appType, appUrl, redirectUri }: HandleCreateApp) => {
    if (props.updateSecretId) {
      setIsModalLoading(true);

      const result = await props.oidcClientStore?.regenerateSecret(
        organizationId,
        relyingPartyId,
        props.updateSecretId
      );

      if (!isApiError(result)) {
        onNewAppSuccess();
        setNewAppDetails({
          clientId: result?.client_id ?? '',
          clientSecret: result?.client_secret ?? '',
        });
        setCurrentStep('APP_SUCCESS');
        return setIsModalLoading(false);
      } else {
        setError(result.message);
      }
    } else {
      setError(undefined);
      setIsModalLoading(true);

      const result = await props.oidcClientStore?.create(organizationId, relyingPartyId, {
        client_name: appName,
        metadata: {
          app_type: appType,
        },
        redirect_uris: redirectUri,
        client_uri: appUrl,
        response_types: ['code'],
        grant_types: ['authorization_code', 'refresh_token'],
        scope: 'openid profile email offline_access',
        token_endpoint_auth_method: appType === 'mobile' ? 'none' : undefined,
      });

      setIsModalLoading(false);

      if (!isApiError(result)) {
        onNewAppSuccess();
        setNewAppDetails({
          clientId: result?.client_id ?? '',
          clientSecret: appType === 'web' ? result?.client_secret ?? '' : '',
        });

        if (appType === 'mobile') {
          return redirectToDetails(result?.client_id);
        } else {
          setCurrentStep('APP_SUCCESS');
          return setIsModalLoading(false);
        }
      } else {
        setError(result.message);
      }
    }
  };

  const redirectToDetails = (clientId?: string) => {
    navigation.navigate(
      `/organization/${props.organizationId}/relyingParty/${props.relyingPartyId}/apps/${
        clientId ? clientId : newAppDetails?.clientId
      }`
    );
  };

  const handleModalReset = () => {
    setTimeout(() => {
      setCurrentStep('APP_FORM');
    }, 300);
    onCancel();
  };

  const handleModalResetAndRedirect = () => {
    handleModalReset();
    redirectToDetails();
  };

  const renderModalView = () => {
    if (props.updateSecretId && props.isOpen) {
      return (
        <NewAppSuccess
          updateSecretId={props.updateSecretId}
          onClose={handleModalResetAndRedirect}
          clientId={newAppDetails?.clientId}
          clientSecret={newAppDetails?.clientSecret}
        />
      );
    }

    switch (currentStep) {
      case 'APP_FORM':
        return (
          <NewAppForm
            isLoading={isModalLoading}
            onCreateApp={handleCreateApp}
            onModalClose={handleModalReset}
            error={error}
          />
        );
      case 'APP_SUCCESS':
        return (
          <NewAppSuccess
            updateSecretId={props.updateSecretId}
            onClose={handleModalResetAndRedirect}
            clientId={newAppDetails?.clientId}
            clientSecret={newAppDetails?.clientSecret}
          />
        );

      default:
        return (
          <NewAppForm
            isLoading={isModalLoading}
            onCreateApp={handleCreateApp}
            onModalClose={handleModalReset}
            error={error}
          />
        );
    }
  };

  useEffect(() => {
    if (isOpen && props.updateSecretId) {
      handleCreateApp({
        appName: props?.appDetails?.name || '',
        appType: props?.appDetails?.appType || '',
        appUrl: props?.appDetails?.appUrl || '',
        redirectUri: [props?.appDetails?.clientUri || ''],
      });
    }
  }, [isOpen, props.updateSecretId]);

  return (
    <ReactModal
      open={isOpen}
      onClose={handleModalReset}
      center={true}
      styles={ReactModalStyle({ isSmallScreen, customStyles: { width: '750px', padding: '80px 125px' } })}
      blockScroll
    >
      {renderModalView()}
    </ReactModal>
  );
};

export const AddNewAppModal = inject('oidcClientStore')(observer(AddNewAppModalComponent));
