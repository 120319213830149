import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import ReactModal from "react-responsive-modal";
import { OidcClientStore } from "../../../../store/OidcClientStore";
import styled from "styled-components";
import { FormList, FormListItem } from "../../../atoms/List";
import { Input } from "../../../atoms/Input";
import { NoStylingButton, PrimaryButton } from "../../../atoms/Button";
import { isApiError } from "../../../../helper/ResponseHelper";
import { toast } from "react-toastify";
import NewAppSuccess from "../../add_new_app/NewAppSuccess";
import { useNavigation } from "react-navi";
import { useMedia } from "react-media";
import { MEDIA_QUERIES } from "../../../../constants/MediaQueries";
import ReactModalStyle from "../../../../constants/ReactModalStyle";

const Container = styled.div({
    color: '#FFFFFF',
    width: '495px'
})

const Title = styled.h2`
  margin: 0 0 45px 0;
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
`;

const Textbox = styled(Input)`
  margin-bottom: 35px;
`;

const SmallText = styled.small`
  display: block;
  margin-top: 10px;
  color: #bbc4cc;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;

const CancelButton = styled(NoStylingButton)`
  margin-right: 30px;
  background: none;
  border: none;
  color: #e1e7ec;
  font-weight: 600;
`;

const SubmitButton = styled(PrimaryButton)`
  max-width: 215px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

type AppDetails = {
    clientId: string,
    clientSecret: string
}

interface Props {
    relyingPartyId: string
    organizationId: string
    oidcClientStore?: OidcClientStore
    isOpen: boolean
    onClose: () => void
}

const AddNewCredentialModalComponent = (props: Props) => {
    const [credentialName, setCredentialName] = useState('')
    const [currentStep, setCurrentStep] = useState<'CREDENTIAL_CREATE' | 'CREDENTIAL_CREATION_SUCCESS'>('CREDENTIAL_CREATE')
    const [newAppDetails, setNewAppDetails] = useState<AppDetails | undefined>(undefined)
    const [isCreateLoading, setIsCreateLoading] = useState(false)
    const navigation = useNavigation()
    const isSmallScreen = useMedia({ query: MEDIA_QUERIES.tablet });

    const onClose = () => {
        setCredentialName('')
        props.onClose()
    }

    const onSuccessClose = () => {
        setCredentialName('')
        redirectToDetails()
    }

    const redirectToDetails = () => {
        navigation.navigate(
            `/organization/${props.organizationId}/relyingParty/${props.relyingPartyId}/um/${newAppDetails?.clientId}`
        );
    };

    const handleSubmit = async () => {
        setIsCreateLoading(true)
        const oidcConfigResponse = await props.oidcClientStore?.getOidcConfig(props.organizationId, props.relyingPartyId)
        if (isApiError(oidcConfigResponse)) {
            setIsCreateLoading(false)
            toast.error("Error creating credential")
            return
        }
        const response = await props.oidcClientStore?.create(props.organizationId, props.relyingPartyId, {
            client_name: credentialName,
            grant_types: ['client_credentials'],
            token_endpoint_auth_method: 'client_secret_basic',
            audience: [oidcConfigResponse?.issuer ?? ''],
            metadata: {
                app_type: 'credential'
            }
        })
        if (!isApiError(response)) {
            setNewAppDetails({
                clientId: response?.client_id ?? '',
                clientSecret: response?.client_secret ?? ''
            })
            setCurrentStep('CREDENTIAL_CREATION_SUCCESS')
        } else {
            toast.error("Error creating credential")
            return setIsCreateLoading(false)
        }
        setIsCreateLoading(false)
    }

    const modalContent = () => {
        switch (currentStep) {
            case "CREDENTIAL_CREATE":
                return <form onSubmit={(e: React.FormEvent) => e.preventDefault()}>
                    <FormList>
                        <FormListItem>
                            <Title>Create new API credential</Title>
                        </FormListItem>
                        <FormListItem>
                            <Textbox
                                label="Application name"
                                name="appName"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCredentialName(e.target.value)}
                                placeholder='e.g. "User Dashboard"'
                                value={credentialName}
                            />
                            <SmallText>
                                Set a name for the credential to remember where you use it.
                            </SmallText>
                        </FormListItem>
                        <FormListItem>
                            <ButtonWrapper>
                                <CancelButton type="button" onClick={onClose}>
                                    Cancel
                                </CancelButton>
                                <SubmitButton type="submit" onClick={handleSubmit} isLoading={isCreateLoading}>
                                    Create
                                </SubmitButton>
                            </ButtonWrapper>
                        </FormListItem>
                    </FormList>
                </form>
            case "CREDENTIAL_CREATION_SUCCESS":
                return <NewAppSuccess updateSecretId={undefined}
                    onClose={onSuccessClose}
                    clientId={newAppDetails?.clientId}
                    clientSecret={newAppDetails?.clientSecret} />
        }
    }

    return (
      <ReactModal
        onClose={onClose}
        open={props.isOpen}
        styles={ReactModalStyle({ isSmallScreen, customStyles: { maxWidth: '750px', padding: '80px 125px' } })}
        center={true}
        blockScroll
      >
        <Container>{modalContent()}</Container>
      </ReactModal>
    );
}

export const AddNewCredentialModal = inject('oidcClientStore')(observer(AddNewCredentialModalComponent))