import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import CopyToClipboardButton from './CopyToClipboardButton';

const Wrapper = styled.div<{ backgroundColor?: string }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 52px;
  padding: 0 12px 0 22px;
  border-radius: 4px;
  background: ${({ backgroundColor }) => backgroundColor ?? '#545b61'};
  box-sizing: border-box;
`;

const TextWrapper = styled.input<{ backgroundColor?: string }>`
  width: 100%;
  height: 100%;
  padding: 0;
  overflow: hidden;
  background: ${({ backgroundColor }) => backgroundColor ?? '#545b61'};
  border: none;
  color: #ffffff;
  font-family: 'IBM Plex Mono', monospace;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  outline: none;

  &:focus {
    overflow: visible;
    text-overflow: initial;
  }
`;

interface Props {
  backgroundColor?: string;
  children: string;
  style?: CSSProperties;
}

export const CopyableText = (props: Props) => {
  return (
    <Wrapper style={props.style} backgroundColor={props.backgroundColor}>
      <TextWrapper readOnly value={props.children} backgroundColor={props.backgroundColor} />
      <CopyToClipboardButton value={props.children} />
    </Wrapper>
  );
};
