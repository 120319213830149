import React, { Suspense, useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';

import { RelyingPartyStore } from '../../../store/RelyingPartyStore';
import { OrganizationStore } from '../../../store/OrganizationStore';
import { StatisticsStore } from '../../../store/StatisticsStore';

import { SettingsContainer } from '../../atoms/Container';
import { SettingsTitle } from '../../atoms/Texts';
import { TenantStatus } from '../TenantStatusComponent';
import { RelyingPartyContextMenu } from '../../molecules/RelyingPartyContextMenu';

import { CurrentSubscriptionBanner, UpgradeBanner } from '../../atoms/SubscriptionBadge';
import { UpgradeModal } from '../UpgradeModal';
import { UpgradeSuccessModal } from '../UpgradeSuccessModal';
import { GET_BILLING_PORTAL_URL } from '../../../constants/Url';
import { CopyableText } from '../../atoms/CopyableText';
import LoadingWheel from '../../atoms/LoadingWheel';
import DashboardChart from '../DashboardChart';

const Number = styled.span({
  textAlign: 'left',
  fontSize: '36px',
  fontWeight: 300,
  color: '#FFFFFF',
});

const NumberContainer = styled(SettingsContainer)({
  width: '100%',
  marginRight: '25px',
  ':last-child': {
    marginRight: 0,
  },
});

const UpgradeBannerContainer = styled.div({
  marginBottom: '25px',
});

const NumbersContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '20px',
  width: '100%',
});

const TopContainerWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  boxSizing: 'border-box',
  alignItems: 'stretch',
});

const TopContainer = styled(SettingsContainer)({
  display: 'flex',
  width: '100%',
  boxSizing: 'border-box',
  alignItems: 'stretch',
  position: 'relative',
  justifyContent: 'space-between',
  height: 'auto',
  color: '#FFFFFF',
  textAlign: 'left',
  marginRight: '20px',
  padding: '35px 30px',
  ':last-child': {
    marginRight: 0,
  },
});

const EmptyImage = styled.div({
  width: '108px',
  height: '108px',
  backgroundColor: '#FFFFFF',
  borderRadius: '50%',
  boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.25)',
});

const Image = styled.img({
  width: '108px',
  height: '108px',
  objectFit: 'contain',
  borderRadius: '50%',
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.25)',
});

const ProjectNameWrapper = styled.div({
  marginLeft: '25px',
});

const ProjectName = styled.h1({
  margin: '0',
  fontSize: '20px',
  fontWeight: 600,
});

const CurrentSubscriptionBannerWrapper = styled.div({
  marginTop: '-13px',
});

const TenantUrlWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '35px',
});

const LoadingContainer = styled.div({
  position: 'relative',
  width: '100%',
  height: '270px',
  marginTop: '10px',
  backgroundColor: '#545b61',
  borderRadius: '5px',
});

interface Props {
  organizationStore?: OrganizationStore;
  relyingPartyStore?: RelyingPartyStore;
  statisticsStore?: StatisticsStore;
  endTime: moment.Moment;
  startTime: moment.Moment;
}

const ApiDashboardComponent = (props: Props) => {
  const { statisticsStore, organizationStore, relyingPartyStore, endTime, startTime } = props;
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [tenantStatus, setTenantStatus] = useState<any>();

  useEffect(() => {
    return function cleanup() {
      statisticsStore?.setStatsToZero();
    };
  }, [statisticsStore]);

  useEffect(() => {
    const uri = window.location.toString();
    if (uri.indexOf('#') > 0) {
      setIsSuccessModalOpen(true);
    }
  }, []);

  useEffect(() => {
    statisticsStore?.getActiveUserPerDay(
      'api',
      organizationStore?.organization?.id ?? '',
      relyingPartyStore?.relyingParty?.id ?? '',
      startTime.toISOString(),
      endTime.toISOString()
    );
    statisticsStore?.getRequestsPerDay(
      'api',
      organizationStore?.organization?.id ?? '',
      relyingPartyStore?.relyingParty?.id ?? '',
      startTime.toISOString(),
      endTime.toISOString()
    );
  }, []);

  const getTenantUrl = (): string => {
    let env = '';
    if (window.location.origin.includes('.dev.')) {
      env = '.dev';
    } else if (window.location.origin.includes('.stg.')) {
      env = '.stg';
    }

    return 'https://' + relyingPartyStore?.relyingParty?.id + '.authentication' + env + '.hanko.io';
  };

  const onSubscriptionBannerClick = async () => {
    if (relyingPartyStore?.relyingParty?.subscription?.active === true) {
      const response = await fetch(
        GET_BILLING_PORTAL_URL(organizationStore?.organization?.id ?? '') +
          '?return_url=' +
          encodeURIComponent(window.location.toString()),
        {
          method: 'GET',
          credentials: 'same-origin',
          cache: 'no-cache',
          mode: 'cors',
        }
      );
      if (response.status === 201) {
        const redirectUrl = response.headers.get('location');
        window.location.assign(redirectUrl ?? '');
      } else {
        toast.error('Internal server error');
      }
    } else {
      setIsUpgradeModalOpen(true);
    }
  };

  const handleUpdateStatus = async () => {
    const tenantStatus = await relyingPartyStore?.getTenantStatus(
      organizationStore?.organization?.id ?? '',
      relyingPartyStore?.relyingParty?.id ?? ''
    );

    return setTenantStatus(tenantStatus);
  };

  useEffect(() => {
    handleUpdateStatus();
  }, []);

  return (
    <>
      <TopContainerWrapper>
        <TopContainer>
          <RelyingPartyContextMenu projectType="api" />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {relyingPartyStore?.relyingParty?.icon_url === undefined ||
            relyingPartyStore?.relyingParty?.icon_url === '' ? (
              <EmptyImage />
            ) : (
              <Image src={relyingPartyStore?.relyingParty?.icon_url} alt="Logo" />
            )}
            <ProjectNameWrapper>
              <ProjectName>{relyingPartyStore?.relyingParty?.name}</ProjectName>
              <CurrentSubscriptionBannerWrapper>
                <CurrentSubscriptionBanner
                  isPro={relyingPartyStore?.relyingParty?.subscription?.active ?? false}
                  onClick={onSubscriptionBannerClick}
                  style={{ marginTop: '30px' }}
                />
              </CurrentSubscriptionBannerWrapper>
            </ProjectNameWrapper>
          </div>
          <div style={{ marginTop: '35px' }}>
            <TenantStatus apiTenantStatus={tenantStatus} updateTenantStatus={handleUpdateStatus} />
          </div>
          <TenantUrlWrapper>
            <CopyableText style={{ width: '100%' }}>{getTenantUrl()}</CopyableText>
          </TenantUrlWrapper>
        </TopContainer>
      </TopContainerWrapper>
      <Suspense
        fallback={
          <LoadingContainer>
            <LoadingWheel />
          </LoadingContainer>
        }
      >
        <DashboardChart
          activeUsersPerDay={statisticsStore?.activeUsersPerDay}
          requestsPerDay={statisticsStore?.requestsPerDay}
          endTime={endTime}
          startTime={startTime}
        />
      </Suspense>
      <NumbersContainer>
        <NumberContainer>
          {relyingPartyStore?.relyingParty?.subscription?.active === true ? null : (
            <UpgradeBannerContainer>
              <UpgradeBanner
                isPro={relyingPartyStore?.relyingParty?.subscription?.active ?? false}
                onClick={onSubscriptionBannerClick}
              />
            </UpgradeBannerContainer>
          )}
          <SettingsTitle>Monthly active users (this month)</SettingsTitle>
          <Number>{statisticsStore?.currentMonthActiveUsers?.activeUsers ?? 0}</Number>
        </NumberContainer>
        <NumberContainer>
          <SettingsTitle>Monthly active users (last month)</SettingsTitle>
          <Number>{statisticsStore?.lastMonthActiveUsers?.activeUsers ?? 0}</Number>
        </NumberContainer>
      </NumbersContainer>
      <UpgradeModal
        isOpen={isUpgradeModalOpen}
        onClose={() => setIsUpgradeModalOpen(false)}
        organizationId={organizationStore?.organization?.id ?? ''}
        relyingPartyId={relyingPartyStore?.relyingParty?.id ?? ''}
        projectType="api"
      />
      <UpgradeSuccessModal
        onClose={() => {
          const uri = window.location.toString();
          if (uri.indexOf('#') > 0) {
            const cleanUri = uri.substring(0, uri.indexOf('#'));
            window.history.replaceState({}, document.title, cleanUri);
          }
          setIsSuccessModalOpen(false);
        }}
        isOpen={isSuccessModalOpen}
        projectType="api"
      />
    </>
  );
};

export const ApiDashboard = inject(
  'organizationStore',
  'relyingPartyStore',
  'statisticsStore'
)(observer(ApiDashboardComponent));
