import React, { ChangeEvent, useState } from 'react'
import styled from 'styled-components'
import { SettingsDescription, SettingsTitle } from '../../../atoms/Texts'
import { FormList, FormListItem } from '../../../atoms/List'
import { PrimaryButton } from '../../../atoms/Button'
import { Input } from '../../../atoms/Input'
import { RelyingPartyStore } from '../../../../store/RelyingPartyStore'
import { OrganizationStore } from '../../../../store/OrganizationStore'
import { inject, observer } from 'mobx-react'
import { isApiError } from '../../../../helper/ResponseHelper'
import { toast } from 'react-toastify'
import {
  ColumnLeft,
  ColumnRight,
  SettingsContainerLarge as SettingsContainerLargeComponent,
} from '../../../atoms/Container';
import { BACKEND_LOGIN_REDIRECT_URL } from "../../../../constants/Url";
import { useCurrentRoute } from "react-navi";

const SettingsContainerLarge = styled(SettingsContainerLargeComponent)({
  width: '100%',
});

const FidoSettingsFormList = styled(FormList)({
    margin: 0
})

type State = {
    registrationTimeout: number
    authenticationTimeout: number
    transactionTimeout: number
    initialized: boolean
}

interface Props {
    relyingPartyStore?: RelyingPartyStore
    organizationStore?: OrganizationStore
}

export const WebauthnTimeoutSettingsComponent = (props: Props) => {
    const route = useCurrentRoute()
    const [state, setState] = useState<State>({
        registrationTimeout: 0,
        authenticationTimeout: 0,
        transactionTimeout: 0,
        initialized: false
    })
    const [isSubmitting, setIsSubmitting] = useState(false)

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault()

        if (isSubmitting) {
            return;
        }

        setIsSubmitting(true)

        const {relyingPartyStore, organizationStore} = props
        const {registrationTimeout, authenticationTimeout, transactionTimeout} = state
        const organizationId = organizationStore?.organization?.id ?? ''
        const relyingPartyId = relyingPartyStore?.relyingParty?.id ?? ''
        const result = await relyingPartyStore?.update(organizationId, relyingPartyId, {
            timeouts: {
                webauthn: {
                    registration: registrationTimeout,
                    authentication: authenticationTimeout,
                    transaction: transactionTimeout
                }
            }
        })

        if (!isApiError(result)) {
            toast.success("Timeouts updated.")
        } else if (result.code === 401) {
            window.location.assign(BACKEND_LOGIN_REDIRECT_URL(route.url.href))
        } else {
            toast.error("Failed to update timeouts.")
        }

        setIsSubmitting(false);
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const target = event.target
        const stateChange = {[target.name]: parseInt(target.value) * 1000} as Pick<State, 'registrationTimeout' | 'authenticationTimeout' | 'transactionTimeout'>
        setState({...state, ...stateChange})
    }

    const {registrationTimeout, authenticationTimeout, transactionTimeout, initialized} = state
    const {relyingPartyStore} = props

    if (!initialized && relyingPartyStore?.relyingParty) {
        setState({
            registrationTimeout: relyingPartyStore?.relyingParty?.timeouts.webauthn.registration ?? 0,
            authenticationTimeout: relyingPartyStore?.relyingParty?.timeouts.webauthn.authentication ?? 0,
            transactionTimeout: relyingPartyStore?.relyingParty?.timeouts.webauthn.transaction ?? 0,
            initialized: true
        })
    }

    const isButtonDisabled = (registrationTimeout === 0 || authenticationTimeout === 0 || transactionTimeout === 0) ||
        (authenticationTimeout === (relyingPartyStore?.relyingParty?.timeouts.webauthn.authentication ?? 0) &&
            registrationTimeout === (relyingPartyStore?.relyingParty?.timeouts.webauthn.registration ?? 0) &&
            transactionTimeout === (relyingPartyStore?.relyingParty?.timeouts.webauthn.transaction ?? 0))

    return <SettingsContainerLarge>
        <SettingsTitle>Request timeouts</SettingsTitle>
        <ColumnLeft>
            <SettingsDescription>The durations the WebAuthn requests are valid for, e.g. the time allowed for the user
                to authenticate with a Security Key or a Platform Authenticator. If a request becomes invalid, the
                authentication will fail, even if a valid assertion is presented, and the user has to initiate a new
                authentication.</SettingsDescription>
        </ColumnLeft>
        <ColumnRight>
            <form onSubmit={onSubmit}>
                <FidoSettingsFormList>
                    <FormListItem>
                        <Input style={{marginBottom: "25px"}} type="number" label="Registration request TTL (seconds)"
                               name="registrationTimeout" size={1}
                               value={registrationTimeout / 1000} onChange={handleInputChange}/>
                        <Input style={{marginBottom: "22px"}} type="number" label="Authentication request TTL (seconds)"
                               name="authenticationTimeout" size={1}
                               value={authenticationTimeout / 1000} onChange={handleInputChange}/>
                        <Input style={{marginBottom: "22px"}} type="number" label="Transaction request TTL (seconds)"
                               name="transactionTimeout" size={1}
                               value={transactionTimeout / 1000} onChange={handleInputChange}/>
                    </FormListItem>
                    <FormListItem><PrimaryButton type="submit" disabled={isButtonDisabled} isLoading={isSubmitting}>Save
                        Changes</PrimaryButton></FormListItem>
                </FidoSettingsFormList>
            </form>
        </ColumnRight>
    </SettingsContainerLarge>
}

export const WebauthnTimeoutSettings = inject('organizationStore', 'relyingPartyStore')(observer(WebauthnTimeoutSettingsComponent))