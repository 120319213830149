import React, { useState } from 'react'
import styled from 'styled-components'
import ReactModal from 'react-responsive-modal'
import { PrimaryButton, SecondaryButton } from "../../atoms/Button";
import { isApiError } from "../../../helper/ResponseHelper";
import { toast } from 'react-toastify'
import { FormList, FormListItem } from "../../atoms/List";
import { ApiKeyStore } from "../../../store/ApiKeyStore";
import { inject, observer } from "mobx-react";
import { BACKEND_LOGIN_REDIRECT_URL } from "../../../constants/Url";
import { useCurrentRoute } from "react-navi";
import { useMedia } from 'react-media';
import { MEDIA_QUERIES } from '../../../constants/MediaQueries';
import ReactModalStyle from '../../../constants/ReactModalStyle';

const Title = styled.h1({
    fontSize: '25px',
    color: '#FFFFFF',
    fontWeight: 'bold',
    letterSpacing: '0.28px',
    marginLeft: 0,
})

const Description = styled.p({
    color: '#FFFFFF',
    fontSize: '16px',
    letterSpacing: '0.18px',
    wordBreak: 'break-all',
    marginTop: '29px',
})

const Container = styled.div({
    margin: '80px 128px',
    color: '#FFFFFF'
})

const CancelButton = styled(SecondaryButton)({
    color: '#E1E7EC',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    ':hover': {
        backgroundColor: 'transparent',
        borderColor: 'transparent'
    },
    width: '50%'
})

const ButtonWrapper = styled.div({
    display: 'flex',
    flexDirection: 'row',
    width: '70%',
})

const ButtonWrapperWrapper = styled.div({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end',
})

const Checkbox = styled.input({
    height: '20px',
    width: '20px'
})

const CheckboxLabel = styled.label({
    fontSize: '16px',
    marginLeft: '8px'
})

const CheckboxWrapper = styled.div({
    display: 'flex',
    alignItems: 'center'
})

const CheckboxListItem = styled(FormListItem)({
    margin: '20px 0 48px 0'
})

interface Props {
    isOpen: boolean
    onClose: () => void
    organizationId: string
    relyingPartyId: string
    apiKeyId: string
    apiKeyStore?: ApiKeyStore
}

const DeleteApiKeyModalComponent = (props: Props) => {
    const [deletionConfirmed, setDeletionConfirmed] = useState(false)
    const [isDeleteKeyLoading, setIsDeleteKeyLoading] = useState(false);
    const route = useCurrentRoute()
    const isSmallScreen = useMedia({ query: MEDIA_QUERIES.tablet });
    const deleteApiKey = async () => {
        setIsDeleteKeyLoading(true);
        const {organizationId, relyingPartyId} = props
        const result = await props.apiKeyStore?.delete(organizationId, relyingPartyId, props.apiKeyId)
        if (!isApiError(result)) {
            setIsDeleteKeyLoading(false);
            props.apiKeyStore?.list(organizationId, relyingPartyId)
            props.onClose()
            toast.success("API key deleted")
        } else if (result.code === 401) {
            setIsDeleteKeyLoading(false);
            window.location.assign(BACKEND_LOGIN_REDIRECT_URL(route.url.href))
        } else {
            setIsDeleteKeyLoading(false);
            toast.error("API key could not be deleted")
        }
    }

    const {isOpen, onClose} = props

    return <ReactModal open={isOpen} onClose={onClose} styles={ReactModalStyle({isSmallScreen})} center={true} blockScroll>
        <Container>
            <Title>Delete API Key</Title>
            <Description>
                Are you sure you want to delete the API key '{props.apiKeyId}'?
            </Description>
            <FormList style={{margin: 0}}>
                <CheckboxListItem>
                    <CheckboxWrapper>
                        <Checkbox type="checkbox" id="delete_confirm" checked={deletionConfirmed} onChange={() => {
                            setDeletionConfirmed(!deletionConfirmed)
                        }}/>
                        <CheckboxLabel htmlFor="delete_confirm">Yes I'm sure, delete API key</CheckboxLabel>
                    </CheckboxWrapper>
                </CheckboxListItem>
                <FormListItem>
                    <ButtonWrapperWrapper>
                        <ButtonWrapper>
                            <CancelButton type="button" onClick={onClose}>Cancel</CancelButton>
                            <PrimaryButton type="submit" onClick={deleteApiKey} isLoading={isDeleteKeyLoading}
                                           disabled={!deletionConfirmed}>Delete</PrimaryButton>
                        </ButtonWrapper>
                    </ButtonWrapperWrapper>
                </FormListItem>
            </FormList>
        </Container>
    </ReactModal>

}

export const DeleteApiKeyModal = inject('apiKeyStore')(observer(DeleteApiKeyModalComponent))