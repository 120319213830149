import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigation } from 'react-navi';
import { PrimaryButton } from '../atoms/Button';
import { SettingsContainer } from '../atoms/Container';
import { AddNewAppModal } from './AddNewAppModal';
import { OidcClientStore } from '../../store/OidcClientStore';
import { inject, observer } from 'mobx-react';
import { isApiError } from '../../helper/ResponseHelper';
import { toast } from 'react-toastify';
import { CopyableText } from '../atoms/CopyableText';

type Props = {
  organizationId: string;
  relyingPartyId: string;
  oidcClientStore?: OidcClientStore;
};

const Container = styled(SettingsContainer)`
  width: 100%;
  padding: 35px 30px;
  box-sizing: border-box;
  color: #ffffff;
  text-align: left;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const RightColumn = styled.div`
  width: 160px;
`;

const Title = styled.h2`
  margin: 0 0 25px 0;
  font-size: 18px;
  font-weight: 700;
`;

const Text = styled.p`
  max-width: 495px;
  margin-bottom: 30px;
  font-size: 14px;
`;

const AppsContainer = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const AppDetails = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 18px;
  background-color: #545b61;
  border-radius: 5px;

  &:not(:last-child) {
    margin-bottom: 25px;
  }

  &:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }
`;

const AppName = styled.span`
  font-size: 16px;
  font-weight: 600;
`;

const DetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    cursor: auto;
  }
`;

const ClientDetailsText = styled.span`
  margin-right: 20px;
  font-size: 14px;
  font-weight: 500;
`;

const RedLink = styled.a`
  color: #ff2e4c;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

type Endpoints = {
  authorizationEndpoint: string;
  logoutEndpoint: string;
  openIDEndpoint: string;
  revocationEndpoint: string;
  tokenEndpoint: string;
  userInfoEndpoint: string;
};

const RelyingPartyAppsComponent = ({ organizationId, relyingPartyId, oidcClientStore }: Props) => {
  const navigation = useNavigation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [endpoints, setEndopints] = useState<Endpoints | undefined>();
  const [allApps, setAllApps] = useState<any[]>();

  const fetchAllApps = () => {
    oidcClientStore
      ?.list(organizationId, relyingPartyId)
      .then((result) => {
        if (isApiError(result)) {
          toast.error('Error getting Apps');
        } else {
          setAllApps(result);
        }
      })
      .catch((error) => console.error(error));

    setEndopints({
      authorizationEndpoint: 'https://auth.yourdomain.com/oauth2/auth',
      logoutEndpoint: 'https://auth.yourdomain.com/oauth2/sessions/logout',
      openIDEndpoint: 'https://auth.yourdomain.com/.well-known/openid-configuration',
      revocationEndpoint: 'https://auth.yourdomain.com/oauth2/revoke',
      tokenEndpoint: 'https://auth.yourdomain.com/oauth2/token',
      userInfoEndpoint: 'https://auth.yourdomain.com/userinfo',
    });
  };

  useEffect(() => {
    fetchAllApps();
  }, []);

  const goToAppDetails = (appId: string) =>
    navigation.navigate(`${navigation.getCurrentValue().url.pathname}/${appId}`);

  return (
    <>
      <Container>
        <ColumnWrapper>
          <div>
            <Title>Your Apps</Title>
            <Text>
              Connect your mobile or web apps to use Hanko Identity for authentication with OpenID Connect (OIDC).
              Please see the{' '}
              <RedLink href="https://docs.hanko.io/identity/configuration" target="_blank">
                docs
              </RedLink>{' '}
              for detailed instructions.
            </Text>
          </div>
          <RightColumn>
            <PrimaryButton type="submit" onClick={() => setIsModalOpen(true)}>
              Connect app
            </PrimaryButton>
          </RightColumn>
        </ColumnWrapper>
        <AppsContainer>
          {allApps &&
            allApps.length > 0 &&
            oidcClientStore?.clients
              ?.filter((client) => client?.metadata?.app_type !== 'credential')
              .map((client, index) => (
                <AppDetails key={`${client.client_id}-${index}`} onClick={() => goToAppDetails(client.client_id ?? '')}>
                  <AppName>{client.client_name}</AppName>
                  <DetailsWrapper onClick={(e) => e.stopPropagation()}>
                    <ClientDetailsText>Client&nbsp;ID:</ClientDetailsText>
                    <CopyableText>{client.client_id || ''}</CopyableText>
                  </DetailsWrapper>
                </AppDetails>
              ))}
        </AppsContainer>
      </Container>
      <AddNewAppModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onNewAppSuccess={() => fetchAllApps()}
        organizationId={organizationId}
        relyingPartyId={relyingPartyId}
      />
    </>
  );
};

export const RelyingPartyApps = inject('oidcClientStore')(observer(RelyingPartyAppsComponent));
