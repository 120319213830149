import React from 'react';
import styled, { CSSProperties } from 'styled-components';

export const InputComponent = styled.input<{ error?: string }>((props) => ({
  backgroundColor: '#272F34',
  color: '#E1E7EC',
  margin: '1px',
  padding: '12px 20px',
  border: props.error == undefined ? '1px solid #272F34' : '1px solid #FF2E4C',
  borderRadius: '2px',
  fontWeight: 500,
  fontSize: '16px',
  ':focus': {
    outline: 'none',
    border: '1px solid #BBC4CC',
  },
  ':disabled': {
    color: '#BBC4CC',
    backgroundColor: '#545B61',
    border: '1px solid #545B61',
  },
}));

export const TextAreaComponent = styled.textarea<{ error?: string }>((props) => ({
  backgroundColor: '#272F34',
  color: '#E1E7EC',
  margin: '1px',
  padding: '12px 20px',
  border: props.error == undefined ? '1px solid #272F34' : '1px solid #FF2E4C',
  borderRadius: '2px',
  fontWeight: 500,
  fontSize: '16px',
  ':focus': {
    outline: 'none',
    border: '1px solid #BBC4CC',
  },
  ':disabled': {
    color: '#BBC4CC',
    backgroundColor: '#545B61',
    border: '1px solid #545B61',
  },
}));

const inputStyles = {
  margin: '0',
  padding: '15px',
  borderRadius: '2px',
  backgroundColor: '#272F34',
};

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Label = styled.label`
  margin-bottom: 5px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
`;

const ErrorMessage = styled.span`
  color: #ff2e4d;
  font-size: 14px;
  font-weight: 700;
`;

export const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  color: '#818A93',
  ':focus-within': {
    color: '#FFFFFF',
  },
});

interface Props {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  type?: string;
  name?: string;
  value?: string | number | string[];
  placeholder?: string;
  disabled?: boolean;
  size?: number;
  style?: CSSProperties;
  className?: string;
  min?: number | string;
  pattern?: string;
  error?: string;
  defaultValue?: string;
  readOnly?: boolean;
}

interface InputProps {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  type?: string;
  name?: string;
  value?: string | number | string[];
  placeholder?: string;
  disabled?: boolean;
  size?: number;
  min?: number | string;
  pattern?: string;
  error?: string;
  defaultValue?: string;
  readOnly?: boolean;
}

export const Input = (props: Props) => {
  const {
    label,
    onChange,
    type,
    name,
    value,
    placeholder,
    size,
    disabled,
    min,
    pattern,
    error,
    defaultValue,
    readOnly,
  } = props;
  const inputProps: InputProps = {
    onChange,
    label,
    type,
    name,
    value,
    placeholder,
    size,
    disabled,
    min,
    pattern,
    error,
    defaultValue,
    readOnly,
  };

  return (
    <Wrapper className={props.className} style={props.style}>
      <LabelWrapper>
        <Label htmlFor={props.name}>{props.label}</Label>
        {error ? <ErrorMessage>{error}</ErrorMessage> : null}
      </LabelWrapper>
      <InputComponent {...inputProps} style={inputStyles} />
    </Wrapper>
  );
};

interface TextAreaProps {
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  label: string;
  type?: string;
  name?: string;
  value?: string | number | string[];
  placeholder?: string;
  disabled?: boolean;
  size?: number;
  style?: CSSProperties;
  className?: string;
  min?: number | string;
  pattern?: string;
  error?: string;
  defaultValue?: string;
  readOnly?: boolean;
}

interface TextAreaInputProps {
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  label?: string;
  type?: string;
  name?: string;
  value?: string | number | string[];
  placeholder?: string;
  disabled?: boolean;
  size?: number;
  min?: number | string;
  pattern?: string;
  error?: string;
  defaultValue?: string;
  readOnly?: boolean;
}

export const TextAreaInput = (props: TextAreaProps) => {
  const {
    label,
    onChange,
    type,
    name,
    value,
    placeholder,
    size,
    disabled,
    min,
    pattern,
    error,
    defaultValue,
    readOnly,
  } = props;
  const inputProps: TextAreaInputProps = {
    onChange,
    label,
    type,
    name,
    value,
    placeholder,
    size,
    disabled,
    min,
    pattern,
    error,
    defaultValue,
    readOnly,
  };

  return (
      <Wrapper className={props.className} style={props.style}>
        <LabelWrapper>
          <Label htmlFor={props.name}>{props.label}</Label>
          {error ? <ErrorMessage>{error}</ErrorMessage> : null}
        </LabelWrapper>
        <TextAreaComponent {...inputProps} style={inputStyles} />
      </Wrapper>
  );
};
