import React from 'react';
import styled from 'styled-components';
import { Color } from '../../atoms/Color';
import { NoStylingButton, PrimaryButton } from '../../atoms/Button';
import CopyToClipboardButton from '../../atoms/CopyToClipboardButton';
import { ReactComponent as InfoIcon } from '../../../assets/info.svg';
import { CopyableText } from '../../atoms/CopyableText';

const Wrapper = styled.div`
  max-width: 495px;
  box-sizing: border-box;
  color: #ffffff;
`;

const Title = styled.h2`
  margin: 0 0 25px 0;
  font-size: 24px;
  font-weight: 700;
`;

const MessageWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 40px;
  padding: 13px 17px;
  border-radius: 2px;
  border: 1px solid ${Color.primaryRed};
`;

const InfoSvg = styled(InfoIcon)`
  width: 35px;
  margin-right: 15px;
`;

const Text = styled.span`
  max-width: 495px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 25px;
`;

const ClientDetailsList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const ClientDetailsItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0;
  border-top: 1px solid #272f34;

  &:last-child {
    border-bottom: 1px solid #272f34;
  }
`;

const ClientDetailsText = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 80px;
`;

const CancelButton = styled(NoStylingButton)`
  margin-right: 30px;
  background: none;
  border: none;
  color: #e1e7ec;
  font-weight: 600;
`;

const SubmitButton = styled(PrimaryButton)`
  max-width: 215px;
`;

type Props = {
  updateSecretId?: string;
  onClose: () => void;
  clientId: string;
  clientSecret: string;
};

const NewAppSuccess = ({ onClose, clientId, clientSecret, updateSecretId }: Props) => (
  <Wrapper>
    <Title>{updateSecretId ? 'New credentials created' : 'App successfully connected'}</Title>
    <MessageWrapper>
      <InfoSvg fill={Color.primaryRed} />
      <Text>Please store the secret in a safe place. It cannot be retrieved after closing this dialog</Text>
    </MessageWrapper>
    <ClientDetailsList>
      <ClientDetailsItem>
        <ClientDetailsText>Client ID:</ClientDetailsText>
        <CopyableText style={{ width: '400px' }}>{clientId}</CopyableText>
      </ClientDetailsItem>
      {clientSecret.length > 0 ? (
        <ClientDetailsItem>
          <ClientDetailsText>Client secret:</ClientDetailsText>
          <CopyableText style={{ width: '400px' }}>{clientSecret}</CopyableText>
        </ClientDetailsItem>
      ) : null}
    </ClientDetailsList>
    <ButtonWrapper>
      <CancelButton type="button" onClick={onClose}>
        Close
      </CancelButton>
      <SubmitButton type="button" onClick={onClose}>
        Ok
      </SubmitButton>
    </ButtonWrapper>
  </Wrapper>
);

NewAppSuccess.defaultProps = {
  clientId: '',
  clientSecret: '',
};

export default NewAppSuccess;
