import React from 'react'
import styled from "styled-components";

interface ContainerProps {
    readonly active: boolean
}

const Container = styled.div<ContainerProps>(props => ({
    display: 'flex',
    borderRadius: '3px',
    padding: '25px 10px 25px 22px',
    cursor: 'pointer',
    justifyContent: "flex-start",
    backgroundColor: props.active ? '#3E464C' : 'transparent',
    ':hover': {
        backgroundColor: '#303940'
    },
    alignItems: 'center',
    marginBottom: '10px',
    ':nth-last-child(2)': {
        marginBottom: '20px'
    }
}))

const Button = styled.button({
    minWidth: '100px',
    cursor: 'pointer',
    borderRadius: '1.5px',
    background: '#545B61',
    color: '#FFFFFF',
    borderStyle: 'none',
    padding: '6px 0px',
    fontWeight: 500,
    letterSpacing: 0,
    fontSize: '14px',
    zIndex: 20,
})

const Text = styled.span({
    fontSize: '20px',
    lineHeight: '26px',
    letterSpacing: 0.18,
    fontWeight: 500,
    color: '#FFFFFF',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    paddingRight: '25px',
})

interface Props {
    key: string
    title: string
    onChange: (e: React.MouseEvent) => void
    onSettings: (e: React.MouseEvent) => void
    active: boolean
}

export const ChooseOrganizationDropDownItem = (props: Props) => {
    const {title, onChange, onSettings, active} = props
    return <Container onClick={onChange} active={active}>
        <Text>{title}</Text>
    </Container>
}