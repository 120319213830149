import React from 'react';
import ReactModal from 'react-responsive-modal';
import styled from 'styled-components';
import { NoStylingButton, PrimaryButton } from '../atoms/Button';
import { Color } from '../atoms/Color';
import { AddOverlayBanner } from '../atoms/OverlayBanner';
import crossIconSvg from '../../assets/cross_icon.svg';
import { ReactComponent as InfoIconSvg } from '../../assets/info.svg';
import { useMedia } from 'react-media';
import { MEDIA_QUERIES } from '../../constants/MediaQueries';
import ReactModalStyle from '../../constants/ReactModalStyle';

const Modal = styled.div`
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  width: 750px;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  padding: 80px 125px;
  background: ${Color.darkBlue};
  border-radius: 5px;
  border: none;
  color: #ffffff;
`;

const CloseModalButton = styled.button`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  top: 15px;
  right: 15px;
  width: 15px;
  height: 15px;
  padding: 5px;
  background: no-repeat;
  border: none;
  cursor: pointer;
`;

const Image = styled.img`
  display: block;
  margin: 0 auto;
`;

const Title = styled.div`
  margin-bottom: 40px;
  font-size: 24px;
  font-weight: 700;
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 50px;
`;

const InfoIcon = styled(InfoIconSvg)`
  margin-right: 16px;
`;

const Text = styled.div`
  max-width: 415px;
  line-height: 25px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto;
`;

const CancelButton = styled(NoStylingButton)`
  color: #e1e7ec;
  font-weight: 600;
`;

const SaveButton = styled(PrimaryButton)`
  width: 215px;
  margin-left: 35px;
`;

type Props = {
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

const DomainConfirmationModal = ({ isLoading, onClose, onSubmit, isOpen }: Props) => {
  const isSmallScreen = useMedia({ query: MEDIA_QUERIES.tablet });

  return (
    <ReactModal onClose={onClose} open={isOpen} styles={ReactModalStyle({isSmallScreen})} center blockScroll>
      <AddOverlayBanner />
      <Modal>
        <CloseModalButton onClick={onClose}>
          <Image src={crossIconSvg} alt="close modal icon" />
        </CloseModalButton>
        <Title>Change domain</Title>
        <TextWrapper>
          <InfoIcon fill="#FF2E4C" />
          <Text>
            Please note that when you change the domain, the endpoints of your apps change. To make sure that everything
            works, please check your apps after changing your domain.
          </Text>
        </TextWrapper>
        <ButtonWrapper>
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <SaveButton onClick={onSubmit} isLoading={isLoading}>
            Ok
          </SaveButton>
        </ButtonWrapper>
      </Modal>
    </ReactModal>
  );
};

export default DomainConfirmationModal;
