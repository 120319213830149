export type ApiError = {
    code: number
    message: string
    debug_hint?: string
}

export async function handleApiResponse<T>(response: Response): Promise<T | ApiError> {
    if (response.status >= 200 && response.status <= 299) {
        return await response.json() as T
    }
    else if (response.status === 401) {
        return new Promise<ApiError>(resolve => {
            resolve({code: response.status, message: ""})
        })
    }
    else if (response.status === 413) {
        return new Promise<ApiError>(resolve => {
            resolve({code: response.status, message: "413 Request Entity Too Large"})
        })
    }
    else {
        return await response.json() as ApiError
    }
}

export async function handleMultipleApiResponses<T>(identityResponse: Response, relyingPartiesResponse: Response): Promise<T | ApiError> {
    if ((identityResponse.status >= 200 && identityResponse.status <= 299) && (relyingPartiesResponse.status >= 200 && relyingPartiesResponse.status <= 299)) {
        const identityJson = await identityResponse.json();
        const relyingPartiesJson = await relyingPartiesResponse.json();

        const response = [
            ...identityJson,
            ...relyingPartiesJson
        ];

        return response as any
    }
    else if (identityResponse.status === 401) {
        return new Promise<ApiError>(resolve => {
            resolve({code: identityResponse.status, message: ""})
        })
    }
    else if (relyingPartiesResponse.status === 401) {
        return new Promise<ApiError>(resolve => {
            resolve({code: relyingPartiesResponse.status, message: ""})
        })
    }
    else {
        return await identityResponse.json() as ApiError
    }
}


export function isApiError(toBeDetermined: any): toBeDetermined is ApiError {
    return (toBeDetermined !== undefined && toBeDetermined.hasOwnProperty("code") && toBeDetermined.hasOwnProperty("message"))
}

export function errorMessage(err: ApiError): string {
    if (err.debug_hint !== undefined && err.debug_hint.length > 0) {
        return err.debug_hint
    }
    return err.code + " - " + err.message
}
