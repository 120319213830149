import React from 'react';
import styled from 'styled-components';
import { useMedia } from 'react-media';
import { appStateStore, AppStateStore } from '../../store/AppStateStore';
import { observer, inject } from 'mobx-react';
import { DropdownNavBar } from './NavBarDropdown';
import { OrganizationStore } from '../../store/OrganizationStore';
import { UserStore } from '../../store/UserStore';
import { ChooseOrganizationDropDown } from './ChooseOrganizationDropDown';
import { MEDIA_QUERIES } from '../../constants/MediaQueries';
import LogoSvg from '../../assets/logo.svg';
import { ReactComponent as BurgerIcon } from '../../assets/burger_icon.svg';
import { Link } from 'react-navi';

const Wrapper = styled.div<{ isMobile?: boolean }>((props) => ({
  position: 'relative',
  backgroundColor: '#272F34',
  width: props.isMobile ? '100vw' : '100%',
  height: '75px',
}));

const MaxWidthWrapper = styled.div({
  height: '100%',
  margin: '0 55px 0 auto',
  maxWidth: '1200px',
  display: 'flex',
  flexDirection: 'row',
});

const DropdownWrapper = styled.div({
  alignItems: 'center',
  textAlign: 'right',
  margin: 'auto',
  flexGrow: 1,
});

const LogoLink = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '25px',
});

const Logo = styled.img({
  width: '110px',
  height: '55px',
});

const MenuButton = styled.button({
  position: 'absolute',
  top: '50%',
  right: '25px',
  background: 'none',
  border: 'none',
  transform: 'translateY(-50%)',
  cursor: 'pointer',
});

interface Props {
  appStateStore?: AppStateStore;
  organizationStore?: OrganizationStore;
  userStore?: UserStore;
}

const NavBarComponent = (props: Props) => {
  const organizationName = props.appStateStore?.breadcrumbs[0]?.title || '';
  const isMobile = useMedia({ query: MEDIA_QUERIES.tablet });

  return (
    <Wrapper isMobile={isMobile}>
      <MaxWidthWrapper>
        {isMobile ? (
          <>
            <LogoLink href={`/organization/${props?.organizationStore?.organization?.id}/relyingParty`}>
              <Logo src={LogoSvg} />
            </LogoLink>
            <MenuButton onClick={() => appStateStore.toggleSidebarMenu()}>
              <BurgerIcon fill="#ffffff" />
            </MenuButton>
          </>
        ) : (
          <DropdownWrapper>
            <ChooseOrganizationDropDown title={organizationName} />
            <DropdownNavBar />
          </DropdownWrapper>
        )}
      </MaxWidthWrapper>
    </Wrapper>
  );
};

export const NavBar = inject('appStateStore', 'organizationStore', 'userStore')(observer(NavBarComponent));
