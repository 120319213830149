import React from 'react';
import styled, { CSSProperties } from 'styled-components';
import Select from 'react-select';

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
`;

type Props = {
  defaultValue?: { label: string; value: string };
  disabled?: boolean;
  error?: string;
  label: string;
  name?: string;
  onChange: (option: any) => void;
  options: any;
  placeholder?: string;
  style?: CSSProperties;
  value?: { label: string; value: string };
};

export const CustomDropdown = ({
  defaultValue,
  disabled,
  error,
  label,
  name,
  onChange,
  options,
  placeholder,
  style,
  value,
}: Props) => {
  const customStyles = {
    container: () => ({
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      cursor: 'pointer',
    }),
    control: () => ({
      position: 'relative',
      width: '100%',
      height: '52px',
      padding: '15px 60px 15px 20px',
      boxSizing: 'border-box',
      backgroundColor: disabled ? '#545B61' : '#272F34',
      border: !!error ? '1px solid #FF2E4C' : disabled ? '1px solid #545B61' : '1px solid #272F34',
      borderRadius: '2px',
      color: disabled ? '#bbc4cc' : '#e1e7ec',
      fontSize: '16px',
      fontWeight: '500',
    }),
    valueContainer: () => ({
      padding: '0',
    }),
    singleValue: () => ({
      color: disabled ? '#bbc4cc' : '#e1e7ec',
    }),
    indicatorSeparator: () => ({
      position: 'absolute',
      top: '50%',
      right: '34px',
      width: '2px',
      height: '28px',
      transform: 'translateY(-50%)',
      background: '#bbc4cc',
    }),
    indicatorsContainer: () => ({
      position: 'absolute',
      top: '50%',
      right: '15px',
      transform: 'translateY(-50%)',
    }),
    dropdownIndicator: () => ({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#bbc4cc',
    }),
    menu: () => ({
      position: 'absolute',
      zIndex: '1',
      width: '100%',
      marginTop: '58px',
      background: '#272F34',
      boxShadow: '0px 9px 28px rgba(0, 0, 0, 0.25)',
      borderRadius: '2px',
      color: '#ffffff',
      fontFamily: 'IBM Plex Sans',
      fontSize: '16px',
      fontWeight: '500',
    }),
    menuList: () => ({
      padding: '0',
    }),
    option: () => ({
      padding: '26px',
      cursor: 'pointer',
      ':hover': {
        backgroundColor: '#3e464c',
      },
      ':not(first-child)': {
        paddingBottom: '13px',
      },
      ':not(last-child)': {
        paddingTop: '13px',
      },
    }),
  };

  return (
    <div style={style}>
      <Label>{label}</Label>
      <Select
        value={value}
        onChange={(val) => onChange(val || '')}
        options={options}
        // @ts-ignore
        styles={customStyles}
        // @ts-ignore
        defaultValue={defaultValue}
        isSearchable={false}
      />
    </div>
  );
};
