import React from "react";
import styled from "styled-components";
import {Color} from "../components/atoms/Color";

const TooltipContent = styled.div({
    lineHeight: 1.5,
    'a:visited, a:hover, a:active, a:link': {
        textDecoration: "none",
        color: Color.primaryRed,
    }
})

export const KeyProtectionTooltip = () => {
    const keyProtectionLink = "https://fidoalliance.org/specs/common-specs/fido-registry-v2.1-ps-20191217.html#key-protection-types"
    return <TooltipContent>
        <p>Describes the method an authenticator uses to protect the private key material for FIDO registrations</p>
        <ul>
            <li>software: the authenticator uses software-based key management</li>
            <li>hardware: the authenticator uses hardware-based key management</li>
            <li>tee: the authenticator uses the Trusted Execution Environment for key management</li>
            <li>secure_element: the authenticator uses a Secure Element for key management</li>
            <li>remote_handle: the authenticator does not store [...] keys at the client, but relies on a server-provided key handle</li>
        </ul>
        <p>
            <cite>
                <a href={keyProtectionLink} target="_blank" rel="noopener noreferrer">
                    FIDO Registry of Predefined Values - Key Protection Types
                </a>
            </cite> by FIDO Alliance. Proposed Standard 17 December 2019
        </p>
    </TooltipContent>
}

export const MatcherProtectionTooltip = () => {
    const matcherProtectionLink = "https://fidoalliance.org/specs/common-specs/fido-registry-v2.1-ps-20191217.html#matcher-protection-types"
    return <TooltipContent>
        <p>Describes the method an authenticator uses to protect the matcher that performs user verification. </p>
        <ul>
            <li>software: the authenticator's matcher is running in software</li>
            <li>tee: the authenticator's matcher is running inside the Trusted Execution Environment</li>
            <li>on_chip: the authenticator's matcher is running on the chip</li>
        </ul>
        <p>
            <cite>
                <a href={matcherProtectionLink} target="_blank" rel="noopener noreferrer">
                    FIDO Registry of Predefined Values - Matcher Protection Types
                </a>
            </cite> by FIDO Alliance. Proposed Standard 17 December 2019
        </p>
    </TooltipContent>
}