import React, {ChangeEvent, useState} from 'react';
import {
    AutoSizer,
    Column, SortDirection, SortDirectionType, SortIndicator,
    Table,
    TableCellDataGetterParams,
    TableCellProps,
} from 'react-virtualized';
import 'react-virtualized/styles.css';
import {MetadataStatement} from "../../../../models/HankoMdsAuthenticator";
import ReactTooltip from "react-tooltip";
import {TableHeaderProps} from "react-virtualized/dist/es/Table";
import styled from "styled-components";
import { ReactComponent as HelpIcon } from "../../../../assets/help_outline.svg";
import {Color} from "../../../atoms/Color";
import {KeyProtectionTooltip, MatcherProtectionTooltip} from "../../../../helper/TooltipHelper";

const HelpIconStyled = styled(HelpIcon)({
    height: '15px',
    fill: '#FFFFFF',
    ':hover': {
        fill: Color.primaryRed,
    },
})

// TODO: cannot get this to work without "backtick notation"?
const ReactTooltipStyled = styled(ReactTooltip)`
        pointer-events: auto !important;
        text-transform: none;
        &:hover {
            visibility: visible !important;
            opacity: 1 !important;
        }
`;

interface SortOptions { sortBy: string, sortDirection: SortDirectionType }

interface Props {
    authenticators: MetadataStatement[]
    whitelist: string[],
    onWhitelistUpdate: (whitelist: string[]) => void,
}

interface State {
    authenticators: MetadataStatement[]
    sortOptions: SortOptions
}

export const MdsAuthenticatorTable = (props: Props) => {
    const defaultSortOptions = {sortBy: "description", sortDirection: SortDirection.ASC}

    const _sort =  (authenticators: MetadataStatement[], {sortBy, sortDirection}: SortOptions) => {
        let sorted = authenticators.sort(getSorter(sortBy));
        sorted = sortDirection === SortDirection.DESC ? [...sorted].reverse() : sorted
        return { authenticators: sorted, sortOptions: {sortBy, sortDirection} }
    }

    const [state, setState] = useState<State>({
        authenticators: _sort([...props.authenticators], defaultSortOptions).authenticators,
        sortOptions: defaultSortOptions
    })

    const sortAuthenticators = ({sortBy, sortDirection}: SortOptions) => {
        let sorted = _sort([...state.authenticators], {sortBy, sortDirection})
        setState({ authenticators: sorted.authenticators, sortOptions: {sortBy, sortDirection} });
    }

    function getSorter(sortBy: string) {
        if (sortBy === "description") {
            return (a: MetadataStatement, b: MetadataStatement) => a[sortBy].localeCompare(b[sortBy], 'standard', { sensitivity: 'case'});
        } else if (sortBy === "attachmentHint") {
            return (a: MetadataStatement, b: MetadataStatement) => a[sortBy] > b[sortBy] ? 1 : -1;
        }
    }

    const authenticatorTypeGetter = ({rowData}: TableCellDataGetterParams) => {
        return rowData["attachmentHint"].includes("internal") ? 'Platform' : 'Roaming'
    }

    const keyProtectionGetter = ({rowData}: TableCellDataGetterParams) => {
        return rowData["keyProtection"]
    }

    const matcherProtectionGetter = ({rowData}: TableCellDataGetterParams) => {
        return rowData["matcherProtection"]
    }

    const headerRenderer = (headerProps: TableHeaderProps) => {
        const showSortIndicator = headerProps.sortBy === headerProps.dataKey;

        const getTooltip = (key: string) => {
            if (key === "matcherProtection") {
                return <MatcherProtectionTooltip/>
            } else if (key === "keyProtection") {
                return <KeyProtectionTooltip/>
            }
        }

        const tooltip = getTooltip(headerProps.dataKey)

        return <div style={{display: 'flex', alignItems: 'center'}}>
            <div
                className="ReactVirtualized__Table__headerTruncatedText"
                key="label"
                title={typeof headerProps.label === 'string' ? headerProps.label : undefined}>
                {headerProps.label}
            </div>
            {tooltip ? (
                <div>
                    <HelpIconStyled data-tip data-for={headerProps.dataKey}/>
                    <ReactTooltipStyled delayHide={300} id={headerProps.dataKey} place='bottom' effect='solid'>
                        {tooltip}
                    </ReactTooltipStyled>
                </div>
            ): null}
            {showSortIndicator ? (
                    <SortIndicator key="SortIndicator" sortDirection={headerProps.sortDirection} />
            ): null}
       </div>
    }

    const selectCellRenderer = ({whitelist, onWhitelistUpdate}: Props) => {

        const onHandleChange = (event: ChangeEvent<HTMLInputElement>) => {
            let newList = whitelist
            if (event.target.checked) {
                newList = whitelist.concat(event.target.name)
            } else if (!event.target.checked) {
                newList = whitelist.filter(child => child !== event.target.name)
            }
            onWhitelistUpdate(newList)
        }

        const isOnWhitelist = (authenticatorId: string) => {
            return whitelist.find(id => id === authenticatorId) !== undefined
        }

        return (cellProps: TableCellProps) => {
            return <div style={{display: 'flex', justifyContent: 'center'}}>
                <input
                    id={cellProps.rowData.aaguid}
                    type="checkbox"
                    name={cellProps.rowData.aaguid}
                    checked={isOnWhitelist(cellProps.rowData.aaguid)}
                    onChange={onHandleChange} />
            </div>
        }
    }

    return <AutoSizer disableHeight>
            {({ width }) => (
                <Table
                    width={width}
                    height={450}
                    headerHeight={30}
                    rowHeight={30}
                    rowCount={state.authenticators.length}
                    rowGetter={({index}) => state.authenticators[index]}
                    rowStyle={{
                        backgroundColor: '#545B61',
                        paddingRight: '0px',
                        fontSize: '14px',
                        fontWeight: 500,
                        letterSpacing: 0,
                        color: '#FFFFFF',
                        alignItems: 'center'
                    }}
                    sort={sortAuthenticators}
                    sortBy={state.sortOptions.sortBy}
                    sortDirection={state.sortOptions.sortDirection}>
                    <Column width={width * 0.025}
                            dataKey=""
                            cellRenderer={selectCellRenderer(props)}
                            />
                    <Column width={width * 0.4}
                            label="Description"
                            dataKey="description"
                            headerRenderer={headerRenderer}/>
                    <Column width={width * 0.175}
                            label="Type" dataKey="attachmentHint"
                            cellDataGetter={authenticatorTypeGetter}
                            headerRenderer={headerRenderer}/>
                    <Column width={width * 0.2} label="Key Prot."
                            dataKey="keyProtection"
                            disableSort
                            cellDataGetter={keyProtectionGetter}
                            headerRenderer={headerRenderer}/>
                    <Column width={width * 0.2}
                            label="Matcher Prot."
                            disableSort
                            dataKey="matcherProtection"
                            cellDataGetter={matcherProtectionGetter}
                            headerRenderer={headerRenderer}/>
                </Table>
            )}
        </AutoSizer>
}
