import React, { ChangeEvent, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { OrganizationStore } from '../../store/OrganizationStore';
import { NoStylingButton, PrimaryButton } from '../atoms/Button';
import { SettingsContainer } from '../atoms/Container';
import { InputWithRadioButton } from '../atoms/InputWithRadio';
import DomainConfirmationModal from './DomainConfirmationModal';
import { UpgradeModal } from './UpgradeModal';
import { UpgradeSuccessModal } from './UpgradeSuccessModal';
import { IdentityStore } from '../../store/IdentityProjectStore';
import { inject, observer } from 'mobx-react';
import { isApiError } from '../../helper/ResponseHelper';
import { toast } from 'react-toastify';
import { CopyableText } from '../atoms/CopyableText';
import { Color } from '../atoms/Color';

const Container = styled(SettingsContainer)`
  width: 100%;
  padding: 35px 30px;
  box-sizing: border-box;
  color: #ffffff;
  text-align: left;
`;

const OverlayContainer = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  background: 'transparent',
  height: '100%',
  width: '100%',
  zIndex: 1,
});

const Title = styled.h2`
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 25px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ColumnLeft = styled.div`
  max-width: 315px;
`;

const LeftText = styled.div`
  width: 315px;
  margin-bottom: 25px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;

const SubscriptionLabelWrapper = styled.span`
  margin-left: 15px;
  padding: 3px 8px 3px 11px;
  background: #50c9ba;
  border-radius: 30px;
  color: #ffffff;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Warning = styled.div`
  margin-top: 23px;
`;

const BoldRed = styled.span`
  color: #ff2e4c;
  font-weight: 600;
  text-transform: uppercase;
`;

const ColumnRight = styled.div`
  width: 495px;
`;

const RadioWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 35px;
  }
`;

const RedWarning = styled.div`
  color: #ff2e4c;
  font-size: 14px;
  font-weight: 500;
`;

const GreenWarning = styled.div`
  color: #50c9ba;
  font-size: 14px;
  font-weight: 500;
`;

const CNameWrapper = styled.div`
  position: relative;
  margin-top: 15px;
  margin-bottom: 17px;
  padding: 20px 25px;
  border-radius: 2px;
  background: #545b61;
  color: #ffffff;
`;

const CNameText = styled.div`
  font-size: 14px;
  line-height: 21.5px;
`;

const Strong = styled.strong`
  font-weight: 600;
`;

const CustomDomainField = styled.div`
  margin: 15px 0;
`;

const CustomDomainText = styled.div`
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
`;

const StatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 5px;
`;

const CheckStatusButton = styled(NoStylingButton)`
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
`;

const SaveButton = styled(PrimaryButton)`
  display: block;
  width: 160px;
  margin-top: 40px;
  margin-left: auto;
`;

const keyframe = keyframes`
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(359deg)
  }
`;

const LoadingWheel = styled.div`
  display: block;
  height: 18px;
  width: 18px;
  border: 3px rgba(0, 0, 0, 0.25) solid;
  border-top: 3px ${Color.primaryRed} solid;
  border-right: 3px ${Color.primaryRed} solid;
  border-bottom: 3px ${Color.primaryRed} solid;
  border-radius: 50%;
  animation: ${keyframe} 1s infinite linear;
  box-sizing: border-box;
`;

interface RelyingPartyCustomDomainProps {
  identityStore?: IdentityStore;
  organizationStore?: OrganizationStore;
  organizationId: string;
}

const RelyingPartyCustomDomainComponent = (props: RelyingPartyCustomDomainProps) => {
  const [pickedRadioDomain, setPickedRadioDomain] = useState<'default' | 'custom'>('default');
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [defaultDomain, setDefaultDomain] = useState('');
  const [customDomain, setCustomDomain] = useState('');
  const [newCustomDomain, setNewCustomDomain] = useState('');
  const [isCustomDomainValid, setIsCustomDomainValid] = useState(true);
  const [isCustomDomainChecking, setIsCustomDomainChecking] = useState(false);
  const [isStatusConnected, setIsStatusConnected] = useState(false);
  const [isDomainConfirmationOpen, setIsDomainConfirmationOpen] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const handleCustomDomainChange = (e: ChangeEvent<HTMLInputElement>) => {
    // this regular expression must be aligned with the one in the backend (domainHelper.go)
    const pattern = new RegExp('^([A-Za-z0-9][A-Za-z0-9-]{0,61}[A-Za-z0-9]\\.){2,}[A-Za-z]{2,6}$');
    const isValid = pattern.test(e.target.value);
    setNewCustomDomain(e.target.value);

    return setIsCustomDomainValid(isValid);
  };

  const getSubDomain = (domain: string) => {
    // this approach has the downside, that it does not work with second level domains such as 'co.uk' or many other ones
    return domain.split('.').slice(0, -2).join('.');
  };

  const handleSaveChanges = () => {
    setIsSaveLoading(true);
    props.identityStore
      ?.update(props.organizationId, props.identityStore?.relyingParty?.id ?? '', {
        domain: {
          enabled: pickedRadioDomain === 'custom' && newCustomDomain.length > 0,
          domain: pickedRadioDomain === 'custom' ? newCustomDomain : undefined,
        },
      })
      .then((res) => {
        if (!isApiError(res)) {
          setIsDomainConfirmationOpen(false);
          setCustomDomain(res.domain?.domain ?? '');
          setNewCustomDomain(res.domain?.domain ?? '');
          setIsStatusConnected(res.domain?.verified ?? false);
          setPickedRadioDomain(res.domain?.enabled === true ? 'custom' : 'default');
        } else {
          toast.error('Failed to save changes');
        }
        setIsSaveLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsSaveLoading(false);
      });
  };

  const currentCheckStatus = () => {
    if (isCustomDomainChecking) {
      return <LoadingWheel />;
    } else if (isStatusConnected) {
      return <GreenWarning>Connected</GreenWarning>;
    } else {
      return <RedWarning>Not Connected</RedWarning>;
    }
  };

  const handleCheckStatus = async () => {
    setIsCustomDomainChecking(true);

    const checkResult = await props.identityStore?.getCustomDomainStatus(
      props.organizationId,
      props.identityStore?.relyingParty?.id ?? ''
    );
    if (!isApiError(checkResult)) {
      setIsCustomDomainChecking(false);

      if (checkResult?.verified === true) {
        const result = await props.identityStore?.get(
          props.organizationId,
          props.identityStore?.relyingParty?.id ?? ''
        );
        if (!isApiError(result)) {
          setIsStatusConnected(result?.domain?.verified ?? false);
        }
      }
    } else {
      setIsCustomDomainChecking(false);
      toast.error('Failed to get status');
    }
  };

  const hasSomethingChanged = () => {
    if (pickedRadioDomain === 'default' && customDomain != '') {
      return true;
    } else if (pickedRadioDomain === 'custom' && customDomain !== newCustomDomain && isCustomDomainValid) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    props.identityStore?.get(props.organizationId, props.identityStore?.relyingParty?.id ?? '').then((res) => {
      if (!isApiError(res)) {
        setDefaultDomain(`${res.id}.identity.hanko.io`);
        setCustomDomain(res.domain?.domain ?? '');
        setNewCustomDomain(res.domain?.domain ?? '');
        setIsStatusConnected(res.domain?.verified ?? false);
        setPickedRadioDomain(res.domain?.enabled === true ? 'custom' : 'default');
      } else {
        // error
        toast.error('Failed to load custom domains');
      }
    });
  }, [props.organizationId, props.identityStore]);

  return (
    <>
      <Container>
        <Title>
          Custom domain
          {!props.identityStore?.relyingParty?.subscription?.active ? (
            <SubscriptionLabelWrapper onClick={() => setIsUpgradeModalOpen(true)}>Pro</SubscriptionLabelWrapper>
          ) : null}
        </Title>
        <ColumnWrapper>
          <ColumnLeft>
            <LeftText>
              With a <BoldRed>Custom Domain</BoldRed> you can use Hanko Identity with a subdomain of your brand instead
              of the default domain provided by Hanko. You need to be on a paid plan to use this feature.
              <Warning>
                <BoldRed>Warning: </BoldRed>The WebAuthn credentials of your users will be bound (scoped) to this
                domain. Changing the domain makes all existing credentials unusable.
              </Warning>
            </LeftText>
          </ColumnLeft>
          <ColumnRight>
            <RadioWrapper>
              <InputWithRadioButton
                type="url"
                label="Default domain"
                radioValue="default"
                active={pickedRadioDomain === 'default'}
                value={defaultDomain}
                name="domain-radio"
                onRadioChange={() => setPickedRadioDomain('default')}
                readOnly
                copyButtonValue={defaultDomain}
                placeholder="http://localhost"
              />
            </RadioWrapper>
            <form style={{ position: 'relative' }} onSubmit={(e) => e.preventDefault()}>
              <RadioWrapper>
                <InputWithRadioButton
                  label="Custom domain"
                  radioValue="custom"
                  active={pickedRadioDomain === 'custom'}
                  name="domain-radio"
                  onChange={handleCustomDomainChange}
                  onRadioChange={() => setPickedRadioDomain('custom')}
                  value={newCustomDomain}
                  placeholder="auth.yourdomain.com"
                  disabled={!props.identityStore?.relyingParty?.subscription?.active}
                />
              </RadioWrapper>
              {props.identityStore?.relyingParty?.subscription?.active === true ? null : (
                <OverlayContainer
                  onClick={() => {
                    !props.identityStore?.relyingParty?.subscription?.active && setIsUpgradeModalOpen(true);
                  }}
                />
              )}
            </form>
            {newCustomDomain.length > 0 && !isCustomDomainValid ? (
              <RedWarning style={{ marginTop: '10px' }}>Enter valid domain ({`<subdomain>.<domain>.<tld>`})</RedWarning>
            ) : null}
            {newCustomDomain.length > 0 && pickedRadioDomain === 'custom' && isCustomDomainValid ? (
              <>
                <CNameWrapper>
                  <CNameText>
                    Visit the admin console of your DNS provider (typically the website you purchased your domain from)
                    and create a <Strong>CNAME Record:</Strong>
                  </CNameText>
                  <CustomDomainField>
                    <CustomDomainText>Record Name</CustomDomainText>
                    <CopyableText backgroundColor="#727a82" style={{ height: '40px' }}>
                      {getSubDomain(newCustomDomain)}
                    </CopyableText>
                  </CustomDomainField>
                  <CustomDomainField>
                    <CustomDomainText>Value</CustomDomainText>
                    <CopyableText backgroundColor="#727a82" style={{ height: '40px' }}>
                      {'identity.hanko.io.'}
                    </CopyableText>
                  </CustomDomainField>
                  {customDomain != '' ? (
                    <StatusWrapper>
                      {currentCheckStatus()}
                      <CheckStatusButton onClick={handleCheckStatus}>Check status</CheckStatusButton>
                    </StatusWrapper>
                  ) : null}
                </CNameWrapper>
              </>
            ) : null}
            <SaveButton onClick={() => setIsDomainConfirmationOpen(true)} disabled={!hasSomethingChanged()}>
              Save changes
            </SaveButton>
          </ColumnRight>
        </ColumnWrapper>
      </Container>
      <DomainConfirmationModal
        isOpen={isDomainConfirmationOpen}
        onClose={() => setIsDomainConfirmationOpen(false)}
        isLoading={isSaveLoading}
        onSubmit={handleSaveChanges}
      />
      <UpgradeModal
        isOpen={isUpgradeModalOpen}
        onClose={() => setIsUpgradeModalOpen(false)}
        organizationId={props.organizationStore?.organization?.id ?? ''}
        relyingPartyId={props.identityStore?.relyingParty?.id ?? ''}
        projectType="identity"
      />
      <UpgradeSuccessModal
        onClose={() => {
          const uri = window.location.toString();
          if (uri.indexOf('#') > 0) {
            const cleanUri = uri.substring(0, uri.indexOf('#'));
            window.history.replaceState({}, document.title, cleanUri);
          }
          setIsSuccessModalOpen(false);
        }}
        isOpen={isSuccessModalOpen}
        projectType="identity"
      />
    </>
  );
};

export const RelyingPartyCustomDomain = inject(
  'identityStore',
  'organizationStore'
)(observer(RelyingPartyCustomDomainComponent));
