import { OidcConfigModel } from './../models/OidcConfigModel';
import { action, observable } from "mobx";
import { RelyingPartyAppsResponse } from "../types/relying_party/RelyingPartyApps";
import { GET_APP_DETAILS, REGENERATE_APP_SECRET, RELYING_PARTY_APPS, GET_OIDC_CONFIGURATION } from "../constants/Url";
import { ApiError, handleApiResponse, isApiError } from "../helper/ResponseHelper";
import RequestTimeout from '../helper/RequestTimeout';

export class OidcClientStore {
    @observable client?: RelyingPartyAppsResponse
    @observable clients?: RelyingPartyAppsResponse[]
    @observable endpoints?: OidcConfigModel

    @action
    list = async (organizationId: string, identityTenantId: string) => {
        const response = await RequestTimeout(RELYING_PARTY_APPS(organizationId, identityTenantId), {
            method: 'GET',
            credentials: 'same-origin',
            cache: 'no-cache',
            mode: 'cors'
        })

        return handleApiResponse<RelyingPartyAppsResponse[]>(response).then(this.listSuccess).catch(this.error)
    }

    @action
    create = async (organizationId: string, identityTenantId: string, oidcClient: RelyingPartyAppsResponse) => {
        const response = await RequestTimeout(RELYING_PARTY_APPS(organizationId, identityTenantId), {
            method: 'POST',
            credentials: 'same-origin',
            cache: 'no-cache',
            mode: 'cors',
            body: JSON.stringify(oidcClient)
        })
        return handleApiResponse<RelyingPartyAppsResponse>(response).then(this.success).catch(this.error)
    }

    @action
    get = async (organizationId: string, identityTenantId: string, oidcClientId: string) => {
        const response = await RequestTimeout(GET_APP_DETAILS(organizationId, identityTenantId, oidcClientId), {
            method: 'GET',
            credentials: 'same-origin',
            cache: 'no-cache',
            mode: 'cors'
        })
        return handleApiResponse<RelyingPartyAppsResponse>(response).then(this.success).catch(this.error)
    }

    @action
    update = async (organizationId: string, identityTenantId: string, oidcClientId: string, oidcClient: RelyingPartyAppsResponse) => {
        const response = await RequestTimeout(GET_APP_DETAILS(organizationId, identityTenantId, oidcClientId), {
            method: 'PATCH',
            credentials: 'same-origin',
            cache: 'no-cache',
            mode: 'cors',
            body: JSON.stringify(oidcClient)
        })
        return handleApiResponse<RelyingPartyAppsResponse>(response).then(this.success).catch(this.error)
    }

    @action
    delete = async (organizationId: string, identityTenantId: string, oidcClientId: string) => {
        const response = await RequestTimeout(GET_APP_DETAILS(organizationId, identityTenantId, oidcClientId), {
            method: 'DELETE',
            credentials: 'same-origin',
            cache: 'no-cache',
            mode: 'cors',
        })

        if (response.ok) {
            this.client = undefined
        }

        return response.ok
    }

    @action
    regenerateSecret = async (organizationId: string, identityTenantId: string, oidcClientId: string) => {
        const response = await RequestTimeout(REGENERATE_APP_SECRET(organizationId, identityTenantId, oidcClientId), {
            method: 'POST',
            credentials: 'same-origin',
            cache: 'no-cache',
            mode: 'cors',
        })
        return handleApiResponse<RelyingPartyAppsResponse>(response).then(this.success).catch(this.error)
    }

    @action
    getOidcConfig = async (organizationId: string, identityTenantId: string) => {
        const response = await RequestTimeout(GET_OIDC_CONFIGURATION(organizationId, identityTenantId), {
            method: 'GET',
            credentials: 'same-origin',
            cache: 'no-cache',
            mode: 'cors'
        })

        return handleApiResponse<OidcConfigModel>(response).then(this.endpointsSuccess).catch(this.error)
    }

    @action.bound
    success = (result: RelyingPartyAppsResponse | ApiError) => {
        if (!isApiError(result)) {
            this.client = result
        }
        return result
    }

    @action.bound
    listSuccess = (result: RelyingPartyAppsResponse[] | ApiError) => {
        if (!isApiError(result)) {
            this.clients = result
        }
        return result
    }

    @action.bound
    endpointsSuccess = (result: OidcConfigModel | ApiError) => {
        if (!isApiError(result)) {
            this.endpoints = result
        }
        return result
    }

    @action.bound
    error = (reason: any): ApiError => {
        return {
            code: 0,
            message: "Could not get fetch"
        }
    }
}

export const oidcClientStore = new OidcClientStore()
