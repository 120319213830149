import React from 'react';
import { ColBig, ColSmall, Grid, Row } from '../atoms/Grid';
import { Menu } from '../molecules/Menu';
import { MenuItemModel } from '../../models/MenuItemModel';
import { ReactComponent as DashboardIcon } from '../../assets/dashboard.svg';
import { ReactComponent as PaletteIcon } from '../../assets/palette.svg';
import { ReactComponent as FingerprintIcon } from '../../assets/fingerprint.svg';
import { ReactComponent as MailIcon } from '../../assets/mail.svg';
import { ReactComponent as KeyIcon } from '../../assets/key.svg';
import { ReactComponent as BurgerIcon } from '../../assets/burger.svg';
import { ReactComponent as WorldIcon } from '../../assets/world.svg';
import { ReactComponent as EnvelopeIcon } from '../../assets/envelope.svg';
import { ReactComponent as PeopleIcon } from "../../assets/people_outline.svg";
import { inject, observer } from 'mobx-react';
import { RelyingPartyStore } from '../../store/RelyingPartyStore';
import { OrganizationStore } from '../../store/OrganizationStore';
import { ApiKeyStore } from '../../store/ApiKeyStore';
import { AppStateStore } from '../../store/AppStateStore';
import { IdentityStore } from "../../store/IdentityProjectStore";

interface Props {
  relyingPartyStore?: RelyingPartyStore;
  organizationStore?: OrganizationStore;
  apiKeyStore?: ApiKeyStore;
  appStateStore?: AppStateStore;
  identityStore?: IdentityStore
  projectId: string
  children: React.ReactNode;
}

const menu = (organizationId: string, relyingPartyId: string, showIdentityMenu: boolean): MenuItemModel[] => {
  if (showIdentityMenu) {
    return [
      {
        title: 'Dashboard',
        link: '/organization/' + organizationId + '/relyingParty/' + relyingPartyId + '/dashboard',
        icon: DashboardIcon,
      },
      {
        title: 'Your apps',
        link: '/organization/' + organizationId + '/relyingParty/' + relyingPartyId + '/apps',
        icon: BurgerIcon,
      },
      {
        title: 'Users',
        link: '/organization/' + organizationId + '/relyingParty/' + relyingPartyId + '/um',
        icon: PeopleIcon,
      },
      {
        title: 'Brand',
        link: '/organization/' + organizationId + '/relyingParty/' + relyingPartyId + '/brand',
        icon: PaletteIcon,
      },
      {
        title: 'Custom domain',
        link: '/organization/' + organizationId + '/relyingParty/' + relyingPartyId + '/custom_domain',
        icon: WorldIcon,
      },
      {
        title: 'Emails',
        link: '/organization/' + organizationId + '/relyingParty/' + relyingPartyId + '/emails',
        icon: EnvelopeIcon,
      }
    ];
  }

  return [
    {
      title: 'Dashboard',
      link: '/organization/' + organizationId + '/relyingParty/' + relyingPartyId + '/dashboard',
      icon: DashboardIcon,
    },
    {
      title: 'Brand',
      link: '/organization/' + organizationId + '/relyingParty/' + relyingPartyId + '/brand',
      icon: PaletteIcon,
    },
    {
      title: 'API keys',
      link: '/organization/' + organizationId + '/relyingParty/' + relyingPartyId + '/api_keys',
      icon: KeyIcon,
    },
    {
      title: 'Passlinks',
      link: '/organization/' + organizationId + '/relyingParty/' + relyingPartyId + '/passlinks',
      icon: MailIcon,
    },
    {
      title: 'WebAuthn',
      link: '/organization/' + organizationId + '/relyingParty/' + relyingPartyId + '/webauthn',
      icon: FingerprintIcon,
    },
  ];
};

const RelyingPartyContainerComponent = (props: Props) => {
  const { appStateStore, organizationStore, relyingPartyStore, identityStore, projectId } = props;
  const organizationId = organizationStore?.organization?.id ?? '';

  const isIdentity = identityStore?.relyingParty?.id === projectId
  const projectName = relyingPartyStore?.relyingParty?.id === projectId ? relyingPartyStore?.relyingParty?.name ?? '' : identityStore?.relyingParty?.name ?? ''

  appStateStore?.setBreadcrumbs([
    {
      title: organizationStore?.organization?.name ?? '',
      link: '/organization/' + organizationId + '/relyingParty',
    },
    {
      title: projectName,
      link: '/organization/' + organizationId + '/relyingParty/' + projectId + '/dashboard',
    },
  ]);

  return (
    <Grid>
      <Row>
        <ColSmall>
          <Menu
            menuItems={menu(
              organizationId,
              projectId,
              isIdentity
            )}
          />
        </ColSmall>
        <ColBig>{props.children}</ColBig>
      </Row>
    </Grid>
  );
};

export const RelyingPartyContainer = inject(
  'organizationStore',
  'relyingPartyStore',
  'appStateStore',
  'identityStore'
)(observer(RelyingPartyContainerComponent));
