import React, { useState } from 'react'
import styled from 'styled-components'
import ReactModal from 'react-responsive-modal'
import { FormList, FormListItem } from '../../atoms/List'
import { PrimaryButton, SecondaryButton } from '../../atoms/Button'
import { isApiError } from '../../../helper/ResponseHelper'
import { toast } from 'react-toastify'
import { useCurrentRoute, useNavigation } from "react-navi";
import { inject, observer } from "mobx-react";
import { relyingPartyStore, RelyingPartyStore } from "../../../store/RelyingPartyStore";
import { OrganizationStore } from "../../../store/OrganizationStore";
import { BACKEND_LOGIN_REDIRECT_URL } from "../../../constants/Url";
import { identityStore, IdentityStore } from "../../../store/IdentityProjectStore";
import { useMedia } from 'react-media'
import { MEDIA_QUERIES } from '../../../constants/MediaQueries'
import ReactModalStyle from '../../../constants/ReactModalStyle'

const Title = styled.h1({
    fontSize: '25px',
    color: '#FFFFFF',
    fontWeight: 'bold',
    letterSpacing: '0.28px',
    marginLeft: 0,
})

const Description = styled.p({
    color: '#FFFFFF',
    fontSize: '16px',
    letterSpacing: '0.18px',
    wordBreak: 'break-all',
    marginTop: '29px',
})

const Container = styled.div({
    margin: '80px 128px',
    color: '#FFFFFF'
})

const CheckboxContainer = styled.div({
    display: 'flex',
    flexDirection: 'row'
})

const CancelButton = styled(SecondaryButton)({
    color: '#E1E7EC',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    ':hover': {
        backgroundColor: 'transparent',
        borderColor: 'transparent'
    },
    width: '50%'
})

const ButtonWrapper = styled.div({
    display: 'flex',
    flexDirection: 'row',
    width: '70%',
})

const ButtonWrapperWrapper = styled.div({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end',
})

const Checkbox = styled.input({
    height: '20px',
    width: '20px'
})

const CheckboxLabel = styled.label({
    fontSize: '16px',
    marginLeft: '8px'
})

const CheckboxWrapper = styled.div({
    display: 'flex',
    alignItems: 'center'
})

const CheckboxListItem = styled(FormListItem)({
    margin: '20px 0 48px 0'
})

interface Props {
    isOpen: boolean
    onClose: () => void
    relyingPartyStore?: RelyingPartyStore
    organizationStore?: OrganizationStore
    identityStore?: IdentityStore
    projectType?: 'identity' | 'api'
}

type State = {
    isCheckboxChecked: boolean
}

const DeleteRelyingPartyModalComponent = (props: Props) => {
    const route = useCurrentRoute()
    const navigation = useNavigation()
    const isSmallScreen = useMedia({ query: MEDIA_QUERIES.tablet });
    const [state, setState] = useState<State>({
        isCheckboxChecked: false
    })

    const deleteRelyingParty = async () => {
        const {organizationStore, relyingPartyStore} = props
        const organizationId = organizationStore?.organization?.id ?? ''
        const result = props.projectType === 'identity' ?
            await props.identityStore?.delete(organizationId, identityStore?.relyingParty?.id ?? '') :
            await props.relyingPartyStore?.delete(organizationId, relyingPartyStore?.relyingParty?.id ?? '')
        if (!isApiError(result)) {
            toast.success("Project deleted")
            navigation.navigate('/organization/' + organizationId + '/relyingParty')
        } else if (result.code === 401) {
            window.location.assign(BACKEND_LOGIN_REDIRECT_URL(route.url.href))
        } else {
            toast.error("Project could not be deleted")
        }
    }

    const onClose = () => {
        setState({
            isCheckboxChecked: false
        })
        props.onClose()
    }

    const {isOpen} = props
    const {isCheckboxChecked} = state

    const rpName = props.projectType === 'identity' ? identityStore?.relyingParty?.name : relyingPartyStore.relyingParty?.name

    return <ReactModal
        open={isOpen}
        onClose={onClose}
        styles={ReactModalStyle({isSmallScreen})}
        center={true}
        blockScroll
    >
        <Container>
            <Title>Delete project</Title>
            <Description>
                Are you sure you want to delete '{rpName}'? All your data will be lost.
            </Description>
            <FormList style={{margin: 0}}>
                <CheckboxListItem>
                    <CheckboxWrapper>
                        <Checkbox type="checkbox" id="delete_confirm" checked={isCheckboxChecked} onChange={() => {
                            setState({
                                isCheckboxChecked: !isCheckboxChecked
                            })
                        }}/>
                        <CheckboxLabel htmlFor="delete_confirm">Yes I'm sure, delete project</CheckboxLabel>
                    </CheckboxWrapper>
                </CheckboxListItem>
                <FormListItem>
                    <ButtonWrapperWrapper>
                        <ButtonWrapper>
                            <CancelButton type="button" onClick={onClose}>Cancel</CancelButton>
                            <PrimaryButton type="submit" onClick={deleteRelyingParty}
                                           disabled={!isCheckboxChecked}>Delete</PrimaryButton>
                        </ButtonWrapper>
                    </ButtonWrapperWrapper>
                </FormListItem>
            </FormList>

            {/*<FormList style={{margin: 0}}>*/}
            {/*    <FormListItem>*/}
            {/*        <CheckboxContainer>*/}
            {/*            <input type="checkbox" name="delete_accept" onChange={onHandleChange}*/}
            {/*                   checked={isCheckboxChecked}/>*/}
            {/*            <label htmlFor="delete_accept" style={{color: '#FFFFFF', marginLeft: '8px'}}>Yes, I'm sure,*/}
            {/*                delete the Relying Party</label>*/}
            {/*        </CheckboxContainer>*/}
            {/*    </FormListItem>*/}
            {/*    <FormListItem><PrimaryButton onClick={deleteRelyingParty} disabled={!isCheckboxChecked}>Delete Relying*/}
            {/*        Party</PrimaryButton></FormListItem>*/}
            {/*    <FormListItem><SecondaryButton onClick={onClose}>Cancel</SecondaryButton></FormListItem>*/}
            {/*</FormList>*/}
        </Container>
    </ReactModal>
}

export const DeleteRelyingPartyModal = inject('organizationStore', 'relyingPartyStore', 'identityStore')(observer(DeleteRelyingPartyModalComponent))