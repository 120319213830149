import React, { ChangeEvent, useEffect, useState } from 'react'
import styled from 'styled-components'
import ReactModal from 'react-responsive-modal'
import { FormList, FormListItem } from "../../atoms/List";
import { Input } from "../../atoms/Input";
import { PrimaryButton } from "../../atoms/Button";
import { inject, observer } from "mobx-react";
import { passlinkRedirectUrlStore, PasslinkRedirectUrlStore } from "../../../store/PasslinkRedirectUrlStore";
import { isApiError } from "../../../helper/ResponseHelper";
import { toast } from "react-toastify";
import { BACKEND_LOGIN_REDIRECT_URL } from "../../../constants/Url";
import { RelyingPartyStore } from "../../../store/RelyingPartyStore";
import { OrganizationStore } from "../../../store/OrganizationStore";
import { useCurrentRoute } from "react-navi";
import { PasslinkRedirectUrlModel } from "../../../models/PasslinkRedirectUrlModel";
import {
    ButtonWrapper,
    ButtonWrapperWrapper,
    CancelButton,
    Checkbox,
    CheckboxLabel,
    CheckboxListItem,
    CheckboxWrapper
} from './DeletePasslinkRedirectUrlModal'
import { MEDIA_QUERIES } from '../../../constants/MediaQueries';
import { useMedia } from 'react-media';
import ReactModalStyle from '../../../constants/ReactModalStyle';

const Title = styled.h1({
    fontSize: '25px',
    color: '#FFFFFF',
    fontWeight: 'bold',
    letterSpacing: '0.28px'
})

const Description = styled.p({
    color: '#FFFFFF',
    fontSize: '14px',
    letterSpacing: '0.18px',
    margin: 0,
})

const List = styled(FormList)({
    margin: '20px 0 0 0'
})

const Container = styled.div({
    margin: '80px 128px',
    color: '#FFFFFF'
})

type State = {
    default: boolean
    url: string
    valid: boolean
}

interface Props {
    isOpen: boolean
    onClose: () => void
    relyingPartyStore?: RelyingPartyStore
    organizationStore?: OrganizationStore
    passlinkRedirectUrlStore?: PasslinkRedirectUrlStore
}

export const AddPasslinkRedirectUrlModalComponent = (props: Props) => {
    const [state, setState] = useState<State>({default: false, url: "", valid: false})
    const [isCreateLoading, setIsCreateLoading] = useState(false);
    const route = useCurrentRoute()
    const isSmallScreen = useMedia({ query: MEDIA_QUERIES.tablet });

    const createRedirectURL = async (e: React.FormEvent) => {
        e.preventDefault()

        if (isCreateLoading) {
            return;
        }

        setIsCreateLoading(true);
        const {organizationStore, relyingPartyStore} = props
        const relyingPartyId = relyingPartyStore?.relyingParty?.id || ""
        const organizationId = organizationStore?.organization?.id || ""
        const model = {url: state.url, default: state.default} as PasslinkRedirectUrlModel


        const result = await props.passlinkRedirectUrlStore?.create(organizationId, relyingPartyId, model)
        if (!isApiError(result)) {
            toast.success("Redirect URL created")
            setState({...state, valid: false, url: "", default: false})
            onClose()
        } else if (result.code === 401) {
            window.location.assign(BACKEND_LOGIN_REDIRECT_URL(route.url.href))
        } else {
            toast.error("Redirect URL could not be created")
            onClose()
        }

        setIsCreateLoading(false);
    }

    const setDefault = async (e: ChangeEvent<HTMLInputElement>) => {
        setState({...state, default: e.target.checked})
    }
    const setRedirectUrl = async (e: ChangeEvent<HTMLInputElement>) => {
        setState({...state, url: e.target.value, valid: e.target.validity.valid})
    }

    const onClose = () => {
        setState({
            ...state,
            url: "",
            default: false
        })
        props.onClose()
    }

    return <ReactModal
        open={props.isOpen}
        onClose={onClose}
        styles={ReactModalStyle({isSmallScreen, customStyles: {width: 'auto'}})}
        center={true}
        blockScroll
    >
        <Container>
            <Title>Add new Redirect URL</Title>
            <Description>
                The redirect URL must match your target URL. Make sure to provide subdirectories, query
                parameters will be ignored.
            </Description>
            <form onSubmit={createRedirectURL}>
                <List>
                    <FormListItem>
                        <Input type={"url"} label="Redirect URL" name="redirect_url"
                               placeholder={"e.g. https://auth.yourdomain.com/signup"}
                               onChange={setRedirectUrl} pattern={"^https?://.*"}/>
                    </FormListItem>
                    <CheckboxListItem>
                        <CheckboxWrapper>
                            <Checkbox type="checkbox" id="default_url" checked={state.default} onChange={setDefault}/>
                            <CheckboxLabel htmlFor="default_url">Make URL default</CheckboxLabel>
                        </CheckboxWrapper>
                    </CheckboxListItem>
                    <FormListItem>
                        <ButtonWrapperWrapper>
                            <ButtonWrapper>
                                <CancelButton type="button" onClick={onClose}>Cancel</CancelButton>
                                <PrimaryButton type="submit" disabled={!state.valid} isLoading={isCreateLoading}>Create</PrimaryButton>
                            </ButtonWrapper>
                        </ButtonWrapperWrapper>
                    </FormListItem>
                </List>
            </form>
        </Container>
    </ReactModal>
}

export const AddPasslinkRedirectUrlModal = inject('relyingPartyStore', 'organizationStore', 'passlinkRedirectUrlStore')(observer(AddPasslinkRedirectUrlModalComponent))
