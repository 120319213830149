import { Color } from '../components/atoms/Color';

type Args = {
  isSmallScreen?: boolean;
  customStyles?: {};
};

export default ({ isSmallScreen, customStyles }: Args) => ({
  modal: {
    width: '800px',
    minWidth: isSmallScreen ? '300px' : 'auto',
    minHeight: isSmallScreen ? '600px' : 'auto',
    overflow: isSmallScreen ? 'scroll' : 'initial',
    padding: 0,
    boxSizing: 'border-box',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: Color.darkBlue,
    ...customStyles,
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    padding: 0,
  },
  closeButton: {
    position: isSmallScreen ? 'fixed' : 'auto',
    top: isSmallScreen ? '130px' : '14px',
    cursor: 'pointer',
    fill: Color.iconGrey,
    outline: 'none',
  },
});
