import { map, Matcher, redirect } from "navi";
import { userStore } from "../store/UserStore";
import { isApiError } from "./ResponseHelper";

export const withAuthentication = (matcher: Matcher<object, object>) => {
    return map(async (request, context) => {
            const requestedUrl = encodeURIComponent(request.mountpath + request.search)
            const user = await userStore.get()
            return isApiError(user) ? redirect('/login?redirect_to=' + requestedUrl) : matcher
        }
    )
}