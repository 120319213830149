import styled from 'styled-components'

export const Grid = styled.div({
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
})

export const Row = styled.div({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
})

export const ColSmall = styled.div({
    width: 'calc(25% - 25px)',
    margin: '0 25px 25px 0',
    ":first-child": {
        marginLeft: '0',
        width: 'calc(24% - 12.5px)'
    },
    ":last-child": {
        marginRight: '0',
        width: 'calc(24% - 12.5px)'
    }
})

export const ColMid = styled(ColSmall)({
    width: 'calc(38% - 37.5px)'
})

export const ColBig = styled(ColSmall)({
    width: 'calc(75% - 25px)',
    ":first-child": {
        width: 'calc(75% - 12.5px)'
    },
    ":last-child": {
        width: 'calc(75% - 12.5px)'
    }
})
