import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { StatisticsStore } from '../../store/StatisticsStore';
import { SettingsContainer } from '../atoms/Container';
import { SettingsTitle } from '../atoms/Texts';
import { InternalStatsRepresentation } from '../../models/LoginStatistic';
import { Color } from '../atoms/Color';

const Title = styled(SettingsTitle)({
  fontSize: '24px',
});

const ChartContainer = styled(SettingsContainer)({
  width: 'calc(100% - 60px)',
});

interface Props {
  activeUsersPerDay: StatisticsStore['activeUsersPerDay'];
  requestsPerDay: StatisticsStore['requestsPerDay'];
  endTime: moment.Moment;
  startTime: moment.Moment;
}

type LoginData = {
  labels: string[];
  datasets: any[];
};

const makeAxis = (id: string, label: string, position: string) => {
  return {
    id: id,
    stepSize: 1,
    type: 'linear',
    position: position,
    ticks: {
      callback: (value: any) => {
        return value % 1 === 0 ? value : undefined;
      },
      suggestedMin: 0,
      fontColor: '#FFFFFF',
    },
    scaleLabel: {
      display: true,
      labelString: label,
      fontFamily: 'IBM Plex Sans',
      fontSize: 16,
      fontColor: '#FFFFFF',
    },
  };
};

const makeDataset = (name: string, axisId: string, color: string, data: number[]) => {
  return {
    label: name,
    backgroundColor: color,
    borderColor: color,
    yAxisID: axisId,
    fill: false,
    lineTension: 0,
    cubicInterpolationMode: 'monotone',
    data: data,
  };
};

const loginDataOptions = {
  scales: {
    xAxes: [
      {
        ticks: {
          fontColor: '#FFFFFF',
        },
      },
    ],
    yAxes: [makeAxis('Users', 'Daily Active Users', 'left'), makeAxis('Logins', 'Logins', 'right')],
  },
  legend: {
    labels: {
      fontColor: '#FFFFFF',
    },
  },
  tooltips: {
    mode: 'x',
    intersect: false,
  },
};

const daysBetween = (from: moment.Moment, to: moment.Moment): string[] => {
  const fromDate = from.startOf('day');
  const toDate = to.startOf('day');

  const span = moment.duration(toDate.diff(fromDate)).asDays();
  const days = [];
  for (let i = 0; i <= span; i++) {
    days.push(moment(fromDate).add(i, 'day').startOf('day').format('YYYY-MM-DDTHH:mm:ss') + 'Z');
  }

  return days;
};

const DashboardChart = ({ activeUsersPerDay, requestsPerDay, endTime, startTime }: Props) => {
  const [loginData, setLoginData] = useState<LoginData>({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    const dateSpan = daysBetween(startTime.clone(), endTime.clone());
    const stats = dateSpan.reduce((acc: InternalStatsRepresentation[], entry) => {
      let activeUsers = activeUsersPerDay?.find((value) => entry === value.date)?.activeUsers ?? 0;
      let requestsCount = requestsPerDay?.find((value) => entry === value.date)?.requestsCount ?? 0;

      return [
        ...acc,
        {
          date: entry,
          activeUsers,
          requestsCount,
        },
      ];
    }, []);

    const labels = stats.map((entry) => moment(entry.date).format('l'));

    const dailyActiveUsers = stats.map((entry) => entry.activeUsers);

    const dailyLogins = stats.map((entry) => entry.requestsCount);

    setLoginData({
      labels: labels,
      datasets: [
        makeDataset('Daily Active Users', 'Users', Color.primaryRed, dailyActiveUsers ?? [0]),
        makeDataset('Logins', 'Logins', '#34868E', dailyLogins ?? [0]),
      ],
    });
  }, [activeUsersPerDay, requestsPerDay]);

  return (
    <ChartContainer>
      <Title>Usage (last {loginData.labels.length} days)</Title>
      <Line data={loginData} options={loginDataOptions} height={100} />
    </ChartContainer>
  );
};

export default DashboardChart;
