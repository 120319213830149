import React, { ChangeEvent, useState } from 'react';
import ReactModal from 'react-responsive-modal';
import { Input } from '../atoms/Input';
import { PrimaryButton, SecondaryButton } from '../atoms/Button';
import { isApiError } from '../../helper/ResponseHelper';
import { FormList, FormListItem } from '../atoms/List';
import { toast } from 'react-toastify';
import { inject, observer } from 'mobx-react';
import { OrganizationStore } from '../../store/OrganizationStore';
import { BACKEND_LOGIN_REDIRECT_URL } from '../../constants/Url';
import { useCurrentRoute } from 'react-navi';
import styled from 'styled-components';
import { useMedia } from 'react-media';
import { MEDIA_QUERIES } from '../../constants/MediaQueries';
import ReactModalStyle from '../../constants/ReactModalStyle';

const CancelButton = styled(SecondaryButton)({
  color: '#E1E7EC',
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  ':hover': {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
  width: '50%',
});

const ButtonWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '70%',
});

const ButtonWrapperWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'flex-end',
});

type State = {
  name: string;
  error?: string;
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  organizationStore?: OrganizationStore;
}

const AddOrganizationModalComponent = (props: Props) => {
  const route = useCurrentRoute();
  const [state, setState] = useState<State>({ name: '' });
  const isSmallScreen = useMedia({ query: MEDIA_QUERIES.tablet });

  const createOrganization = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name } = state;
    if (name.trim().length != 0) {
      const result = await props.organizationStore?.create({ name: name });
      if (!isApiError(result)) {
        window.location.assign('/organization/' + result?.id + '/relyingParty');
      } else if (result.code === 401) {
        window.location.assign(BACKEND_LOGIN_REDIRECT_URL(route.url.href));
      } else {
        toast.error('Organization could not be created');
      }
    } else {
      setState({
        ...state,
        error: 'Required',
      });
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const stateChange = { [target.name]: target.value } as Pick<State, 'name' | 'error'>;
    setState(stateChange);
  };

  const onCancel = () => {
    setState({
      error: undefined,
      name: '',
    });
    props.onClose();
  };

  const { isOpen } = props;
  const { name, error } = state;
  return (
    <ReactModal open={isOpen} onClose={onCancel} center={true} styles={ ReactModalStyle({ isSmallScreen }) } blockScroll>
      <form onSubmit={createOrganization} style={{ margin: '80px 128px' }}>
        <FormList>
          <FormListItem>
            <h2 style={{ margin: 0, color: 'white' }}>Create new organization</h2>
          </FormListItem>
          <FormListItem>
            <Input label="Organization Name" name="name" onChange={handleInputChange} value={name} error={error} />
          </FormListItem>
          <FormListItem>
            <ButtonWrapperWrapper>
              <ButtonWrapper>
                <CancelButton type="button" onClick={onCancel}>
                  Cancel
                </CancelButton>
                <PrimaryButton type="submit" onClick={createOrganization}>
                  Create
                </PrimaryButton>
              </ButtonWrapper>
            </ButtonWrapperWrapper>
          </FormListItem>
        </FormList>
      </form>
    </ReactModal>
  );
};

export const AddOrganizationModal = inject('organizationStore')(observer(AddOrganizationModalComponent));
