import React from "react";

import { MenuItemModel } from '../../models/MenuItemModel'
import { OrganizationStore } from "../../store/OrganizationStore";
import { AppStateStore } from "../../store/AppStateStore";

import { Menu } from "../molecules/Menu";
import { ColBig, ColSmall, Grid, Row } from "../atoms/Grid";
import { ReactComponent as DashboardIcon } from '../../assets/dashboard.svg'
import { inject, observer } from "mobx-react";

export const menu = (organizationId: string): MenuItemModel[] => {
    return [
        {
            title: "Details",
            link: "/organization/" + organizationId + "/settings/general",
            icon: DashboardIcon
        }
    ]
}

interface Props {
    children: React.ReactNode
    organizationStore?: OrganizationStore
    appStateStore?: AppStateStore
}

const OrganizationSettingsComponent = (props: Props) => {
    const {organizationStore, appStateStore} = props
    const organizationId = organizationStore?.organization?.id ?? ''
    const organizationName = organizationStore?.organization?.name ?? ''

    appStateStore?.setBreadcrumbs([
        {
            title: organizationName,
            link: '/organization/' + organizationId + '/relyingParty'
        },
        {
            title: 'Manage Organization',
            link: '/organization/' + organizationId + '/settings/general'
        }
    ])

    return <Grid>
        <Row>
            <ColSmall>
                <Menu menuItems={menu(organizationId)} isProjectMenu />
            </ColSmall>
            <ColBig>
                {props.children}
            </ColBig>
        </Row>
    </Grid>
}

export const OrganizationSettings = inject( 'organizationStore', 'appStateStore')(observer(OrganizationSettingsComponent))