import React from "react";
import styled from "styled-components";
import ReactModal from "react-responsive-modal";
import { ProBadge } from "../atoms/SubscriptionBadge";
import { PrimaryButton } from '../atoms/Button'
import LogoRaw from "../../assets/logo.svg";
import CheckCircleSuccess from "../../assets/check_circle_outline_success.svg"
import { useMedia } from "react-media";
import { MEDIA_QUERIES } from "../../constants/MediaQueries";
import ReactModalStyle from "../../constants/ReactModalStyle";

const Container = styled.div({
    margin: '36px 107px',
})

const Description = styled.span({
    color: '#FFFFFF',
    width: '100%',
    fontSize: '16px',
    fontWeight: 500,
})

const ListItem = styled.li({
    ':not(:first-child)': {
        marginTop: '20px'
    },
    fontSize: '14px',
    fontWeight: 'bold',
    listStyle: 'none',
    backgroundImage: "url('" + CheckCircleSuccess + "')",
    backgroundSize: '20px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left top',
    padding: '0 0 0 32px',
    height: 'auto',
    minHeight: '20px'
})

const List = styled.ul({
    listStyle: 'none',
    padding: 0,
    color: '#FFFFFF',
    width: '100%',
})

interface Props {
    onClose: () => void
    isOpen: boolean
    projectType: 'identity' | 'api'
}

export const UpgradeSuccessModal = (props: Props) => {
    const isSmallScreen = useMedia({ query: MEDIA_QUERIES.tablet });
    const descriptionText = props.projectType === 'identity' ? 'Welcome to Hanko Identity Pro. You can now benefit from the full feature set:' : 'Welcome to Hanko Authentication API Pro. You can now benefit from the full feature set:'
    let featureList = <>
        <List>
            <ListItem>Unlimited monthly active users</ListItem>
            <ListItem>Passlink custom sender*</ListItem>
            <ListItem>Passcode API (SMS)*</ListItem>
        </List>
        <List>
            <ListItem>FIDO MDS with custom authenticators*</ListItem>
            <ListItem>Branded Authenticator App*</ListItem>
        </List>
    </>
    if (props.projectType === 'identity') {
        featureList = <>
            <List>
                <ListItem>Custom domain</ListItem>
                <ListItem>Unlimited monthly active users</ListItem>
            </List>
            <List>
                <ListItem>Mandatory 2FA*</ListItem>
                <ListItem>Pre-integrated KYC providers*</ListItem>
                <ListItem>3rd-party logins*</ListItem>
            </List>
        </>
    }
    return <ReactModal
        onClose={props.onClose}
        open={props.isOpen}
        styles={ReactModalStyle({isSmallScreen, customStyles: {width: '684px'}})}
        center={true}
        blockScroll
    >
        <Container>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: '30px'}}>
                <img src={LogoRaw} alt="Hanko Logo" style={{width: '105px', height: 'auto'}}/>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', marginBottom: '32px'}}>
                <ProBadge style={{fontSize: '19px', fontWeight: 600}}/>
            </div>
            <Description>{descriptionText}</Description>
            <div style={{display: 'flex', flexDirection: 'row', margin: '32px 0 0 0'}}>
                {featureList}
            </div>
            <div style={{color: '#FFFFFF', fontSize: '12px', marginBottom: '32px'}}>
                *Coming soon
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <PrimaryButton style={{width: '135px'}} onClick={props.onClose}>Continue</PrimaryButton>
            </div>
        </Container>
    </ReactModal>
}
