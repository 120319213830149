import styled from "styled-components";
import { FormList, FormListItem } from "../../../atoms/List";
import { RelyingPartyStore } from "../../../../store/RelyingPartyStore";
import { OrganizationStore } from "../../../../store/OrganizationStore";
import React, { ChangeEvent, useState } from "react";
import { useCurrentRoute } from "react-navi";
import { isApiError } from "../../../../helper/ResponseHelper";
import { toast } from "react-toastify";
import { BACKEND_LOGIN_REDIRECT_URL } from "../../../../constants/Url";
import ReactModal from "react-responsive-modal";
import { TextAreaInput } from "../../../atoms/Input";
import { ButtonWrapper, ButtonWrapperWrapper, CancelButton, } from "../DeletePasslinkRedirectUrlModal";
import { PrimaryButton } from "../../../atoms/Button";
import { inject, observer } from "mobx-react";
import { useMedia } from "react-media";
import { MEDIA_QUERIES } from "../../../../constants/MediaQueries";
import ReactModalStyle from "../../../../constants/ReactModalStyle";

const Title = styled.h1({
    fontSize: '25px',
    color: '#FFFFFF',
    fontWeight: 'bold',
    letterSpacing: '0.28px'
})

const List = styled(FormList)({
    margin: '20px 0 0 0'
})

const Container = styled.div({
    margin: '80px 128px',
    color: '#FFFFFF'
})

type State = {
    origin: string
    valid: boolean
    error?: string
}

interface Props {
    isOpen: boolean
    onClose: () => void
    relyingPartyStore?: RelyingPartyStore
    organizationStore?: OrganizationStore
}

const AddMobileOriginModalComponent = (props: Props) => {
    const [state, setState] = useState<State>({origin: '', valid: false, error: undefined})
    const [isCreatingUrl, setIsCreatingUrl] = useState(false)
    const route = useCurrentRoute()
    const isSmallScreen = useMedia({ query: MEDIA_QUERIES.tablet });

    const createRedirectURL = async (e: React.FormEvent) => {
        e.preventDefault()

        if (isCreatingUrl) {
            return;
        }

        setIsCreatingUrl(true);

        const {organizationStore, relyingPartyStore} = props
        const relyingPartyId = relyingPartyStore?.relyingParty?.id || ""
        const organizationId = organizationStore?.organization?.id || ""

        const origins = props.relyingPartyStore?.relyingParty?.origins ?? []
        const newOrigin = state.origin.toUpperCase()

        if (!origins.includes(newOrigin)) {
            origins.push(newOrigin)
        }

        const result = await props.relyingPartyStore?.update(organizationId, relyingPartyId, {
            origins: origins
        })
        if (!isApiError(result)) {
            toast.success("Mobile origins added successfully")
            setState({...state, valid: false, origin: ''})
            onClose()
        } else if (result.code === 401) {
            window.location.assign(BACKEND_LOGIN_REDIRECT_URL(route.url.href))
        } else {
            toast.error("Mobile origin could not be added")
        }

        setIsCreatingUrl(false);
    }

    const setOrigin = async (e: ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value.trim()
        const regex = new RegExp("^[a-fA-F0-9:]{95}$")
        const validity = regex.test(value)
        let errorMessage = undefined
        if (!validity) {
            errorMessage = "Origins must only consist of 'A-F', 'a-f', '0-9', ':' and must be 95 characters long"
        }
        setState({...state, origin: value, valid: validity, error: errorMessage})
    }

    const onClose = () => {
        setState({
            ...state,
            origin,
            valid: false,
            error: undefined
        })
        props.onClose()
    }

    return <ReactModal
        open={props.isOpen}
        onClose={onClose}
        styles={ReactModalStyle({isSmallScreen, customStyles: {width: 'auto'}})}
        center={true}
        blockScroll
    >
        <Container>
            <Title>Add new mobile origin</Title>
            <form onSubmit={createRedirectURL}>
                <List>
                    <FormListItem>
                        <TextAreaInput type="text" label="Mobile origin (SHA256 fingerprint)" name="mobile_origin"
                                       placeholder="AA:BB:CC:DD:EE:FF:..."
                                       error={state.error}
                                       onChange={setOrigin}/>
                    </FormListItem>
                    <FormListItem>
                        <ButtonWrapperWrapper>
                            <ButtonWrapper>
                                <CancelButton type="button" onClick={onClose}>Cancel</CancelButton>
                                <PrimaryButton type="submit" disabled={!state.valid} isLoading={isCreatingUrl}>Create</PrimaryButton>
                            </ButtonWrapper>
                        </ButtonWrapperWrapper>
                    </FormListItem>
                </List>
            </form>
        </Container>
    </ReactModal>
}

export const AddMobileOriginModal = inject('relyingPartyStore', 'organizationStore')(observer(AddMobileOriginModalComponent))