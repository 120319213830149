import { observable, action } from 'mobx'
import {OrganizationModel, OrganizationRequest} from '../models/OrganizationModel'
import { CREATE_ORGANIZATION_URL, GET_IDENTITY_URL, GET_RELYING_PARTIES_URL, ORGANIZATION_URL } from '../constants/Url';
import { ApiError, handleApiResponse, isApiError } from '../helper/ResponseHelper'
import { RelyingPartyModel } from "../models/RelyingPartyModel";
import { ProjectModel } from "../models/ProjectModel";
import { IdentityTenantModel } from "../models/IdentityTenantModel";
import { getIdentityTenantUrl } from "../helper/TenantHelper";
import RequestTimeout from '../helper/RequestTimeout';

export class OrganizationStore {

    @observable organization?: OrganizationModel = undefined;
    @observable relyingParties?: ProjectModel[] = []

    @action
    get = async (organizationId: string) => {
        const response = await RequestTimeout(ORGANIZATION_URL(organizationId), {
            method: 'GET',
            credentials: 'same-origin',
            cache: 'no-cache',
            mode: 'cors'
        });
        return handleApiResponse<OrganizationModel>(response).then(this.success).catch(this.error)
    };

    @action
    update = async (organizationId: string, organization: OrganizationRequest) => {
        const response = await RequestTimeout(ORGANIZATION_URL(organizationId), {
            method: 'PATCH',
            credentials: 'same-origin',
            cache: 'no-cache',
            mode: 'cors',
            body: JSON.stringify(organization),
        });
        return handleApiResponse<OrganizationModel>(response).then(this.success).catch(this.error)
    };

    @action
    create = async (organization: OrganizationRequest) => {
        const response = await RequestTimeout(CREATE_ORGANIZATION_URL, {
            method: 'POST',
            credentials: 'same-origin',
            cache: 'no-cache',
            mode: 'cors',
            body: JSON.stringify(organization)
        })
        return handleApiResponse<OrganizationModel>(response).then(this.success).catch(this.error)
    }

    @action
    listRelyingParties = async (organizationId: string) => {
        const relyingPartiesResponse = await RequestTimeout(GET_RELYING_PARTIES_URL(organizationId), {
            method: 'GET',
            credentials: 'same-origin',
            cache: 'no-cache',
            mode: 'cors'
        });

        const identityResponse = await RequestTimeout(GET_IDENTITY_URL(organizationId), {
            method: 'GET',
            credentials: 'same-origin',
            cache: 'no-cache',
            mode: 'cors'
        });

        const rps = await handleApiResponse<RelyingPartyModel[]>(relyingPartiesResponse)
        const identityTenants = await handleApiResponse<IdentityTenantModel[]>(identityResponse)

        if (!isApiError(rps) && !isApiError(identityTenants)) {
            const rpModels = rps.map((value) => {
                return {
                    id: value.id,
                    name: value.name,
                    domain: value.origin,
                    subscription: {
                        active: value.subscription?.active ?? false
                    },
                    iconUrl: value.icon_url,
                    projectType: 'api'
                } as ProjectModel
            })
            const identityModels = identityTenants.map((value) => {
                return {
                    id: value.id,
                    name: value.name,
                    domain: value.domain?.domain !== undefined ? 'https://' + value.domain?.domain : getIdentityTenantUrl(value.id),
                    subscription: {
                        active: value.subscription?.active ?? false
                    },
                    iconUrl: value?.brand?.logo_url ?? "",
                    projectType: 'identity'
                } as ProjectModel
            })
            this.relyingParties = [...rpModels, ...identityModels]
        }

        return this.relyingParties
    }

    @action.bound
    success = (result: OrganizationModel | ApiError) => {
        if (!isApiError(result)) {
            this.organization = result
        }
        return result
    };

    @action.bound
    listSuccess = (result: ProjectModel[] | ApiError) => {
        if (!isApiError(result)) {
            this.relyingParties = result
        }
        return result
    };

    @action.bound
    error = (reason: any): ApiError => {
        return {
            code: 0,
            message: 'Could not get fetch.'
        }
    }
}

export const organizationStore = new OrganizationStore();
