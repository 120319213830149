import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { OrganizationStore } from "../../store/OrganizationStore";
import { RelyingPartyStore } from "../../store/RelyingPartyStore";
import { apiKeyStore, ApiKeyStore } from "../../store/ApiKeyStore";
import styled from "styled-components";
import { SettingsContainer } from "../atoms/Container";
import { SettingsDescription, SettingsTitle } from "../atoms/Texts";
import { SettingsDescriptionLink } from "../atoms/Link";
import { PrimaryButton } from '../atoms/Button'
import { ApiKeyTableItem } from './settings/ApiKeyTableItem'
import { DeleteApiKeyModal } from "./settings/DeleteApiKeyModal";
import { AddApiKeyModal } from "./settings/AddApiKeyModal";
import { isApiError } from "../../helper/ResponseHelper";
import { toast } from "react-toastify";
import LoadingWheel from "../atoms/LoadingWheel";

const ApiKeysSettingsContainer = styled(SettingsContainer)({
    padding: '30px',
    width: 'auto'
})

const Button = styled(PrimaryButton)({
    paddingLeft: '40px',
    paddingRight: '40px',
    width: '165px',
    whiteSpace: 'nowrap',
    fontWeight: 500
})

const LoadingWheelContainer = styled.div({
    position: 'relative',
    marginTop: '40px',
    marginBottom: '40px'
});

interface Props {
    organizationStore?: OrganizationStore
    relyingPartyStore?: RelyingPartyStore
    apiKeyStore?: ApiKeyStore
}

type State = {
    isRemoveModalOpen: boolean
    isAddModalOpen: boolean
    focusedApiKey?: string
}

const RelyingPartyApiKeysComponent = (props: Props) => {
    const [state, setState] = useState<State>({
        isRemoveModalOpen: false,
        isAddModalOpen: false,
        focusedApiKey: undefined
    });
    const [isCreatingNewKeyLoading, setIsCreatingNewKeyLoading] = useState(false);
    const {organizationStore, relyingPartyStore} = props

    const openAddModal = async () => {
        setIsCreatingNewKeyLoading(true);
        const result = await props.apiKeyStore?.create(organizationStore?.organization?.id || "", relyingPartyStore?.relyingParty?.id || "", {name: ""})
        if (!isApiError(result)) {
            setState({
                ...state,
                isAddModalOpen: true
            })
        } else {
            toast.error("A new API key could not be created")
        }

        setIsCreatingNewKeyLoading(false);
        props.apiKeyStore?.list(organizationStore?.organization?.id || "", relyingPartyStore?.relyingParty?.id || "")
    }

    const closeAddModal = () => {
        setState({
            ...state,
            isAddModalOpen: false
        })
    }

    const deleteApiKey = (id: string) => {
        setState({
            ...state,
            isRemoveModalOpen: true,
            focusedApiKey: id
        })
    }

    const closeRemoveModal = () => {
        setState({
            ...state,
            isRemoveModalOpen: false,
            focusedApiKey: undefined
        })
    }

    return <>
        <ApiKeysSettingsContainer>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div style={{display: 'flex', flexDirection: 'column', marginRight: '150px'}}>
                    <SettingsTitle>API keys</SettingsTitle>
                    <SettingsDescription>API keys are required to make authorized requests to the Hanko API. See
                        the <SettingsDescriptionLink href="https://docs.hanko.io">docs</SettingsDescriptionLink> on how
                        to
                        use API keys.</SettingsDescription>
                </div>
                <div>
                    <Button onClick={openAddModal} isLoading={isCreatingNewKeyLoading} >Create new</Button>
                </div>
            </div>
          {
            props.apiKeyStore?.apiKeyList ? (<table style={{ borderCollapse: 'collapse', margin: '40px 0px', tableLayout: 'fixed' }}>
              <tbody>
                {props.apiKeyStore?.apiKeyList?.map((value, index, array) => {
                  return <ApiKeyTableItem item={value} deleteApiKey={deleteApiKey} />;
                })}
              </tbody>
            </table>) : <LoadingWheelContainer><LoadingWheel /></LoadingWheelContainer>
          }
        </ApiKeysSettingsContainer>
        <AddApiKeyModal
            isOpen={state.isAddModalOpen}
            onClose={closeAddModal}
            organizationId={organizationStore?.organization?.id || ''}
            relyingPartyId={relyingPartyStore?.relyingParty?.id || ''}
        />
        <DeleteApiKeyModal
            isOpen={state.isRemoveModalOpen}
            onClose={closeRemoveModal}
            organizationId={organizationStore?.organization?.id || ''}
            relyingPartyId={relyingPartyStore?.relyingParty?.id || ''}
            apiKeyId={state.focusedApiKey || ''}
        />
    </>
}

export const RelyingPartyApiKeys = inject('apiKeyStore', 'organizationStore', 'relyingPartyStore')(observer(RelyingPartyApiKeysComponent))