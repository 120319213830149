import React, {Fragment, useEffect} from 'react';
import styled from 'styled-components';
import { BreadcrumbModel } from '../../models/BreadcrumbModel';
import { ReactComponent as ArrowIcon } from '../../assets/arrow_right_thin.svg';
import { useNavigation } from 'react-navi';
import { AppStateStore } from '../../store/AppStateStore';
import { OrganizationStore } from '../../store/OrganizationStore';
import { inject, observer } from 'mobx-react';
import {useMedia} from "react-media";
import {MEDIA_QUERIES} from "../../constants/MediaQueries";

const Wrapper = styled.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '40px',
});

const PrimaryLink = styled.a({
  color: '#E1E7EC',
  fontSize: '16px',
  fontWeight: 500,
  letterSpacing: 0,
  textDecoration: 'none',
  lineHeight: '22px',
});

const SecondaryLink = styled(PrimaryLink)({
  display: 'flex',
  fontSize: '16px',
  fontWeight: 'bold',
  marginRight: '4px',
  color: '#E1E7EC',
});

interface Props {
  appStateStore?: AppStateStore;
  organization: OrganizationStore['organization'];
}

export const BreadcrumbsComponent = (props: Props) => {
  const navigation = useNavigation();
  const isMobile = useMedia({ query: MEDIA_QUERIES.tablet });

  useEffect( () => {
    const headerContainer = document.getElementById('headerContainer');
    if (headerContainer && isMobile) {
      headerContainer.style['marginLeft'] = '40px';
    }
    return function() {
      if (headerContainer) {
        headerContainer.style['marginLeft'] = 'calc(24% + 12px)';
      }
    }
  }, [isMobile]);

  return (
    <Wrapper>
      <SecondaryLink href={`/organization/${props.organization?.id}/relyingParty`}>
        {props?.organization?.name}
      </SecondaryLink>
      {props.appStateStore?.breadcrumbs.map((value: BreadcrumbModel, index: number, array: BreadcrumbModel[]) => {
        if (index == 0) {
          if (array.length == 1) {
            return (
              <SecondaryLink
                key={'breadcrumb' + index}
                href={'#'}
                onClick={(e) => {
                  e.preventDefault();
                  navigation.navigate(value.link);
                }}
              >
                {value.title}
              </SecondaryLink>
            );
          } else {
            return (
              <Fragment key={index}>
                <SecondaryLink
                  key={'breadcrumb' + index}
                  href={'#'}
                  onClick={(e) => {
                    e.preventDefault();
                    navigation.navigate(value.link);
                  }}
                >
                  {value.title}
                </SecondaryLink>
                <ArrowIcon style={{ marginRight: '4px', width: '20px', height: '20px' }} fill="#E1E7EC" />
              </Fragment>
            );
          }
        } else if (index < array.length - 1) {
          return (
            <Fragment key={index}>
              <SecondaryLink
                key={'breadcrumb' + index}
                href={'#'}
                onClick={(e) => {
                  e.preventDefault();
                  navigation.navigate(value.link);
                }}
              >
                {value.title}
              </SecondaryLink>
              <ArrowIcon style={{ marginRight: '4px', width: '20px', height: '20px' }} fill="#E1E7EC" />
            </Fragment>
          );
        } else {
          return (
            <PrimaryLink
              key={'breadcrumb' + index}
              href={'#'}
              onClick={(e) => {
                e.preventDefault();
                navigation.navigate(value.link);
              }}
            >
              {value.title}
            </PrimaryLink>
          );
        }
      })}
    </Wrapper>
  );
};

export const Breadcrumbs = inject('appStateStore')(observer(BreadcrumbsComponent));
