import React, { Suspense, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { OrganizationStore } from '../../../store/OrganizationStore';
import { StatisticsStore } from '../../../store/StatisticsStore';
import { IdentityStore } from '../../../store/IdentityProjectStore';
import { SettingsContainer } from '../../atoms/Container';
import { SettingsTitle } from '../../atoms/Texts';
import { CurrentSubscriptionBanner, UpgradeBanner } from '../../atoms/SubscriptionBadge';
import { TenantStatus } from './../TenantStatusComponent';
import { RelyingPartyContextMenu } from '../../molecules/RelyingPartyContextMenu';
import { ReactComponent as LoginIconSvg } from '../../../assets/login.svg';
import { GET_BILLING_PORTAL_URL } from '../../../constants/Url';
import { UpgradeModal } from '../UpgradeModal';
import { UpgradeSuccessModal } from '../UpgradeSuccessModal';
import { Link, useNavigation } from 'react-navi';
import { OidcClientStore } from '../../../store/OidcClientStore';
import { CopyableText } from '../../atoms/CopyableText';
import LoadingWheel from '../../atoms/LoadingWheel';
import DashboardChart from '../DashboardChart';

const Number = styled.span({
  textAlign: 'left',
  fontSize: '36px',
  fontWeight: 300,
  color: '#FFFFFF',
});

const NumberContainer = styled(SettingsContainer)({
  width: '100%',
  marginRight: '25px',
  ':last-child': {
    marginRight: 0,
  },
});

const UpgradeBannerContainer = styled.div({
  marginBottom: '25px',
});

const NumbersContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '20px',
  width: '100%',
});

const TopContainer = styled(SettingsContainer)({
  display: 'flex',
  width: '100%',
  boxSizing: 'border-box',
  alignItems: 'stretch',
  position: 'relative',
  justifyContent: 'space-between',
  height: 'auto',
  color: '#FFFFFF',
  textAlign: 'left',
  marginRight: '20px',
  padding: '35px 30px',
  ':last-child': {
    marginRight: 0,
  },
});

const EmptyImage = styled.div({
  width: '108px',
  height: '108px',
  backgroundColor: '#FFFFFF',
  borderRadius: '50%',
  boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.25)',
});

const Image = styled.img({
  width: '108px',
  height: '108px',
  objectFit: 'contain',
  borderRadius: '50%',
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.25)',
});

const ProjectNameWrapper = styled.div`
  margin-left: 25px;
`;

const ProjectName = styled.h1({
  margin: '0',
  fontSize: '20px',
  fontWeight: 600,
});

const LoadingContainer = styled.div({
  position: 'relative',
  width: '100%',
  height: '270px',
  marginTop: '10px',
  backgroundColor: '#545b61',
  borderRadius: '5px',
});

const CurrentSubscriptionBannerWrapper = styled.div`
  margin-top: -13px;
`;

const TenantUrlWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
`;

const AccessTenantButton = styled.a`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 135px;
  min-width: 135px;
  margin-left: 25px;
  padding: 12px 20px;
  background-color: #ff2e4c;
  border-radius: 3px;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:first-child {
    margin-bottom: 25px;
  }
`;

const NotificationBar = styled.div`
  width: 100%;
  padding: 16px 25px;
  margin-bottom: 25px;
  box-sizing: border-box;
  background: #a5b3e1;
  border-radius: 5px;
  font-size: 13px;
  text-align: left;
`;

const PillLabel = styled.span`
  margin-right: 10px;
  padding: 3px 11px;
  background: #dee5ff;
  border-radius: 50px;
  color: #272f34;
  font-weight: 600;
`;

const NotificationBarText = styled.span`
  color: #161d21;
  font-weight: 400;
`;

const NotificationLink = styled(Link)`
  color: #161d21;
  font-weight: 500;
  text-decoration: underline;
`;

interface Props {
  organizationStore?: OrganizationStore;
  identityStore?: IdentityStore;
  statisticsStore?: StatisticsStore;
  oidcClientStore?: OidcClientStore;
  endTime: moment.Moment;
  startTime: moment.Moment;
}

const IdentityDashboardComponent = (props: Props) => {
  const { statisticsStore, organizationStore, identityStore, oidcClientStore, endTime, startTime } = props;
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  // const [stats, setStats] = useState<InternalStatsRepresentation[]>([]);

  const [tenantStatus, setTenantStatus] = useState<any>();
  const navigation = useNavigation();
  const CURRENT_PROJECT_PATHNAME = navigation.getCurrentValue().url.pathname.split('/dashboard')[0];

  const getTenantUrl = (): string => {
    if (identityStore?.relyingParty?.domain?.verified === true) {
      return 'https://' + identityStore?.relyingParty.domain.domain;
    }
    let env = '';
    if (window.location.origin.includes('.dev.')) {
      env = '.dev';
    } else if (window.location.origin.includes('.stg.')) {
      env = '.stg';
    }

    return 'https://' + identityStore?.relyingParty?.id + '.identity' + env + '.hanko.io';
  };

  const onSubscriptionBannerClick = async () => {
    if (identityStore?.relyingParty?.subscription?.active === true) {
      const response = await fetch(
        GET_BILLING_PORTAL_URL(organizationStore?.organization?.id ?? '') +
          '?return_url=' +
          encodeURIComponent(window.location.toString()),
        {
          method: 'GET',
          credentials: 'same-origin',
          cache: 'no-cache',
          mode: 'cors',
        }
      );
      if (response.status === 201) {
        const redirectUrl = response.headers.get('location');
        window.location.assign(redirectUrl ?? '');
      } else {
        toast.error('Internal server error');
      }
    } else {
      setIsUpgradeModalOpen(true);
    }
  };

  const handleUpdateStatus = async () => {
    const tenantStatus = await identityStore?.getTenantStatus(
      organizationStore?.organization?.id ?? '',
      identityStore?.relyingParty?.id ?? ''
    );

    return setTenantStatus(tenantStatus);
  };

  useEffect(() => {
    statisticsStore?.getActiveUserPerDay(
      'identity',
      organizationStore?.organization?.id ?? '',
      identityStore?.relyingParty?.id ?? '',
      startTime.toISOString(),
      endTime.toISOString()
    );
    statisticsStore?.getRequestsPerDay(
      'identity',
      organizationStore?.organization?.id ?? '',
      identityStore?.relyingParty?.id ?? '',
      startTime.toISOString(),
      endTime.toISOString()
    );
  }, []);

  useEffect(() => {
    return function cleanup() {
      statisticsStore?.setStatsToZero();
    };
  }, [statisticsStore]);

  useEffect(() => {
    const uri = window.location.toString();
    if (uri.indexOf('#') > 0) {
      setIsSuccessModalOpen(true);
    }
  }, []);

  useEffect(() => {
    handleUpdateStatus();
  }, []);

  return (
    <>
      {!oidcClientStore?.clients || oidcClientStore?.clients.length < 1 ? (
        <NotificationBar>
          <PillLabel>Next step</PillLabel>
          <NotificationBarText>
            To use your Identity project, you need to{' '}
            <NotificationLink href={`${CURRENT_PROJECT_PATHNAME}/apps`}>connect your app</NotificationLink>
          </NotificationBarText>
        </NotificationBar>
      ) : null}
      <TopContainer>
        <RelyingPartyContextMenu projectType="identity" />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {identityStore?.relyingParty?.brand.logo_url === undefined ||
          identityStore?.relyingParty?.brand.logo_url === '' ? (
            <EmptyImage />
          ) : (
            <Image src={identityStore?.relyingParty?.brand.logo_url} alt="Logo" />
          )}
          <ProjectNameWrapper>
            <ProjectName>{identityStore?.relyingParty?.name}</ProjectName>
            <CurrentSubscriptionBannerWrapper>
              <CurrentSubscriptionBanner
                isPro={identityStore?.relyingParty?.subscription?.active ?? false}
                onClick={onSubscriptionBannerClick}
                style={{ marginTop: '30px' }}
              />
            </CurrentSubscriptionBannerWrapper>
          </ProjectNameWrapper>
        </div>
        <div style={{ marginTop: '35px' }}>
          <TenantStatus identityTenantStatus={tenantStatus} updateTenantStatus={handleUpdateStatus} isProjectIdentity />
        </div>
        <TenantUrlWrapper>
          <CopyableText style={{ width: '100%' }}>{getTenantUrl()}</CopyableText>
          <AccessTenantButton href={`${getTenantUrl()}/login`} target="_blank">
            <LoginIconSvg fill="#ffffff" />
            <span>Go to login</span>
          </AccessTenantButton>
        </TenantUrlWrapper>
      </TopContainer>
      <Suspense
        fallback={
          <LoadingContainer>
            <LoadingWheel />
          </LoadingContainer>
        }
      >
        <DashboardChart
          activeUsersPerDay={statisticsStore?.activeUsersPerDay}
          requestsPerDay={statisticsStore?.requestsPerDay}
          endTime={endTime}
          startTime={startTime}
        />
      </Suspense>
      <NumbersContainer>
        <NumberContainer>
          {identityStore?.relyingParty?.subscription?.active === true ? null : (
            <UpgradeBannerContainer>
              <UpgradeBanner
                isPro={identityStore?.relyingParty?.subscription?.active ?? false}
                onClick={onSubscriptionBannerClick}
              />
            </UpgradeBannerContainer>
          )}
          <SettingsTitle>Monthly active users (this month)</SettingsTitle>
          <Number>{statisticsStore?.currentMonthActiveUsers?.activeUsers ?? 0}</Number>
        </NumberContainer>
        <NumberContainer>
          <SettingsTitle>Monthly active users (last month)</SettingsTitle>
          <Number>{statisticsStore?.lastMonthActiveUsers?.activeUsers ?? 0}</Number>
        </NumberContainer>
      </NumbersContainer>
      <UpgradeModal
        isOpen={isUpgradeModalOpen}
        onClose={() => setIsUpgradeModalOpen(false)}
        organizationId={organizationStore?.organization?.id ?? ''}
        relyingPartyId={identityStore?.relyingParty?.id ?? ''}
        projectType="identity"
      />
      <UpgradeSuccessModal
        onClose={() => {
          const uri = window.location.toString();
          if (uri.indexOf('#') > 0) {
            const cleanUri = uri.substring(0, uri.indexOf('#'));
            window.history.replaceState({}, document.title, cleanUri);
          }
          setIsSuccessModalOpen(false);
        }}
        isOpen={isSuccessModalOpen}
        projectType="identity"
      />
    </>
  );
};

export const IdentityDashboard = inject(
  'organizationStore',
  'identityStore',
  'statisticsStore',
  'oidcClientStore'
)(observer(IdentityDashboardComponent));
