import React, { useState } from 'react'
import styled from 'styled-components'
import { UserStore } from '../../store/UserStore'
import { useNavigation } from "react-navi";
import { inject, observer } from "mobx-react";
import { ReactComponent as ExpandMoreIcon } from "../../assets/expand_more.svg";
import { ReactComponent as ExpandLessIcon } from "../../assets/expand_less.svg";
import { ReactComponent as AddIcon } from "../../assets/add.svg";
import { ChooseOrganizationDropDownItem } from './ChooseOrganizationDropDownItem'
import { AddOrganizationModal } from "./AddOrganizationModal";
import { Color } from '../atoms/Color';

const DropDownContainer = styled.div({
    cursor: 'pointer',
    display: 'inline-flex',
    zIndex: 20,
    flexDirection: 'row-reverse',
    alignItems: 'center',
    marginRight: '120px',
})

const Menu = styled.div({
    position: 'absolute',
    zIndex: 20,
    right: 0,
    width: '370px',
    marginTop: '30px',
    borderRadius: '4px',
    backgroundColor: '#545B61',
    overflow: 'hidden',
    padding: '8px',
    cursor: 'auto',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 29px rgba(0, 0, 0, 0.25)'
})

const Text = styled.span({
    color: '#FFFFFF',
    fontSize: '16px',
    lineHeight: '21px',
    fontWeight: 500,
    letterSpacing: 0.18,
})

const Background = styled.div({
    position: 'absolute',
    height: '100vh',
    width: '100vw',
    left: 0,
    top: 0,
    zIndex: 10,
    cursor: 'auto',
    opacity: 0
})

const CreateBox = styled.div<{ showBorderTop: boolean }>(props => ({
    display: 'flex',
    padding: '26px 22px',
    ':hover': {
        backgroundColor: '#303940'
    },
    borderTop: props.showBorderTop ? '2px solid #272F34' : 'none',
    textAlign: 'left',
    cursor: 'pointer',
    alignItems: 'center',
}))

const PrimaryLink = styled.span({
    color: '#E1E7EC',
    fontSize: '16px',
    fontWeight: 500,
    letterSpacing: 0,
    textDecoration: 'none',
    lineHeight: '22px',
    cursor: 'pointer',
    zIndex: 20,
})

const SecondaryLink = styled(PrimaryLink)({
    fontSize: '16px',
    fontWeight: 'bold',
    marginRight: '4px',
    color: '#E1E7EC',
})

interface Props {
    children?: React.ReactNode;
    userStore?: UserStore
    title: string
}

const ChooseOrganizationDropDownComponent = (props: Props) => {
    const [isActive, setIsActive] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const navigation = useNavigation()
    const {title} = props

    const goToOrganizationSettings = (id: string) => {
        return (event: React.MouseEvent) => {
            event.preventDefault()
            event.stopPropagation()
            setIsActive(false)
            navigation.navigate("/organization/" + id + "/settings/general")
        }
    }

    const changeOrganization = (id: string) => {
        return (event: React.MouseEvent) => {
            event.preventDefault()
            event.stopPropagation()
            setIsActive(false)
            navigation.navigate("/organization/" + id + "/relyingParty")
        }
    }

    const show = (event: React.MouseEvent) => {
        event.preventDefault()
        setIsActive(true)
    }

    const close = (event: React.MouseEvent) => {
        event.preventDefault()
        if (isActive) {
            setIsActive(false)
        }
    }

    const openCreateOrganizationModal = (event: React.MouseEvent) => {
        event.preventDefault()
        setIsActive(false)
        setIsModalOpen(true)
    }

    const closeCreateOrganizationModal = () => {
        setIsActive(false)
        setIsModalOpen(false)
    }

    return (
      <DropDownContainer onClick={close}>
        {isActive ? <Background onClick={close} /> : null }
        <div style={{ display: 'inline-flex', position: 'relative' }} onClick={show}>
          <SecondaryLink>{title}</SecondaryLink>
          {isActive ? <ExpandLessIcon style={{ zIndex: 20 }} /> : <ExpandMoreIcon />}
          {isActive ? (
            <>
              <Menu>
                {props.userStore?.organizations?.map((orga) => (
                  <ChooseOrganizationDropDownItem
                    key={orga.id}
                    title={orga.name}
                    onChange={changeOrganization(orga.id)}
                    onSettings={goToOrganizationSettings(orga.id)}
                    active={orga.id === props.userStore?.user?.active_organization_id}
                  />
                ))}
                <CreateBox
                  showBorderTop={(props.userStore?.organizations?.length ?? 0) > 0}
                  onClick={openCreateOrganizationModal}
                >
                  <AddIcon
                    style={{
                      height: '20px',
                      width: '20px',
                      color: '#FFFFFF',
                      fill: '#FFFFFF',
                      marginRight: '8px',
                    }}
                  />
                  <Text>Create new organization</Text>
                </CreateBox>
              </Menu>
            </>
          ) : null}
        </div>
        {props.children}
        <AddOrganizationModal isOpen={isModalOpen} onClose={closeCreateOrganizationModal} />
      </DropDownContainer>
    );

}

export const ChooseOrganizationDropDown = inject('userStore')(observer(ChooseOrganizationDropDownComponent))
