import { observable, action } from "mobx";
import {
    RelyingPartyCreateRequest,
    RelyingPartyModel,
    RelyingPartyUpdateRequest, TenantStatus,
} from "../models/RelyingPartyModel";
import {
    GET_RELYING_PARTY_URL,
    PATCH_RELYING_PARTY_URL,
    UPLOAD_RELYING_PARTY_LOGO_URL,
    CREATE_API_PROJECT,
    DELETE_RELYING_PARTY_URL,
    GET_RELYING_PARTY_TENANT_STATUS
} from "../constants/Url";
import { handleApiResponse, ApiError, isApiError } from "../helper/ResponseHelper";
import { TrustedFacet } from "../models/TrustedFacet";
import RequestTimeout from "../helper/RequestTimeout";

export class RelyingPartyStore {
    @observable trustedFacets: TrustedFacet[] = []
    @observable relyingParty?: RelyingPartyModel = undefined
    @observable tenantStatus: TenantStatus = {available_replicas: 0, ready_replicas: 0, replicas: 0}

    @action
    update = async (orgaId: string, rpId: string, relyingPartyUpdateRequest: RelyingPartyUpdateRequest) => {
        const response = await RequestTimeout(PATCH_RELYING_PARTY_URL(orgaId, rpId), {
            method: 'PATCH',
            credentials: 'same-origin',
            cache: 'no-cache',
            mode: 'cors',
            body: JSON.stringify(relyingPartyUpdateRequest)
        })
        return handleApiResponse<RelyingPartyModel>(response).then(this.success).catch(this.error)
    }

    @action
    uploadFile = async (orgaId: string, rpId: string, file: File) => {
        const formdata = new FormData()
        formdata.append('upload', file)
        const response = await RequestTimeout(UPLOAD_RELYING_PARTY_LOGO_URL(orgaId, rpId), {
            method: 'POST',
            credentials: 'same-origin',
            cache: 'no-cache',
            mode: 'cors',
            body: formdata
        })
        return handleApiResponse<RelyingPartyModel>(response).then(this.success).catch(this.error)
    }

    @action
    create = async (orgaId: string, relyingParty: RelyingPartyCreateRequest) => {
        const response = await RequestTimeout(CREATE_API_PROJECT(orgaId), {
            method: 'POST',
            credentials: 'same-origin',
            cache: 'no-cache',
            mode: 'cors',
            body: JSON.stringify(relyingParty)
        })
        return handleApiResponse<RelyingPartyModel>(response).then(this.success).catch(this.error)
    }

    @action
    delete = async (organizationId: string, relyingPartyId: string) => {
        const response = await RequestTimeout(DELETE_RELYING_PARTY_URL(organizationId, relyingPartyId), {
            method: 'DELETE',
            credentials: 'same-origin',
            cache: 'no-cache',
            mode: 'cors',
        })
        return handleApiResponse<RelyingPartyModel>(response).then(this.success).catch(this.error)
    }

    @action
    get = async (orgaId: string, rpId: string) => {
        const response = await RequestTimeout(GET_RELYING_PARTY_URL(orgaId, rpId), {
            method: 'GET',
            credentials: 'same-origin',
            cache: 'no-cache',
            mode: 'cors',
        })

        return handleApiResponse<RelyingPartyModel>(response).then(this.success).catch(this.error)
    }

    @action.bound
    success = (result: RelyingPartyModel | ApiError) => {
        if (!isApiError(result)) {
            this.relyingParty = result
        }
        return result
    }

    @action.bound
    error = (reason: any): ApiError => {
        return {
            code: 0,
            message: 'Could not get fetch.'
        }
    }

    @action
    getTenantStatus = async (orgaId: string, rpId: string) => {
        const response = await RequestTimeout(GET_RELYING_PARTY_TENANT_STATUS(orgaId, rpId), {
            method: 'GET',
            credentials: 'same-origin'
        })

        return handleApiResponse<TenantStatus>(response).then(this.getTenantStatusSuccess).catch(this.error)
    }

    @action.bound
    getTenantStatusSuccess = (result: TenantStatus | ApiError) => {
        if (!isApiError(result)) {
            this.tenantStatus = result
        }

        return result
    }
}

export const relyingPartyStore = new RelyingPartyStore()
