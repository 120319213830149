import styled from 'styled-components'

export const SettingsTitle = styled.h1({
    fontSize: '18px',
    fontWeight: 'bold',
    letterSpacing: 0,
    color: '#FFFFFF',
    width: 'fit-content',
    margin: '0 0 25px 0',
})

export const SettingsDescription = styled.span({
    fontSize: '14px',
    letterSpacing: 0,
    color: '#FFFFFF',
    width: 'fit-content',
    textAlign: 'left'
})
