import React from 'react'
import { SettingsContainerLarge as SettingsContainerLargeComponent } from '../../atoms/Container';
import { SettingsTitle } from "../../atoms/Texts";
import styled from "styled-components";
import { Badge } from "../../atoms/SubscriptionBadge";

const SettingsContainerLarge = styled(SettingsContainerLargeComponent)({
    width: '100%'
});

export const ComingSoonBadge = styled(Badge)({
    backgroundColor: '#DEE5FF',
    color: '#272F34',
    fontWeight: 'bold'
})

export const Description = styled.span({
    fontSize: '13px',
    color: '#161D21',
    marginLeft: '7px',
    marginRight: '14px',
})

export const ComingSoonBannerBackground = styled.div({
    backgroundColor: '#A5B3E1',
    borderRadius: '49px',
    height: 'fit-content',
    width: 'fit-content',
    padding: '5px 15px 5px 5px',
    display: 'flex',
    alignItems: "center",
})

export const IdentityEmailSettings = () => {
    return (
        <SettingsContainerLarge>
            <SettingsTitle>Email template</SettingsTitle>
            <ComingSoonBannerBackground>
                <ComingSoonBadge>Coming soon</ComingSoonBadge>
                <Description>Edit your email templates.</Description>
            </ComingSoonBannerBackground>
        </SettingsContainerLarge>
    )
}