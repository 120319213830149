import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigation } from 'react-navi';
import { PrimaryButton } from '../../../atoms/Button';
import { ColumnLeft, ColumnRight, SettingsContainer } from '../../../atoms/Container';
import { Color } from '../../../atoms/Color';
import { CopyableText } from '../../../atoms/CopyableText';
import { OidcClientStore } from '../../../../store/OidcClientStore';
import { inject, observer } from 'mobx-react';
import { isApiError } from '../../../../helper/ResponseHelper';
import { toast } from 'react-toastify';
import { AddNewCredentialModal } from './AddNewCredentialModal';
import { SettingsDescriptionLink } from '../../../atoms/Link';
import { ComingSoonBadge, ComingSoonBannerBackground, Description } from '../IdentityEmailSettings';
import { ReactComponent as UserManagementPreview } from '../../../../assets/user_management_preview.svg';
import { ReactComponent as LoginIconSvg } from "../../../../assets/login.svg";
import { IdentityStore } from "../../../../store/IdentityProjectStore";

type Props = {
  organizationId: string;
  relyingPartyId: string;
  oidcClientStore?: OidcClientStore;
  identityStore?: IdentityStore;
};

const Container = styled(SettingsContainer)`
  width: calc(100% - 60px);
  padding: 35px 30px;
  color: #ffffff;
  text-align: left;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const RightColumn = styled.div`
  width: 160px;
`;

const Title = styled.h2`
  margin: 0 0 25px 0;
  font-size: 18px;
  font-weight: 700;
`;

const Text = styled.p`
  max-width: 495px;
  margin-bottom: 30px;
  font-size: 14px;
`;

const AppsContainer = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const AppDetails = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 18px;
  background-color: #545b61;
  border-radius: 5px;

  &:not(:last-child) {
    margin-bottom: 25px;
  }

  &:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }
`;

const AppName = styled.span`
  font-size: 16px;
  font-weight: 600;
`;

const DetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    cursor: auto;
  }
`;

const ClientDetailsText = styled.span`
  margin-right: 20px;
  font-size: 14px;
  font-weight: 500;
`;

const AccessTenantButton = styled.a`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: fit-content;
  padding: 12px 20px;
  background-color: #ff2e4c;
  border-radius: 3px;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:first-child {
    margin-bottom: 25px;
  }
`;

const ButtonText = styled.span({
  marginLeft: '18px'
})

const ColumnTopLeft = styled(ColumnLeft)({
  padding: 0,
  width: '60%',
});

const ColumnTopRight = styled(ColumnRight)({
  display: 'flex',
  justifyContent: 'end',
  padding: 0,
  width: '40%',
});

const IdentityUserManagementCredentialsComponent = ({ organizationId, relyingPartyId, oidcClientStore, identityStore }: Props) => {
  const navigation = useNavigation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchAllApps = () => {
    oidcClientStore
      ?.list(organizationId, relyingPartyId)
      .then((result) => {
        if (isApiError(result)) {
          toast.error('Error getting Apps');
        }
      })
      .catch((error) => console.error(error));
  };

  const getTenantUrl = (): string => {
    if (identityStore?.relyingParty?.domain?.verified === true) {
      return 'https://' + identityStore?.relyingParty.domain.domain;
    }
    let env = '';
    if (window.location.origin.includes('.dev.')) {
      env = '.dev';
    } else if (window.location.origin.includes('.stg.')) {
      env = '.stg';
    }

    return 'https://' + identityStore?.relyingParty?.id + '.identity' + env + '.hanko.io';
  };

  useEffect(() => {
    fetchAllApps();
  }, []);

  const goToAppDetails = (appId: string) =>
    navigation.navigate(`${navigation.getCurrentValue().url.pathname}/${appId}`);

  return (
    <>
      <Container>
        <ColumnWrapper>
          <ColumnTopLeft>
            <Title>User Management</Title>
            <Text>
              The User Management UI gives you control over your user base. Invite new users, see their activity or give
              support in case of lost access. Only your account can access the UI for now, multi-user support coming soon
            </Text>
            <AccessTenantButton href={`${getTenantUrl()}/manage`} target="_blank">
              <LoginIconSvg fill="#ffffff" />
              <ButtonText>Open User Management</ButtonText>
            </AccessTenantButton>
          </ColumnTopLeft>
          <ColumnTopRight>
            <UserManagementPreview style={{ boxShadow: '2.51333px 4.18888px 12.5667px rgba(0, 0, 0, 0.15)', width: '305px' ,height: '205px' }} />
          </ColumnTopRight>
        </ColumnWrapper>
      </Container>
      <Container>
        <ColumnWrapper>
          <div>
            <Title>User Management API credentials</Title>
            <Text>
              The User Management API can be accessed by creating API credentials. Please refer to the{' '}
              <SettingsDescriptionLink href="https://docs.hanko.io/identity/um#admin" target="_blank">
                docs
              </SettingsDescriptionLink>{' '}
              on how to use the API.
            </Text>
          </div>
          <RightColumn>
            <PrimaryButton type="submit" onClick={() => setIsModalOpen(true)}>
              Create new
            </PrimaryButton>
          </RightColumn>
        </ColumnWrapper>
        <AppsContainer>
          {oidcClientStore?.clients
            ?.filter((client) => client.metadata?.app_type === 'credential')
            ?.map((client, index) => (
              <AppDetails key={`${client.client_id}-${index}`} onClick={() => goToAppDetails(client.client_id ?? '')}>
                <AppName>{client.client_name}</AppName>
                <DetailsWrapper onClick={(e) => e.stopPropagation()}>
                  <ClientDetailsText>Client ID:</ClientDetailsText>
                  <CopyableText backgroundColor={Color.darkBlue} style={{ width: '330px', height: '35px' }}>
                    {client.client_id || ''}
                  </CopyableText>
                </DetailsWrapper>
              </AppDetails>
            ))}
        </AppsContainer>
      </Container>
      <AddNewCredentialModal
        relyingPartyId={relyingPartyId}
        organizationId={organizationId}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export const IdentityUserManagementCredentials = inject('oidcClientStore', 'identityStore')(
  observer(IdentityUserManagementCredentialsComponent)
);
