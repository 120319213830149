import React, { useState } from 'react'
import styled from "styled-components";
import {
    ColumnLeft,
    ColumnRight,
    SettingsContainerLarge as SettingsContainerLargeComponent
} from "../../../atoms/Container";
import { SettingsDescription, SettingsTitle } from "../../../atoms/Texts";
import { PrimaryButton } from "../../../atoms/Button";
import { WebauthnOriginTableItem } from "./WebauthnOriginTableItem";
import { AddMobileOriginModal } from "./AddMobileOriginModal";
import { DeleteMobileOriginModal } from "./DeleteMobileOriginModal";
import { inject, observer } from "mobx-react";
import { RelyingPartyStore } from "../../../../store/RelyingPartyStore";
import { SettingsDescriptionLink } from "../../../atoms/Link";
import LoadingWheel from '../../../atoms/LoadingWheel';

const SettingsContainerLarge = styled(SettingsContainerLargeComponent)({
    width: '100%'
});

const Left = styled(ColumnLeft)({
    width: '70%'
})

const Right = styled(ColumnRight)({
    width: '30%'
})

const AddNewButton = styled(PrimaryButton)({
    width: "160px",
    fontWeight: 500
})

const RedirectUrlTable = styled.table({
    width: "100%",
    maxWidth: '100%',
    margin: '30px 0 20px 0',
    borderCollapse: 'collapse',
    tableLayout: 'fixed'
})

interface Props {
    relyingPartyStore?: RelyingPartyStore
}

const WebauthnMultipleOriginsSettingsComponent = (props: Props) => {
    const [isAddModalOpen, setAddModalOpen] = useState(false)
    const [isRemoveModalOpen, setRemoveModalOpen] = useState(false)
    const [selectedOrigin, setSelectedOrigin] = useState('')

    return <SettingsContainerLarge>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div style={{marginRight: '150px'}}>
                <SettingsTitle>
                    Additional mobile origins
                </SettingsTitle>
                <p>
                    <SettingsDescription>
                        To enable your Android mobile app to share the WebAuthn credentials with your web app, you need
                        to enter the app signing key's SHA256 fingerprint below. This applies only to Android. Please
                        <SettingsDescriptionLink
                            href="mailto:admin@hanko.io"> contact us</SettingsDescriptionLink> if you need support
                        regarding this feature.
                    </SettingsDescription>
                </p>
            </div>
            <div>
                <AddNewButton onClick={() => setAddModalOpen(true)}>Add new</AddNewButton>
            </div>
        </div>
        {
            props.relyingPartyStore?.relyingParty?.origins ? (
                <RedirectUrlTable>
                    <tbody>
                    {
                        (props.relyingPartyStore?.relyingParty?.origins).map(value => {
                            return <WebauthnOriginTableItem item={value} openRemoveModal={() => {
                                setSelectedOrigin(value)
                                setRemoveModalOpen(true)
                            }}/>
                        })
                    }
                    </tbody>
                </RedirectUrlTable>
            ) : null
        }

        <AddMobileOriginModal isOpen={isAddModalOpen} onClose={() => setAddModalOpen(false)}/>
        <DeleteMobileOriginModal isOpen={isRemoveModalOpen} onClose={() => {
            setRemoveModalOpen(false)
            setSelectedOrigin('')
        }} origin={selectedOrigin}/>
    </SettingsContainerLarge>
}

export const WebauthnMultipleOriginsSettings = inject('relyingPartyStore')(observer(WebauthnMultipleOriginsSettingsComponent))
