import React from 'react';
import styled from 'styled-components'
import { Color } from './Color';
import LoadingWheel from './LoadingWheel';

const PrimaryButtonContainer = styled.button({
    cursor: 'pointer',
    height: '50px',
    color: 'white',
    backgroundColor: Color.primaryRed,
    borderRadius: '2px',
    borderColor: Color.primaryRed,
    boxShadow: '0 4px 16px -8px rgba(117, 33, 29, 0.8)',
    borderStyle: 'none',
    fontWeight: 500,
    fontSize: '16px',
    letterSpacing: '0.18px',
    ":hover": {
        backgroundColor: Color.lightRed,
        borderColor: Color.lightRed
    },
    ":active": {
        backgroundColor: Color.intenseRed,
        borderColor: Color.intenseRed
    },
    ":disabled": {
        backgroundColor: 'rgba(255,255,255,0.12)',
        borderColor: 'rgba(255,255,255,0.12)',
        color: 'rgba(255,255,255,0.38)',
        cursor: 'not-allowed',
        boxShadow: 'none'
    },
    width: '100%'
});

const LoadingWheelContainer = styled.div({
    position: 'relative',
    width: '100%',
    height: '100%'
});

export const PrimaryButton = (props: any) => (
    <PrimaryButtonContainer {...props} onClick={!props.isLoading ? props.onClick : () => {}}>
        { props.isLoading ? (
            <LoadingWheelContainer>
                <LoadingWheel color="#ffffff" />
            </LoadingWheelContainer>
        ) : props.children }
    </PrimaryButtonContainer>
);

export const SecondaryButton = styled(PrimaryButton)({
    color: '#272F34',
    backgroundColor: '#BBC4CC',
    borderColor: '#BBC4CC',
    boxShadow: 'none',
    ":hover": {
        backgroundColor: '#C6D0D9',
        borderColor: '#C6D0D9'
    },
    ":active": {
        backgroundColor: '#E1E7EC',
        borderColor: '#E1E7EC'
    },
    ":disabled": {
        backgroundColor: 'rgba(255,255,255,0.12)',
        borderColor: 'rgba(255,255,255,0.12)',
        color: 'rgba(255,255,255,0.38)',
    },
    width: '100%'
});

export const NoStylingButton = styled.button({
    border: 'none',
    background: 'none',
    color: 'none',
    cursor: 'pointer',
    font: 'inherit'
})
