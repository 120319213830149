import React, { useEffect, useState } from 'react';
import { useNavigation } from 'react-navi';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { NoStylingButton, PrimaryButton } from '../atoms/Button';
import { SettingsContainer } from '../atoms/Container';
import { Color } from '../atoms/Color';
import { ReactComponent as ArrowIcon } from '../../assets/arrow_left_thin.svg';
import { FormList, FormListItem } from '../atoms/List';
import AlertBox from '../atoms/AlertBox';
import { Input } from '../atoms/Input';
import LoadingWheel from '../atoms/LoadingWheel';
import { inject, observer } from 'mobx-react';
import { OidcClientStore } from '../../store/OidcClientStore';
import { isApiError } from '../../helper/ResponseHelper';
import { CustomDropdown } from '../atoms/CustomDropdown';
import { ReactComponent as ArrowUpIcon } from '../../assets/arrow_up_thin.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/arrow_down_thin.svg';
import { ReactComponent as DownChevronIcon } from '../../assets/chevron_icon.svg';
import { ReactComponent as UpChevronIcon } from '../../assets/up_chevron_icon.svg';
import { AddNewAppModal } from './AddNewAppModal';
import IncrementalTextbox from '../atoms/IncrementalTextbox';
import { DeleteAppConfirmationModal } from './DeleteAppConfirmationModal';
import { CopyableText } from '../atoms/CopyableText';

type Props = {
  appId: string;
  organizationId: string;
  relyingPartyId: string;
  oidcClientStore?: OidcClientStore;
};

const Container = styled(SettingsContainer)`
  width: 100%;
  margin-bottom: 25px;
  padding: 35px 30px;
  box-sizing: border-box;
  color: #ffffff;
  text-align: left;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const BackButtonContainer = styled(NoStylingButton)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90px;
  padding: 7px 22px 8px 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
  background: ${Color.darkBlue};
  border-radius: 20px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
`;

const LeftArrowIcon = styled(ArrowIcon)``;

const ContainerTitle = styled.h2`
  margin: 0 0 20px;
  font-size: 18px;
  font-weight: 700;
`;

const LeftColumn = styled.div`
  min-width: 315px;
  max-width: 315px;
  margin-right: 25px;
  font-size: 14px;
  line-height: 22px;
`;

const Textbox = styled(Input)`
  width: 100%;
`;

const SubmitButton = styled(PrimaryButton)`
  display: block;
  max-width: 160px;
  margin-left: auto;
`;

const RightColumn = styled.div`
  width: 100%;
`;

const List = styled(FormList)`
  margin: 0;

  & > {
    padding: 0;
  }
`;

const ClientIdWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const ClientIdLabel = styled.span`
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
`;

const NewSecretButton = styled(NoStylingButton)`
  margin-top: 15px;
  padding-left: 0;
  color: #bbc4cc;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
`;

const InputFootnote = styled.aside`
  margin-top: 10px;
  color: #bbc4cc;
  font-size: 14px;
  line-height: 22px;
`;

const SectionTitle = styled.h2`
  margin: 0 0 25px 0;
  font-size: 18px;
  font-weight: 700;
`;

const SectionText = styled.p`
  max-width: 495px;
  margin-bottom: 30px;
  font-size: 14px;
`;

const OpenIDTitle = styled(SectionTitle)`
  margin-bottom: 10px;
`;
const OpenIDText = styled(SectionText)`
  max-width: 100%;
  margin-bottom: 40px;
`;

const EndpointWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 35px;
`;

const EndpointLabel = styled.span`
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
`;

const AccordionButton = styled(NoStylingButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: 10px;
  margin-left: auto;
  color: #ffffff;
  font-size: 14px;
`;

const ArrowDownSvg = styled(ArrowDownIcon)`
  margin-left: 10px;
`;

const ArrowUpSvg = styled(ArrowUpIcon)`
  margin-left: 10px;
`;

const DeleteAppContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px;
  border: 2px solid ${Color.primaryRed};
`;

const DeleteAppButton = styled(PrimaryButton)`
  position: relative;
  max-width: 160px;
`;

const DeleteAppTitle = styled(ContainerTitle)`
  margin: 0;
`;

const LoadingWheelStyled = styled(LoadingWheel)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const TagWrapper = styled.div`
  margin-bottom: 20px;
`;

const TagsLabel = styled.div`
  margin-bottom: 10px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
`;

const Tags = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const Tag = styled.span`
  margin-right: 7px;
  margin-bottom: 10px;
  padding: 2px 8px;
  background: #272f34;
  border-radius: 3px;
  color: #bbc4cc;
  font-family: 'IBM Plex Mono';
  font-size: 14px;
  font-weight: 400;
`;

const OIDCTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ExpandSectionButton = styled(NoStylingButton)`
  width: 30px;
  height: 30px;
`;

const RedLink = styled.a`
  color: #ff2e4c;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

type AppDetails = {
  appId: string;
  appUrl: string;
  name: string;
  clientUri: string;
  appType: string;
};

const RelyingPartyAppDetailsComponent = ({ appId, organizationId, relyingPartyId, oidcClientStore }: Props) => {
  const navigation = useNavigation();
  const [appDetails, setAppDetails] = useState<AppDetails>();
  const [appInfoError, setAppInfoError] = useState<string>();
  const [newAppName, setNewAppName] = useState<string>();
  const [newAppNameError, setNewAppNameError] = useState<string>();
  const [appType, setAppType] = useState<string>();
  // const [newAppTypeError, setAppTypeError] = useState<string>();
  const [newAppUrl, setNewAppUrl] = useState<string>();
  const [newUrlError, setNewAppUrlError] = useState<string>();
  const [isAppInfoLoading, setIsAppInfoLoading] = useState(false);
  const [isSaveOidcSettingsLoading, setIsSaveOidcSettingsLoading] = useState(false);
  const [loginRedirectUris, setLoginRedirectUris] = useState<string[]>();
  const [loginRedirectError, setLoginRedirectError] = useState<string>();
  const [logoutUrls, setLogoutUrls] = useState<string[]>();
  const [tokenEndpointAuth, setTokenEndpointAuth] = useState<string>();
  const [newScopes, setNewScopes] = useState<string>();
  const [newGrantTypes, setNewGrantTypes] = useState<string[]>();
  const [isAccordionSectionVisible, setIsAccordionSectionVisible] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isOIDCSectionVisible, setIsOIDCSectionVisible] = useState(false);
  const [isDeleteAppConfirmationModalOpen, setIsDeleteAppConfirmationModalOpen] = useState(false);
  const [endpoints, setEndpoints] = useState({
    authorization: '',
    token: '',
    userInfo: '',
    revocation: '',
    logout: '',
    issuer: '',
  });
  const refreshTokensOptions = [
    {
      label: 'Enable',
      value: 'enable',
    },
    {
      label: 'Disable',
      value: 'disable',
    },
  ];
  const tokenEndpointAuthenticationMethod = [
    {
      label: 'client_secret_basic',
      value: 'client_secret_basic',
    },
    {
      label: 'client_secret_post',
      value: 'client_secret_post',
    },
  ];

  const handleGoBack = () => {
    const appsPath = navigation.getCurrentValue().url.pathname.split('/apps/')[0];

    navigation.navigate(`${appsPath}/apps`);
  };

  const handleSaveAppInfo = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsAppInfoLoading(true);
    const result = await oidcClientStore?.update(organizationId, relyingPartyId, appId, {
      client_uri: newAppUrl,
      client_name: newAppName,
    });
    setIsAppInfoLoading(false);
    if (isApiError(result)) {
      setLoginRedirectError('App Info could not be updated');
    } else {
      toast.success('App configuration saved!');
    }
  };

  const handleSaveOidcSettings = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsAppInfoLoading(true);
    setIsSaveOidcSettingsLoading(true);
    const removeArrayEmptyStrings = (arr: string[]) => arr.filter((string) => string !== '');

    const redirectUris = loginRedirectUris ? removeArrayEmptyStrings(loginRedirectUris) : [];
    const postLogoutRedirectUrls = logoutUrls ? removeArrayEmptyStrings(logoutUrls) : [];

    postLogoutRedirectUrls.map((logoutUrl) => {
      if (redirectUris.find((redirectUri) => redirectUri === logoutUrl) === undefined) {
        redirectUris.push(logoutUrl);
      }
    });

    const result = await oidcClientStore?.update(organizationId, relyingPartyId, appId, {
      redirect_uris: redirectUris,
      post_logout_redirect_uris: postLogoutRedirectUrls,
      token_endpoint_auth_method: tokenEndpointAuth,
      scope: newScopes,
      grant_types: newGrantTypes,
    });
    setIsAppInfoLoading(false);
    if (isApiError(result)) {
      toast.error('OIDC client information not updated');
    } else {
      setLoginRedirectUris(result?.redirect_uris ?? []);
      setLogoutUrls(result?.post_logout_redirect_uris ?? []);
      toast.success('OIDC client settings saved!');
    }
    setIsSaveOidcSettingsLoading(false);
  };

  const isAppConfigChanged = () => {
    return newAppName !== oidcClientStore?.client?.client_name || newAppUrl !== oidcClientStore?.client?.client_uri;
  };

  const areEqual = (a: any[], b: any[]) => {
    if (a.length != b.length) {
      return false;
    }
    return (
      a.filter(function (i) {
        return !b.includes(i);
      }).length === 0
    );
  };

  const isOidcSettingsChanged = () => {
    return (
      newScopes !== oidcClientStore?.client?.scope ||
      tokenEndpointAuth !== oidcClientStore?.client?.token_endpoint_auth_method ||
      !areEqual(loginRedirectUris ?? [], oidcClientStore?.client?.redirect_uris ?? []) ||
      !areEqual(logoutUrls ?? [], oidcClientStore?.client?.post_logout_redirect_uris ?? [])
    );
  };

  const handleDeleteApp = async () => {
    setIsAppInfoLoading(true);
    const result = await oidcClientStore?.delete(organizationId, relyingPartyId, appId);
    setIsAppInfoLoading(false);
    if (!isApiError(result)) {
      handleGoBack();
    } else {
      setAppInfoError('Could not delete OIDC client');
    }
  };

  const handleGenerateNewSecret = () => {
    setIsSuccessModalOpen(true);
  };

  const handleRefreshTokensChange = (val: string) => {
    setNewScopes(val === 'enable' ? 'openid profile email offline_access' : 'openid profile email');
    setNewGrantTypes(val === 'enable' ? ['authorization_code', 'refresh_token'] : ['authorization_code']);
  };

  useEffect(() => {
    oidcClientStore
      ?.get(organizationId, relyingPartyId, appId)
      .then((result) => {
        if (!isApiError(result)) {
          setLoginRedirectUris(result.redirect_uris || ['']);
          setLogoutUrls(result.post_logout_redirect_uris);
          setTokenEndpointAuth(result.token_endpoint_auth_method);
          setAppType(result?.metadata?.app_type);
          setAppDetails({
            appUrl: '',
            appId: '',
            name: '',
            clientUri: '',
            appType: '',
          });
          setNewScopes(result?.scope);
          setNewGrantTypes(result?.grant_types);
          setNewAppName(result?.client_name);
          setNewAppUrl(result?.client_uri);
        } else {
          console.error(result.message);
          // handleGoBack()
        }
      })
      .catch((error) => {
        console.error(error);
        handleGoBack();
      });
  }, []);

  useEffect(() => {
    oidcClientStore
      ?.getOidcConfig(organizationId, relyingPartyId)
      .then((result) => {
        if (!isApiError(result)) {
          setEndpoints({
            authorization: result.authorization_endpoint,
            token: result.token_endpoint,
            userInfo: result.userinfo_endpoint,
            revocation: result.revocation_endpoint,
            logout: result.end_session_endpoint,
            issuer: result.issuer,
          });
        } else {
          console.error(result.message);
          // handleGoBack()
        }
      })
      .catch((error) => {
        console.error(error);
        handleGoBack();
      });
  }, []);

  useEffect(() => {
    isAppConfigChanged();
    isOidcSettingsChanged();
  }, [
    oidcClientStore?.client?.client_name,
    oidcClientStore?.client?.client_uri,
    oidcClientStore?.client?.metadata?.app_type,
    oidcClientStore?.client?.scope,
    oidcClientStore?.client?.token_endpoint_auth_method,
    oidcClientStore?.client?.redirect_uris,
    oidcClientStore?.client?.post_logout_redirect_uris,
  ]);

  return (
    <>
      <BackButtonContainer onClick={handleGoBack}>
        <LeftArrowIcon fill="#ffffff" />
        Back
      </BackButtonContainer>
      <Container>
        <ContainerTitle>App configuration</ContainerTitle>
        <FlexContainer>
          <LeftColumn>
            Basic settings of your application. See below for OpenID Connect server endpoints and client redirect URLs.
            <br />
            <br />
            Please see the{' '}
            <RedLink href="https://docs.hanko.io/identity/configuration" target="_blank">
              docs
            </RedLink>{' '}
            for detailed instructions.
          </LeftColumn>
          <RightColumn>
            <List style={{ position: 'relative', padding: 0 }}>
              {!!appInfoError ? (
                <FormListItem>
                  <AlertBox type="error">{appInfoError}</AlertBox>
                </FormListItem>
              ) : null}

              <FormListItem>
                <Textbox
                  label="Application name"
                  name="newAppName"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewAppName(e.target.value)}
                  error={newAppNameError}
                  placeholder="Name"
                  value={newAppName || oidcClientStore?.client?.client_name}
                />
              </FormListItem>
              <FormListItem>
                <Textbox
                  label={appType === 'web' ? 'Application URL' : 'Package name'}
                  name="appUrl"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewAppUrl(e.target.value)}
                  error={newUrlError}
                  placeholder="https://app.yourdomain.com"
                  defaultValue={newAppUrl || oidcClientStore?.client?.client_uri}
                />
              </FormListItem>
              <FormListItem>
                <ClientIdWrapper>
                  <ClientIdLabel>Client ID</ClientIdLabel>
                  <CopyableText>{oidcClientStore?.client?.client_id || ''}</CopyableText>
                </ClientIdWrapper>
                {appType === 'web' ? (
                  <NewSecretButton onClick={handleGenerateNewSecret}>Generate new secret</NewSecretButton>
                ) : null}
              </FormListItem>
              <FormListItem>
                <SubmitButton
                  type="submit"
                  onClick={handleSaveAppInfo}
                  disabled={!isAppConfigChanged()}
                  isLoading={isAppInfoLoading}
                >
                  Save changes
                </SubmitButton>
              </FormListItem>
            </List>
          </RightColumn>
        </FlexContainer>
      </Container>
      <Container>
        <OpenIDTitle>OpenID Connect server endpoints</OpenIDTitle>
        <OpenIDText>
          Use the OpenID Connect (OIDC) Discovery URL to automatically obtain the server endpoints. If your client
          doesn't support auto discovery, use “Show all endpoints” for manual configuration.
        </OpenIDText>
        <EndpointWrapper>
          <EndpointLabel>OpenID Connect Discovery URL</EndpointLabel>
          <CopyableText>{`${endpoints.issuer}.well-known/openid-configuration`}</CopyableText>
        </EndpointWrapper>
        <AccordionButton onClick={() => setIsAccordionSectionVisible(!isAccordionSectionVisible)}>
          Show all endpoints
          {isAccordionSectionVisible ? <ArrowUpSvg fill="#EFF3F5" /> : <ArrowDownSvg fill="#EFF3F5" />}
        </AccordionButton>
        {isAccordionSectionVisible ? (
          <div>
            <EndpointWrapper>
              <EndpointLabel>Authorization endpoint</EndpointLabel>
              <CopyableText>{endpoints.authorization}</CopyableText>
            </EndpointWrapper>
            <EndpointWrapper>
              <EndpointLabel>Token endpoint</EndpointLabel>
              <CopyableText>{endpoints.token}</CopyableText>
            </EndpointWrapper>
            <EndpointWrapper>
              <EndpointLabel>UserInfo endpoint</EndpointLabel>
              <CopyableText>{endpoints.userInfo}</CopyableText>
            </EndpointWrapper>
            <EndpointWrapper>
              <EndpointLabel>Revocation endpoint</EndpointLabel>
              <CopyableText>{endpoints.revocation}</CopyableText>
            </EndpointWrapper>
            <EndpointWrapper>
              <EndpointLabel>Logout / end session endpoint</EndpointLabel>
              <CopyableText>{endpoints.logout}</CopyableText>
            </EndpointWrapper>
          </div>
        ) : null}
      </Container>
      <Container>
        <>
          <OIDCTitleWrapper>
            <ContainerTitle style={{ margin: 0 }}>OpenID Connect client settings</ContainerTitle>
            <ExpandSectionButton onClick={() => setIsOIDCSectionVisible(!isOIDCSectionVisible)}>
              {isOIDCSectionVisible ? <UpChevronIcon fill="#ffffff" /> : <DownChevronIcon fill="#ffffff" />}
            </ExpandSectionButton>
          </OIDCTitleWrapper>
          {isOIDCSectionVisible ? (
            <FlexContainer>
              <LeftColumn>
                In this section you will find all settings you need to configure your OpenID Connect client to work with
                Hanko Identity.
              </LeftColumn>
              <RightColumn>
                <>
                  <List style={{ position: 'relative', padding: 0 }}>
                    {!!appInfoError ? (
                      <FormListItem>
                        <AlertBox type="error">{appInfoError}</AlertBox>
                      </FormListItem>
                    ) : null}

                    <FormListItem>
                      <IncrementalTextbox
                        label="Redirect / callback URLs"
                        name="loginRedirect"
                        onChange={(values) => setLoginRedirectUris(values)}
                        placeholder="https://app.yourdomain.com"
                        defaultValues={loginRedirectUris}
                        style={{ marginTop: '35px' }}
                      />
                      <InputFootnote>
                        Whitelist of all URLs where Hanko Identity will redirect a user. Must include the redirect
                        target after login, where the authentication code exchange is handled. Logout redirect URLs will
                        be amended automatically when you enter them below.
                      </InputFootnote>
                    </FormListItem>
                    <FormListItem>
                      <IncrementalTextbox
                        label="Logout redirect URLs (optional)"
                        name="logoutUrls"
                        onChange={(values) => setLogoutUrls(values)}
                        placeholder={
                          appType === 'web' ? 'https://app.yourdomain.com/logout' : 'com.yourdomain.app://logout'
                        }
                        defaultValues={logoutUrls}
                        style={{ marginTop: '65px' }}
                      />
                      <InputFootnote>Where the user is redirected to after logout</InputFootnote>
                    </FormListItem>
                    <FormListItem>
                      <CustomDropdown
                        label="Refresh tokens"
                        name="refreshTokens"
                        onChange={(option) => handleRefreshTokensChange(option.value)}
                        options={refreshTokensOptions}
                        style={{ marginTop: '35px' }}
                        value={
                          newGrantTypes === undefined
                            ? undefined
                            : (newGrantTypes?.find((value) => value === 'refresh_token')?.length ?? 0) > 0
                            ? refreshTokensOptions[0]
                            : refreshTokensOptions[1]
                        }
                      />
                      <InputFootnote>Refresh token support for this OIDC client</InputFootnote>
                    </FormListItem>
                    {appType === 'web' ? (
                      <FormListItem>
                        <CustomDropdown
                          label="Token endpoint authentication method"
                          name="tokenEndpointAuth"
                          onChange={(option) => setTokenEndpointAuth(option.value)}
                          options={tokenEndpointAuthenticationMethod}
                          style={{ marginTop: '35px' }}
                          value={
                            tokenEndpointAuth === undefined
                              ? undefined
                              : tokenEndpointAuthenticationMethod.find((method) => method.value === tokenEndpointAuth)
                          }
                        />
                        <InputFootnote>
                          Defines whether the client credentials have to be included in the HTTP Authorization header
                          (basic) or in the request body (post).
                        </InputFootnote>
                      </FormListItem>
                    ) : null}
                    <FormListItem>
                      <SubmitButton
                        type="submit"
                        onClick={handleSaveOidcSettings}
                        style={{ marginTop: '55px', marginBottom: '60px' }}
                        disabled={!isOidcSettingsChanged()}
                        isLoading={isSaveOidcSettingsLoading}
                      >
                        Save changes
                      </SubmitButton>
                    </FormListItem>
                  </List>
                  <TagWrapper>
                    <TagsLabel>Response type</TagsLabel>
                    <Tags>
                      {oidcClientStore?.client?.response_types
                        ? oidcClientStore?.client?.response_types.map((responseTypes, index) => (
                            <Tag key={index}>{responseTypes}</Tag>
                          ))
                        : null}
                    </Tags>
                  </TagWrapper>
                  <TagWrapper>
                    <TagsLabel>Scopes</TagsLabel>
                    <Tags>
                      {newScopes ? newScopes.split(' ').map((scope, index) => <Tag key={index}>{scope}</Tag>) : null}
                    </Tags>
                  </TagWrapper>
                  <TagWrapper>
                    <TagsLabel>Supported grant types</TagsLabel>
                    <Tags>
                      {newGrantTypes
                        ? newGrantTypes.map((responseTypes, index) => <Tag key={index}>{responseTypes}</Tag>)
                        : null}
                    </Tags>
                  </TagWrapper>
                </>
              </RightColumn>
            </FlexContainer>
          ) : null}
        </>
      </Container>
      <DeleteAppContainer>
        <DeleteAppTitle>Delete app</DeleteAppTitle>
        <DeleteAppButton
          type="button"
          onClick={() => setIsDeleteAppConfirmationModalOpen(true)}
          disabled={isAppInfoLoading}
        >
          {isAppInfoLoading ? <LoadingWheelStyled /> : 'Delete app'}
        </DeleteAppButton>
      </DeleteAppContainer>
      <AddNewAppModal
        isOpen={isSuccessModalOpen}
        onClose={() => setIsSuccessModalOpen(false)}
        onNewAppSuccess={() => {}}
        appDetails={appDetails}
        organizationId={organizationId}
        updateSecretId={oidcClientStore?.client?.client_id}
        relyingPartyId={relyingPartyId}
      />
      <DeleteAppConfirmationModal
        isOpen={isDeleteAppConfirmationModalOpen}
        onClose={() => setIsDeleteAppConfirmationModalOpen(false)}
        onSubmit={handleDeleteApp}
      />
    </>
  );
};

export const RelyingPartyAppDetails = inject('oidcClientStore')(observer(RelyingPartyAppDetailsComponent));
