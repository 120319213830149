import React, { CSSProperties } from "react";
import { Link } from "react-navi";
import { Color } from "./Color";

interface Props {
    href: string
    highlight: boolean
    children: React.ReactNode
    onClick?: () => void
}

export const MenuLink = (props: Props) => {
    const style: CSSProperties = {
        textDecoration: 'none',
        color: props.highlight ? '#FF2E4C' : '#FFFFFF',
        fontSize: '16px',
        fontWeight: props.highlight ? 800 : 400,
        letterSpacing: '0.18px',
        textAlign: 'left'
    }

    return <Link prefetch={false} style={style} href={props.href} onClick={props.onClick}>{props.children}</Link>
}