import styled from 'styled-components'
import { Color } from "./Color";

export const SettingsContainer = styled.div({
    padding: '35px 30px',
    borderRadius: '5px',
    backgroundColor: Color.darkBlue,
    width: '360px',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'left',
    height: 'fit-content',
    marginBottom: '25px'
})

export const SettingsContainerLarge = styled.div({
    padding: '35px 30px',
    borderRadius: '5px',
    backgroundColor: Color.darkBlue,
    width: '815px',
    display: 'inline-block',
    boxSizing: 'border-box',
    flexDirection: 'column',
    alignContent: 'left',
    textAlign: "left",
    height: 'fit-content',
    color: "#FFF",
})

export const ContainerSection = styled.div({
    width: '100%',
    marginBottom: '30px',
    display: 'inline-block',
    ":last-child": {
        marginBottom: 0
    }
})

export const ColumnLeft = styled.div({
    width: "50%",
    float: "left",
    paddingRight: "14px",
    boxSizing: "border-box",
})

export const ColumnRight = styled.div({
    width: "50%",
    float: "right",
    boxSizing: "border-box",
    paddingLeft: "14px",
})

export const CheckBoxColumnRight = styled(ColumnRight)({
    inlineSize: "fit-content",
})