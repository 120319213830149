import React from 'react';
import { Link } from 'react-navi';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: block;
  color: #ffffff;
`;

const Title = styled.h2`
  margin: 0 0 25px 0;
  font-size: 24px;
  font-weight: 700;
`;

const Text = styled.span`
  max-width: 495px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 25px;
`;

const GenericErrorPage = () => {
  return (
    <Wrapper>
      <Title>An error has occurred</Title>
      <Text>
        An unexpected error ocurred, <Link prefetch={false} href={`/organization`}>click here</Link> to go back to the project overview.
      </Text>
    </Wrapper>
  );
};

export default GenericErrorPage;
