import React, { useState, ChangeEvent, useEffect } from 'react';
import styled from 'styled-components';
import { Input } from '../../atoms/Input';
import { NoStylingButton, PrimaryButton } from '../../atoms/Button';
import { FormList, FormListItem } from '../../atoms/List';
import LoadingWheel from '../../atoms/LoadingWheel';
import AlertBox from '../../atoms/AlertBox';
import { CustomDropdown } from '../../atoms/CustomDropdown';

const Wrapper = styled.div`
  width: 495px;
`;

const Title = styled.h2`
  margin: 0 0 45px 0;
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const Textbox = styled(Input)`
  margin-bottom: 35px;
`;

const SmallText = styled.small`
  display: block;
  margin-top: 10px;
  color: #bbc4cc;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;

const CancelButton = styled(NoStylingButton)`
  margin-right: 30px;
  background: none;
  border: none;
  color: #e1e7ec;
  font-weight: 600;
`;

const SubmitButton = styled(PrimaryButton)`
  max-width: 215px;
`;

type Props = {
  error?: string;
  isLoading: boolean;
  onCreateApp: ({
    appName,
    appType,
    appUrl,
    redirectUri,
  }: {
    appName: string;
    appType: string;
    appUrl: string;
    redirectUri: string[];
  }) => void;
  onModalClose: () => void;
};

const NewAppForm = ({ error, isLoading, onCreateApp, onModalClose }: Props) => {
  const [appName, setAppName] = useState('');
  const [appUrl, setAppUrl] = useState<string>('');
  const [redirectUrl, setRedirectUrl] = useState<string>('');
  const [appType, setAppType] = useState<string>('web');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [nameError, setNameError] = useState<string>();
  const [urlError, setUrlError] = useState<string>();
  const [redirectUrlError, setRedirectUrlError] = useState<string>();
  const applicationTypeOptions = [
    {
      label: 'Web app',
      value: 'web',
    },
    {
      label: 'Mobile app',
      value: 'mobile',
    },
  ];

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setNameError(undefined);

    if (!appName || appName.length <= 0) {
      return setNameError('Required');
    }

    let enhancedAppUrl = appUrl
    if (appType === 'mobile' && appUrl.length > 0) {
      enhancedAppUrl = appUrl.replaceAll(":", "").replaceAll("/", "").concat("://")
    }

    return onCreateApp({
      appName,
      appType,
      appUrl: enhancedAppUrl,
      redirectUri: [redirectUrl, enhancedAppUrl],
    });
  };

  useEffect(() => {
    const webRegex = new RegExp("https?://.+")
    if (appType === 'web' && appUrl.length > 0 && !webRegex.test(appUrl)) {
      setUrlError('Url must be in format "http(s)://..."')
    } else {
      setUrlError(undefined)
    }
  }, [appUrl, appType])

  useEffect(() => {
    const mobileRegex = new RegExp(".+://.*")
    const webRegex = new RegExp("https?://.+")
    if (appType === 'web' && redirectUrl.length > 0 && !webRegex.test(redirectUrl)) {
      setRedirectUrlError('Redirect url must be in format "http(s)://..."')
    } else if (appType === 'mobile' && redirectUrl.length > 0 && !mobileRegex.test(redirectUrl)) {
      setRedirectUrlError('Redirect url must contain "://"')
    } else {
      setRedirectUrlError(undefined)
    }
  }, [redirectUrl, appType])

  useEffect(() => {
    const mobileRegex = new RegExp(".+://.*")
    const webRegex = new RegExp("https?://.+")
    if (appType === 'web') {
      setIsSubmitDisabled(!(appName.length > 0 && appUrl.length > 0 && redirectUrl.length > 0 && webRegex.test(appUrl) && webRegex.test(redirectUrl)))
    } else if (appType === 'mobile') {
      setIsSubmitDisabled(!(appName.length > 0 && appUrl.length > 0 && redirectUrl.length > 0 && mobileRegex.test(redirectUrl)))
    } else {
      setIsSubmitDisabled(true)
    }
  }, [appName, appUrl, isLoading, redirectUrl]);

  return (
    <Wrapper>
      <form onSubmit={(e: any) => handleSubmit(e)}>
        <FormList style={{ position: 'relative', padding: 0, margin: 0 }}>
          <FormListItem>
            <Title>Connect new app</Title>
          </FormListItem>
          {!!error ? (
            <FormListItem style={{ margin: '-20px 0 20px' }}>
              <AlertBox type="error">{error}</AlertBox>
            </FormListItem>
          ) : null}

          <FormListItem>
            <Textbox
              label="Application name"
              name="appName"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAppName(e.target.value)}
              error={nameError}
              placeholder={appType === 'web' ? "Your app's name" : "Your package's name"}
              disabled={isLoading}
            />
          </FormListItem>
          <FormListItem>
            <CustomDropdown
              label="Application Type"
              name="appType"
              onChange={(option) => setAppType(option.value)}
              options={applicationTypeOptions}
              style={{ marginTop: '35px' }}
              defaultValue={applicationTypeOptions[0]}
            />
          </FormListItem>
          <FormListItem>
            <Textbox
              label={appType === 'web' ? 'Application URL' : 'Package name'}
              name="appUrl"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAppUrl(e.target.value)}
              error={urlError}
              placeholder={appType === 'web' ? 'https://app.yourdomain.com' : 'com.yourdomain.app'}
              disabled={isLoading}
            />
          </FormListItem>
          <FormListItem>
            <Textbox
              label="Login redirect / callback URL"
              name="appRedirectUrl"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRedirectUrl(e.target.value)}
              error={redirectUrlError}
              placeholder={
                appType === 'web' ? 'https://app.yourdomain.com/login' : 'com.yourdomain.app://login-callback'
              }
              disabled={isLoading}
              style={{ marginBottom: 0 }}
            />
            <SmallText>
              Where the user is redirected to after login and the authentication code exchange is handled
            </SmallText>
          </FormListItem>
          <FormListItem>
            <ButtonWrapper>
              <CancelButton type="button" onClick={onModalClose} disabled={isLoading}>
                Cancel
              </CancelButton>
              <SubmitButton
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitDisabled}
                isLoading={isLoading}
              >
                Connect
              </SubmitButton>
            </ButtonWrapper>
          </FormListItem>
        </FormList>
      </form>
    </Wrapper>
  );
};

NewAppForm.defaultProps = {
  isLoading: false,
};

export default NewAppForm;
