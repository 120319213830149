import React from 'react';
import ReactModal from "react-responsive-modal";
import ReactModalStyle from "../../constants/ReactModalStyle";
import { useMedia } from "react-media";
import { MEDIA_QUERIES } from "../../constants/MediaQueries";
import { FormList, FormListItem } from "../atoms/List";
import { PrimaryButton, SecondaryButton } from "../atoms/Button";
import styled from "styled-components";


const CancelButton = styled(SecondaryButton)({
  color: '#E1E7EC',
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  ':hover': {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
  width: '50%',
});

const ButtonWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '70%',
});

const ButtonWrapperWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'flex-end',
});

interface Props {
  isOpen: boolean
  onClose: () => void
}

export const ProjectCreationDiscontinuedModal = (props: Props) => {
  const {isOpen, onClose} = props
  const isSmallScreen = useMedia({query: MEDIA_QUERIES.tablet});

  const goToNewConsole = (e: React.FormEvent) => {
    e.preventDefault()
    document.location.href = "https://cloud.hanko.io"
  }

  return <ReactModal onClose={onClose} open={isOpen} center blockScroll styles={ReactModalStyle({isSmallScreen})}>
    <form onSubmit={goToNewConsole} style={{margin: '80px 128px'}}>
      <FormList>
        <FormListItem>
          <h2 style={{margin: 0, color: 'white'}}>Project creation is discontinued</h2>
        </FormListItem>
        <FormListItem>
          <span style={{color: 'white'}}>You can no longer create new projects, but you can still use your existing ones. Please use our new console at <a
            style={{color: 'white'}} href="https://cloud.hanko.io">https://cloud.hanko.io</a></span>
        </FormListItem>
        <FormListItem>
          <ButtonWrapperWrapper>
            <ButtonWrapper>
              <CancelButton type="button" onClick={props.onClose}>
                Cancel
              </CancelButton>
              <PrimaryButton type="submit">
                Go to the new console
              </PrimaryButton>
            </ButtonWrapper>
          </ButtonWrapperWrapper>
        </FormListItem>
      </FormList>
    </form>
  </ReactModal>
}