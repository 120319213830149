import React, { ChangeEvent, useState } from 'react'
import {
  ColumnLeft,
  ColumnRight,
  ContainerSection,
  SettingsContainerLarge as SettingsContainerLargeComponent,
} from '../../../atoms/Container';
import { SettingsDescription, SettingsTitle } from '../../../atoms/Texts'
import { inject, observer } from 'mobx-react'
import { RelyingPartyStore } from '../../../../store/RelyingPartyStore'
import { OrganizationStore } from '../../../../store/OrganizationStore'
import { isApiError } from '../../../../helper/ResponseHelper'
import { toast } from 'react-toastify'
import { CheckboxSetting } from "../../../molecules/CheckboxSetting";
import { BACKEND_LOGIN_REDIRECT_URL } from "../../../../constants/Url";
import { useCurrentRoute } from "react-navi";
import { HankoMdsStore } from '../../../../store/HankoMdsStore'
import { PrimaryButton } from '../../../atoms/Button'
import { MdsAuthenticatorTable } from "./MdsAuthenticatorTable";
import { SettingsDescriptionLink } from "../../../atoms/Link";
import styled from "styled-components";
import { Color } from '../../../atoms/Color';

const SettingsContainerLarge = styled(SettingsContainerLargeComponent)({
    width: '100%'
});

const CustomizedColumnLeft = styled(ColumnLeft)({
    width: '90%'
})

const CustomizedColumnRight = styled(ColumnRight)({
    width: '10%'
})

const SubTitle = styled(SettingsTitle)({
    fontSize: '14px'
})

const Warning = styled.span({
    color: Color.primaryRed,
    fontSize: 'inherit',
    fontWeight: 'bold',
})

interface Props {
    relyingPartyStore?: RelyingPartyStore
    organizationStore?: OrganizationStore
    hankoMdsStore?: HankoMdsStore
}

type State = {
    allowList: string[]
    allowAll: boolean
}

export const WebauthnSettingsComponent = (props: Props) => {
    const route = useCurrentRoute()

    const [state, setState] = useState<State>({
        allowList: props.relyingPartyStore?.relyingParty?.webauthn_policy?.allow_list ?? [],
        allowAll: props.relyingPartyStore?.relyingParty?.webauthn_policy?.allow_all ?? true
    })
    const [isSettingsUpdating, setIsSettingsUpdating] = useState(false);

    const onWhitelistUpdate = (whitelist: string[]) => {
        setState({
            ...state,
            allowList: whitelist
        })
    }

    const onInput = async (e: ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            allowAll: !e.target.checked
        })
    }

    const updateWebauthnPolicySettings = async () => {
        setIsSettingsUpdating(true);
        const {relyingPartyStore, organizationStore} = props
        const organizationId = organizationStore?.organization?.id ?? ''
        const relyingPartyId = relyingPartyStore?.relyingParty?.id ?? ''

        const result = await relyingPartyStore?.update(organizationId, relyingPartyId, {
            webauthn_policy: {
                allow_all: state.allowAll,
                allow_list: state.allowList
            }
        })

        if (!isApiError(result)) {
            toast.success("Settings updated successfully")
            setState({
                ...state,
                allowAll: result?.webauthn_policy?.allow_all ?? false,
                allowList: result?.webauthn_policy?.allow_list ?? []
            })
        } else if (result.code === 401) {
            window.location.assign(BACKEND_LOGIN_REDIRECT_URL(route.url.href))
        } else {
            toast.error("Setting could not be updated")
        }

        setIsSettingsUpdating(false);

    }

    const {hankoMdsStore, relyingPartyStore} = props

    let isSettingChanged = false
    if (relyingPartyStore?.relyingParty?.webauthn_policy?.allow_all !== state.allowAll || !equals(relyingPartyStore?.relyingParty?.webauthn_policy?.allow_list ?? [], state.allowList)) {
        isSettingChanged = true
    }

    return <SettingsContainerLarge>
        <ContainerSection>
            <SettingsTitle>WebAuthn Settings</SettingsTitle>
            <CustomizedColumnLeft>
                <SubTitle>Authenticator allowlist</SubTitle>
                <SettingsDescription>
                    If disabled, all FIDO2 Authenticators are allowed, regardless of make, model or certification
                    status. Activate the allowlist and select the authenticators below if you want to make sure only
                    authenticators with certain characteristics can be used with your service.
                    <br/>
                    <br/>
                    <Warning>Warning:</Warning> If the allowlist is active and no authenticators are selected, your
                    users will not be able to register any authenticator. Please read
                    the <SettingsDescriptionLink
                    href="https://docs.hanko.io/guides/attestation">documentation</SettingsDescriptionLink> on providing
                    an attestation conveyance preference in registration requests to the WebAuthn API before changing
                    this setting.
                </SettingsDescription>
            </CustomizedColumnLeft>
            <CustomizedColumnRight>
                <CheckboxSetting name="allow_unknown_authenticators"
                                 checked={!state.allowAll}
                                 onInput={onInput}/>
            </CustomizedColumnRight>
        </ContainerSection>
        <ContainerSection>
            <div style={{
                display: 'block',
                opacity: state.allowAll ? 0.5 : 1.0,
                pointerEvents: state.allowAll ? 'none' : 'all'
            }}>
                <MdsAuthenticatorTable authenticators={hankoMdsStore?.authenticators ?? []}
                    whitelist={state.allowList}
                    onWhitelistUpdate={onWhitelistUpdate}
                />
            </div>
            <PrimaryButton disabled={!isSettingChanged} style={{marginTop: '27px', maxWidth: '20%', float: 'right'}}
                           onClick={updateWebauthnPolicySettings} isLoading={isSettingsUpdating}>Save</PrimaryButton>
        </ContainerSection>
    </SettingsContainerLarge>
}

export const WebauthnSettings = inject('relyingPartyStore', 'organizationStore', 'hankoMdsStore')(observer(WebauthnSettingsComponent))

const equals = (a: string[], b: string[]): boolean => {
    return a.length === b.length && a.every((v, i) => v === b[i])
}
