import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { appStateStore } from '../../store/AppStateStore';
import { IdentityStore } from '../../store/IdentityProjectStore';
import { RelyingPartyStore } from '../../store/RelyingPartyStore';

const AccountType = styled.div<{ isSubscriptionPro?: boolean }>`
  padding: 3px 11px;
  background-color: ${({ isSubscriptionPro }) => (isSubscriptionPro ? '#50C9BA' : '#818A93')};
  border-radius: 20px;
  color: #ffffff;
  font-size: 13px;
  font-weight: 600;
`;

interface Props {
  relyingPartyStore?: RelyingPartyStore;
  identityStore?: IdentityStore;
}

const AccountTypeLabelComponent = (props: Props) => {
  const [isProjectIdentity, setIsProjectIdentity] = useState(false);
  const isSubscriptionPro = isProjectIdentity
    ? props.identityStore?.relyingParty?.subscription?.active
    : props.relyingPartyStore?.relyingParty?.subscription?.active;

  const getProjectIdFromUrl = (projectId?: string) => {
    return setIsProjectIdentity(props.identityStore?.relyingParty?.id === projectId);
  };

  useEffect(() => {
    const projectId = window.location.pathname.match(new RegExp(`/relyingParty/([^/]*)/`))?.[1];
    getProjectIdFromUrl(projectId);
  }, [props.identityStore?.relyingParty, props.relyingPartyStore?.relyingParty, window.location.pathname]);

  return appStateStore.isProjectTypeLabelVisible ? (
    <AccountType isSubscriptionPro={isSubscriptionPro}>
      {isProjectIdentity ? 'Identity' : 'API'} | {isSubscriptionPro ? 'PRO' : 'FREE'}
    </AccountType>
  ) : null;
};

export const AccountTypeLabel = inject(
  'appStateStore',
  'relyingPartyStore',
  'identityStore'
)(observer(AccountTypeLabelComponent));
