import { observable, action } from 'mobx'
import { RELYING_PARTY_URL } from '../constants/Url';
import RequestTimeout from '../helper/RequestTimeout';
import { ApiError, handleApiResponse, isApiError } from '../helper/ResponseHelper'
import { ApiKeyCreateRequest, ApiKeyModel, ApiKeyModelCreateResponse } from "../models/ApiKeyModel";

export class ApiKeyStore {

    @observable apiKey?: ApiKeyModelCreateResponse = undefined;
    @observable apiKeyList?: ApiKeyModel[] = undefined;

    @action
    create = async (organizationId: string, relyingPartyId: string, apiKey: ApiKeyCreateRequest) => {
        const response = await RequestTimeout(RELYING_PARTY_URL(organizationId, relyingPartyId) + "/api_keys", {
            method: 'POST',
            credentials: 'same-origin',
            cache: 'no-cache',
            mode: 'cors',
            body: JSON.stringify(apiKey),
        });
        return handleApiResponse<ApiKeyModelCreateResponse>(response).then(this.success).catch(this.error)
    };

    @action
    delete = async (organizationId: string, relyingPartyId: string, apiKeyId: string) => {
        const response = await RequestTimeout(RELYING_PARTY_URL(organizationId, relyingPartyId) + '/api_keys/' + apiKeyId, {
            method: 'DELETE',
            credentials: 'same-origin',
            cache: 'no-cache',
            mode: 'cors',
        })
        return handleApiResponse<ApiKeyModelCreateResponse>(response).then(this.success).catch(this.error)
    };

    @action
    list = async (organizationId: string, relyingPartyId: string) => {
        const response = await RequestTimeout(RELYING_PARTY_URL(organizationId, relyingPartyId) + "/api_keys", {
            method: 'GET',
            credentials: 'same-origin',
            cache: 'no-cache',
            mode: 'cors'
        });
        return handleApiResponse<ApiKeyModel[]>(response).then(this.listSuccess).catch(this.error)
    };

    @action.bound
    success = (result: ApiKeyModelCreateResponse | ApiError) => {
        if (!isApiError(result)) {
            this.apiKey = result
        }
        return result
    };

    @action.bound
    listSuccess = (result: ApiKeyModel[] | ApiError) => {
        if (!isApiError(result)) {
            this.apiKeyList = result
        }
        return result
    };

    @action.bound
    error = (reason: any): ApiError => {
        return {
            code: 0,
            message: 'Could not get fetch.'
        }
    }
}

export const apiKeyStore = new ApiKeyStore();
