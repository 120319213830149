import React, { useState } from 'react';
import styled from 'styled-components';

const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const TabTitlesWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
`;

const Tab = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 145px;
  cursor: pointer;
`;

const TabBottomLine = styled.hr<TabProps>`
  width: 100%;
  height: 3px;
  background: ${({ isTabActive }) => (isTabActive ? '#FF2E4C' : '#ffffff')};
  border: none;
`;

type TabProps = {
  isTabActive: boolean;
  onClick?: (tabIndex: number) => void;
};

type TabTitlesProps = {
  activeTab: number;
  onTabClick: (activeTabIndex: number, tabName: string) => void;
  tabList: TabsProps['tabList'];
};

const TabTitles = ({ activeTab, tabList, onTabClick }: TabTitlesProps) => {
  return (
    <TabTitlesWrapper>
      {tabList.map((tabName: string, index: number) => (
        <Tab key={`${tabName}-${index}`} onClick={() => onTabClick(index, tabName)}>
          {tabName}
          <TabBottomLine isTabActive={activeTab === index} />
        </Tab>
      ))}
    </TabTitlesWrapper>
  );
};

type ContentProps = {
  activeTab: string;
  children: React.ReactNode | React.ReactNodeArray;
  name: string;
};

const Content = ({ activeTab, name, children }: ContentProps) => (activeTab === name ? <div>{children}</div> : null);

type TabsProps = {
  children: React.ReactNode | React.ReactNodeArray;
  onTabClick: (tabName: string) => void;
  tabList: string[];
};

const Tabs = ({ children, onTabClick, tabList }: TabsProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabClick = (activeTabIndex: number, tabName: string) => {
    setActiveTabIndex(activeTabIndex);
    onTabClick(tabName);
  };

  return (
    <TabsWrapper>
      <TabTitles activeTab={activeTabIndex} tabList={tabList} onTabClick={handleTabClick} />
      {children}
    </TabsWrapper>
  );
};

Tabs.Content = Content;

export default Tabs;
