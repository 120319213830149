export class Color {
    public static primaryRed = '#FF2E4C'
    public static lightRed = '#E73B54'
    public static intenseRed = '#E8465D'
    public static moreIntenseRed = '#FA0045'

    public static primaryGreen = '#53EAA5'
    public static primaryBlue = '#5399EA'

    public static darkGrey = '#434343'
    public static grey = '#D8D8D8'
    public static mediumGrey = '#EFEFEF'
    public static lightGrey = '#F8F8F8'
    public static iconGrey = '#818A93'

    public static darkBlue = '#3E464C'
}
