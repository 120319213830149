import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from '../atoms/Button';
import { SettingsContainer } from '../atoms/Container';
import { FormList, FormListItem } from '../atoms/List';
import { Input } from '../atoms/Input';
import { DropZone } from '../atoms/DropZone';
import ColorPicker from '../atoms/ColorPicker';
import Range from '../atoms/Range';
import Tabs from '../atoms/Tabs';
import AlertBox from '../atoms/AlertBox';
import CrossSvg from '../../assets/crossIcon.svg';
import { ReactComponent as VerifiedSvg } from '../../assets/verified.svg';
import { ReactComponent as LongArrowLeft } from '../../assets/long_arrow_left.svg';
import { ReactComponent as FingerPrint } from '../../assets/fingerprint.svg';
import { ReactComponent as DotsIcon } from '../../assets/dots.svg';
import { ReactComponent as KeyIcon } from '../../assets/usb_key.svg';
import { IdentityStore } from "../../store/IdentityProjectStore";
import { inject, observer } from "mobx-react";
import { isApiError } from "../../helper/ResponseHelper";
import { toast } from "react-toastify";

const Container = styled(SettingsContainer)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 35px 30px;
  box-sizing: border-box;
  color: #ffffff;
  text-align: left;
`;

const WindowLeft = styled.div`
  z-index: 1;
  width: 370px;
`;

const PageTitle = styled.h1`
  margin: 0 0 25px;
  font-size: 18px;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ListItemStyles = styled(FormListItem)`
  width: calc(50% - 15px);
  margin-bottom: 35px;
`;

const Button = styled(SecondaryButton)`
  display: block;
  background-color: #545b61;
  color: #bbc4cc;

  &:hover {
    background-color: #545b61;
    color: #ffffff;
  }
`;

const SubmitButton = styled(PrimaryButton)`
  max-width: 170px;
  margin-left: auto;
`;

const WindowRight = styled.div`
  position: absolute;
  z-index: 2;
  right: 40px;
  width: 380px;
  height: 700px;
  margin-top: 50px;
  padding-bottom: 250px;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 7px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); ;
`;

const WindowRightContent = styled.div`
  padding: 0 30px;
  border-color: #ffffff;
`;

const Header = styled.div`
  width: 100%;
  padding: 15px 30px;
  box-sizing: border-box;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const Logo = styled.figure`
  position: relative;
  max-height: 35px;
  margin: 0;
`;

const PreviewTitle = styled.div`
  margin-top: 165px;
  margin-bottom: 28px;
  font-size: 25px;
  font-weight: 700;
`;

const PreviewTextbox = styled.input`
  width: 100%;
  padding: 10px 40px 12px 16px;
  box-sizing: border-box;
  border: 1px solid #36596f;
  border-radius: 2px;
  font-size: 13px;
`;

const PreviewTextboxWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const ButtonCross = styled.div`
  position: absolute;
  display: flex;
  top: 50%;
  right: 12px;
  width: 12px;
  height: 12px;
  padding: 7px;
  transform: translateY(-50%);
  cursor: pointer;
`;

const EmailAddress = styled.div`
  display: flex;
  align-items: center;
  margin: 55px 0 55px;
  font-size: 17px;
  font-weight: 600;
`;

const BackButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -2px;
`;

const BackButton = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border: 2px solid #ffffff;
  border-radius: 4px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
`;

const BackArrow = styled(LongArrowLeft)`
  margin-right: 10px;
`;

const LogoutLink = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 13px;
`;

const BiometricsTitle = styled.div`
  margin-left: 15px;
  font-size: 22px;
  font-weight: 700;
`;

const PreviewText = styled.div`
  margin-top: 13px;
  font-size: 13px;
  font-weight: 400;
`;

const Bold = styled.span`
  font-weight: 700;
`;

const Hr = styled.hr`
  width: 100%;
  height: 1px;
  margin: 20px 0;
  border: none;
  background-color: #9bacb7;
`;

const SmallTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 17px;
  font-size: 14px;
  font-weight: 700;
`;

const DeviceItemWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  font-size: 12px;
  font-weight: 500;
`;

const ItemLabel = styled.div`
  padding: 4px 20px;
  background: #dff4f1;
  border-radius: 7px;
  font-size: 12px;
`;

const LastUsedText = styled.div`
  margin-left: 28px;
  font-size: 14px;
  font-weight: 400;
`;

type State = {
  fileUrl?: string;
  name: string;
  origin: string;
  selectedFile?: File;
};

type PreviewSettingsProps = {
  brandColor: string;
  buttonTextColor: string;
  negativeColor: string;
  positiveColor: string;
  roundedCorners: number;
  secondaryColor: string;
  textColor: string;
};

const PreviewSettings = ({
  brandColor,
  buttonTextColor,
  negativeColor,
  positiveColor,
  roundedCorners,
  secondaryColor,
  textColor,
}: PreviewSettingsProps) => {
  return (
    <>
      <Header style={{ height: '70px', boxSizing: 'border-box', backgroundColor: secondaryColor }}>
        <BackButtonWrapper>
          <BackButton>
            <BackArrow fill="#ffffff" />
            Back to Hanko Console
          </BackButton>
          <LogoutLink>Sign out</LogoutLink>
        </BackButtonWrapper>
      </Header>
      <WindowRightContent>
        <EmailAddress style={{ color: textColor }}>
          john.doe@hanko.io
          <VerifiedSvg fill={positiveColor} style={{ marginLeft: '10px' }} />
        </EmailAddress>
        <TitleWrapper>
          <FingerPrint fill={brandColor} />
          <BiometricsTitle style={{ color: textColor }}>Device Biometrics</BiometricsTitle>
        </TitleWrapper>
        <PreviewText style={{ color: textColor }}>
          Set up this device to authenticate using <Bold>Touch ID</Bold> or <Bold>Windows Hello</Bold> and similar
          methods
        </PreviewText>
        <Hr />
        <SmallTitleWrapper style={{ color: textColor }}>
          Private MacBook
          <DotsIcon />
        </SmallTitleWrapper>
        <DeviceItemWrapper>
          <ItemLabel style={{ color: textColor }}>PWless 2FA</ItemLabel>
          <LastUsedText style={{ color: textColor }}>Last used 8 days ago</LastUsedText>
        </DeviceItemWrapper>
        <PrimaryButton
          style={{
            borderRadius: `${roundedCorners}px`,
            backgroundColor: brandColor,
            color: buttonTextColor === 'light' ? '#ffffff' : '#000000',
          }}
        >
          Add this device
        </PrimaryButton>
        <TitleWrapper style={{ marginTop: '50px' }}>
          <KeyIcon fill={brandColor} style={{ width: '24px' }} />
          <BiometricsTitle style={{ color: textColor }}>Security Keys</BiometricsTitle>
        </TitleWrapper>
        <PreviewText style={{ color: textColor }}>
          A FIDO Security Key is a small hardware key that can be used to unlock your account – just like a real key
        </PreviewText>
        <SecondaryButton
          style={{
            marginTop: '30px',
            borderRadius: `${roundedCorners}px`,
            backgroundColor: '#E6EBED',
            color: textColor,
          }}
        >
          Add this device
        </SecondaryButton>
      </WindowRightContent>
    </>
  );
};

type LoginSettingsProps = {
  brandColor: string;
  buttonTextColor: string;
  handleTextboxClear: () => void;
  inputRef: React.MutableRefObject<HTMLInputElement>;
  logoFileUrl: string | undefined;
  name: string;
  roundedCorners: number;
  secondaryColor: string;
  textColor: string;
  negativeColor: string;
};

const LoginSettings = ({
  brandColor,
  buttonTextColor,
  handleTextboxClear,
  inputRef,
  logoFileUrl,
  name,
  roundedCorners,
  secondaryColor,
  negativeColor,
  textColor,
}: LoginSettingsProps) => {
  return (
    <>
      <Header style={{ height: '70px', boxSizing: 'border-box', backgroundColor: secondaryColor }}>
        {logoFileUrl ? (
          <Logo>
            <img src={logoFileUrl} alt="Logo" style={{ maxWidth: '100%', height: '35px', objectFit: 'contain' }} />
          </Logo>
        ) : null}
      </Header>
      <WindowRightContent>
        <PreviewTitle style={{ color: textColor }}>Sign in to {name}</PreviewTitle>
        <AlertBox type="error" color={negativeColor}>
          Something went wrong!
        </AlertBox>
        <PreviewTextboxWrapper>
          <PreviewTextbox type="text" ref={inputRef} style={{ color: textColor, borderRadius: `${roundedCorners}px` }} />
          <ButtonCross onClick={handleTextboxClear}>
            <img src={CrossSvg} alt="clear text" />
          </ButtonCross>
        </PreviewTextboxWrapper>
        <PrimaryButton
          style={{
            borderRadius: `${roundedCorners}px`,
            backgroundColor: brandColor,
            color: buttonTextColor === 'light' ? '#ffffff' : '#000000',
          }}
        >
          Sign in
        </PrimaryButton>
      </WindowRightContent>
    </>
  );
};

type IdentityProjectBrandSettingsProps = {
  organizationId: string;
  relyingPartyId: string;
  identityStore?: IdentityStore
};

const IdentityProjectBrandSettingsComponent = ({ organizationId, relyingPartyId, identityStore }: IdentityProjectBrandSettingsProps) => {
  const defaultPositiveColor = '#50C9BA'
  const defaultNegativeColor = '#FF2E4C'
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [activeTab, setActiveTab] = useState('Preview 1');
  const [name, setName] = useState('');
  const [brandColor, setBrandColor] = useState('#FF2E4C');
  const [secondaryColor, setSecondaryColor] = useState('#545B61');
  const [positiveColor, setPositiveColor] = useState(defaultPositiveColor);
  const [negativeColor, setNegativeColor] = useState(defaultNegativeColor);
  const [textColor, setTextColor] = useState('#001B30');
  const [roundedCorners, setRoundedCorners] = useState(2);
  const [logoFileUrl, setLogoFileUrl] = useState<string | undefined>(undefined);
  const [faviconFileUrl, setFaviconFileUrl] = useState<string | undefined>(undefined);
  const [buttonTextColor, setButtonTextColor] = useState<'light' | 'dark'>('light');
  const [logoFileSelected, setLogoFileSelected] = useState<File | undefined>(undefined);
  const [faviconFileSelected, setFaviconFileSelected] = useState<File | undefined>(undefined);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const [hasError, setHasError] = useState({
    name: false,
    logo: false,
  });

  const handleTextboxClear = () => {
    inputRef.current.value = '';
    return inputRef.current.focus();
  };

  const onLogoFileChanged = (file?: File, fileUrl?: string) => {
    setLogoFileUrl(fileUrl);
    setLogoFileSelected(file);
  };

  const onFaviconFileChanged = (file?: File, fileUrl?: string) => {
    setFaviconFileUrl(fileUrl);
    setFaviconFileSelected(file);
  };

  const hasSomethingChanged = () => {
    return logoFileSelected != undefined ||
        faviconFileSelected != undefined ||
        name !== identityStore?.relyingParty?.name ||
        roundedCorners !== identityStore?.relyingParty?.brand.border_radius ||
        textColor !== identityStore?.relyingParty?.brand.text_color ||
        brandColor !== identityStore?.relyingParty?.brand.brand_color ||
        secondaryColor !== identityStore?.relyingParty?.brand.secondary_color ||
        (identityStore?.relyingParty?.brand.positive_color !== undefined ? positiveColor !== identityStore?.relyingParty?.brand.positive_color : positiveColor !== defaultPositiveColor) ||
        (identityStore?.relyingParty?.brand.negative_color !== undefined ? negativeColor !== identityStore?.relyingParty?.brand.negative_color : negativeColor !== defaultNegativeColor)
  }

  const handleTenantUpdate = (updateState: any) => {
    updateState();
  };

  const handleSaveChanges = async () => {
    setIsSaveButtonDisabled(true);

    let logoImageUrl: string | undefined = undefined
    let faviconImageUrl: string | undefined = undefined

    if (logoFileSelected !== undefined) {
      const response = await identityStore?.uploadImage(logoFileSelected)
      if (isApiError(response)) {
        response?.code === 413
          ? toast.error(`Logo too large: max. 1 MB please`)
          : toast.error('Failed to save changes');
        setIsSaveButtonDisabled(false);
        return
      }
      logoImageUrl = response?.url
    }

    if (faviconFileSelected !== undefined) {
      const response = await identityStore?.uploadImage(faviconFileSelected)
      if (isApiError(response)) {
        response?.code === 413
          ? toast.error(`Favicon too large: max. 1 MB please`)
          : toast.error("Failed to save changes")
        setIsSaveButtonDisabled(false);
        return
      }
      faviconImageUrl = response?.url
    }

    identityStore?.update(organizationId, relyingPartyId, {
      name,
      brand: {
        brand_color: brandColor,
        secondary_color: secondaryColor,
        text_color: textColor,
        border_radius: roundedCorners,
        logo_url: logoImageUrl,
        favicon_url: faviconImageUrl,
        positive_color: positiveColor,
        negative_color: negativeColor,
      }
    }).then((res) => {
      setIsSaveButtonDisabled(false)
      if (!isApiError(res)) {
        setFaviconFileSelected(undefined)
        setLogoFileSelected(undefined)
        toast.success("Saved changes")
      } else {
        toast.error("Failed to save changes")
      }
    })
  };

  useEffect(() => {
    identityStore?.get(organizationId, relyingPartyId)
        .then((res) => {
          if (!isApiError(res)) {
            const { brand, name } = res;

            setName(name);
            setBrandColor(brand.brand_color ?? '#FF2E4C');
            setSecondaryColor(brand.secondary_color ?? '#545B61');
            setPositiveColor(brand.positive_color ?? defaultPositiveColor);
            setNegativeColor(brand.negative_color ?? defaultNegativeColor);
            setTextColor(brand.text_color ?? '#001B30');
            setRoundedCorners(brand.border_radius ?? 2);
            setLogoFileUrl(brand.logo_url);
            setFaviconFileUrl(brand.favicon_url);
            setLogoFileSelected(undefined);
            setFaviconFileSelected(undefined);
          }
        })
  }, [relyingPartyId]);

  return (
    <Container>
      <WindowLeft>
        <PageTitle >Brand settings</PageTitle>
        <form onSubmit={(e) => e.preventDefault()}>
          <FormList style={{ margin: '0' }}>
            <FormListItem>
              <Input
                label="Name"
                name="projectName"
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleTenantUpdate(() => setName(e.target.value))}
                value={name}
                error={hasError.name ? 'Required' : undefined}
              />
            </FormListItem>
            <ListWrapper>
              <ListItemStyles>
                <DropZone
                  label="Logo"
                  onFileChanged={(file, fileUrl) => handleTenantUpdate(() => onLogoFileChanged(file, fileUrl))}
                  fileUrl={logoFileUrl}
                  error={hasError.logo && 'Required'}
                />
              </ListItemStyles>
              <ListItemStyles>
                <DropZone
                  label="Favicon"
                  onFileChanged={(file, fileUrl) => handleTenantUpdate(() => onFaviconFileChanged(file, fileUrl))}
                  fileUrl={faviconFileUrl}
                  acceptTypes={['image/png', 'image/jpeg', '.ico']}
                />
              </ListItemStyles>
              <ListItemStyles>
                <Range
                  label="Rounded corners"
                  start={0}
                  end={25}
                  value={roundedCorners}
                  onChange={(val) => handleTenantUpdate(() => setRoundedCorners(val))}
                />
              </ListItemStyles>
              <ListItemStyles>
                <ColorPicker
                  defaultColor={textColor}
                  label="Text color"
                  onChange={(color) => handleTenantUpdate(() => setTextColor(color))}
                />
              </ListItemStyles>
              <ListItemStyles>
                <ColorPicker
                  defaultColor={brandColor}
                  label="Brand color"
                  onChange={(color) => handleTenantUpdate(() => setBrandColor(color))}
                />
              </ListItemStyles>
              <ListItemStyles>
                <ColorPicker
                  defaultColor={secondaryColor}
                  label="Secondary color"
                  onChange={(color) => handleTenantUpdate(() => setSecondaryColor(color))}
                />
              </ListItemStyles>
              <ListItemStyles>
                <ColorPicker
                  defaultColor={positiveColor}
                  label="Positive color"
                  onChange={(color) => handleTenantUpdate(() => setPositiveColor(color))}
                />
              </ListItemStyles>
              <ListItemStyles>
                <ColorPicker
                  defaultColor={negativeColor}
                  label="Negative color"
                  onChange={(color) => handleTenantUpdate(() => setNegativeColor(color))}
                />
              </ListItemStyles>
            </ListWrapper>
            <FormListItem style={{ display: 'flex' }}>
              <SubmitButton onClick={handleSaveChanges} disabled={isSaveButtonDisabled ? isSaveButtonDisabled : !hasSomethingChanged()}>
                Save changes
              </SubmitButton>
            </FormListItem>
          </FormList>
        </form>
      </WindowLeft>
      <div style={{ marginRight: '55px' }}>
        <Tabs tabList={['Preview 1', 'Preview 2']} onTabClick={(tabName) => setActiveTab(tabName)}>
          <WindowRight>
            <Tabs.Content name="Preview 1" activeTab={activeTab}>
              <PreviewSettings
                brandColor={brandColor}
                buttonTextColor={buttonTextColor}
                roundedCorners={roundedCorners}
                secondaryColor={secondaryColor}
                textColor={textColor}
                positiveColor={positiveColor}
                negativeColor={negativeColor}
              />
            </Tabs.Content>
            <Tabs.Content name="Preview 2" activeTab={activeTab}>
              <LoginSettings
                brandColor={brandColor}
                buttonTextColor={buttonTextColor}
                handleTextboxClear={handleTextboxClear}
                inputRef={inputRef}
                logoFileUrl={logoFileUrl}
                name={name}
                roundedCorners={roundedCorners}
                secondaryColor={secondaryColor}
                textColor={textColor}
                negativeColor={negativeColor}
              />
            </Tabs.Content>
          </WindowRight>
        </Tabs>
      </div>
    </Container>
  );
};

export const IdentityProjectBrandSettings = inject('identityStore')(observer(IdentityProjectBrandSettingsComponent))
