import React, { ChangeEvent } from 'react'
import { ToggleSwitchWrapper, ToggleSwitchBox, ToggleSwitchLabel } from '../atoms/ToggleSwitch'

interface Props {
    name: string,
    onInput: (e: ChangeEvent<HTMLInputElement>) => void
    checked?: boolean
}

export const CheckboxSetting = (props: Props) => {
    const {name, checked, onInput} = props
    return <ToggleSwitchWrapper>
        <ToggleSwitchBox id={name} name={name} type="checkbox" checked={checked} onChange={onInput}/>
        <ToggleSwitchLabel htmlFor={name}/>
    </ToggleSwitchWrapper>

}