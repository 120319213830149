import React from 'react';
import styled, { CSSProperties } from 'styled-components';
import { ReactComponent as LoadingCircle } from '../../assets/loading_wheel.svg';

const Wrapper = styled(LoadingCircle)`
  position: absolute;
  z-index: 9;
  top: 50%;
  left: 50%;
  animation: rotate 0.7s infinite linear;

  @keyframes rotate {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`;

type Props = {
  color?: string;
  style?: CSSProperties;
  size?: number;
};

const LoadingWheel = ({ color, size, style }: Props) => {
  return <Wrapper style={style} width={size} height={size} fill={color} />;
};

LoadingWheel.defaultProps = {
  size: 21,
  color: '#bbc4cc',
};

export default LoadingWheel;
