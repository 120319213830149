import {action, observable} from "mobx";
import {MetadataStatement} from "../models/HankoMdsAuthenticator";
import {HANKO_MDS_GET_LATEST_STATEMENTS} from "../constants/Url";
import {ApiError, handleApiResponse, isApiError} from "../helper/ResponseHelper";
import RequestTimeout from "../helper/RequestTimeout";

export class HankoMdsStore {
    @observable authenticators: MetadataStatement[] = []

    @action
    get = async () => {
        const response = await RequestTimeout(HANKO_MDS_GET_LATEST_STATEMENTS, {
            method: 'GET',
            credentials: 'same-origin'
        })

        return handleApiResponse<MetadataStatement[]>(response).then(this.success).catch(this.error)
    }

    @action.bound
    success = (result: MetadataStatement[] | ApiError) => {
        if (!isApiError(result)) {
            this.authenticators = result
        }
        return result
    };

    @action.bound
    error = (reason: any): ApiError => {
        return {
            code: 0,
            message: 'Could not get authenticator list from Hanko MDS.'
        }
    }
}

export const hankoMdsStore = new HankoMdsStore()
