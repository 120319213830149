import React, { useState, CSSProperties, useEffect } from 'react';
import styled from 'styled-components';
import { NoStylingButton } from './Button';
import { Input } from './Input';
import { ReactComponent as PlusIconSvg } from '../../assets/plus_icon.svg';
import { ReactComponent as MinusIconSvg } from '../../assets/minus_icon.svg';

const Wrapper = styled.div``;

const Row = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 45px;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const Textbox = styled(Input)`
  width: 100%;
  max-width: 440px;
  margin-right: 5px;
`;

const SumButton = styled(NoStylingButton)`
  position: relative;
  width: 52px;
  height: 52px;
  background: #272f34;
  border-radius: 2px;
`;

const MinusButton = styled(NoStylingButton)`
  position: relative;
  width: 52px;
  height: 52px;
  background: #272f34;
  border-radius: 2px;
`;

const PlusIcon = styled(PlusIconSvg)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const MinusIcon = styled(MinusIconSvg)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

type Props = {
  error?: string;
  label: string;
  name?: string;
  onChange: (val: string[]) => void;
  placeholder?: string;
  value?: string;
  defaultValues?: string[];
  style?: CSSProperties;
};

type TextboxInputProps = {
  defaultValue?: string;
  index: number;
  name?: string;
  onChange: (val: string, index: number) => void;
  placeholder?: string;
  value?: string;
};

const TextboxInput = ({ defaultValue, index, name, onChange, placeholder, value }: TextboxInputProps) => {
  const handleOnChange = (input: string) => {
    onChange(input, index);
  };

  return (
    <Textbox
      label=""
      name={name}
      value={value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange(e.target.value)}
      placeholder={placeholder}
      defaultValue={defaultValue}
    />
  );
};

const IncrementalTextbox = ({ defaultValues, error, label, name, onChange, placeholder, style, value }: Props) => {
  const [inputValue, setInputValue] = useState('');
  const [incrementedValues, setIncrementedValues] = useState<string[]>(defaultValues || ['']);

  useEffect(() => {
    setIncrementedValues(defaultValues || [''])
  }, [defaultValues])

  const removeIndexFromArray = (index: number) => {
    const newArray = [
      ...incrementedValues.slice(0, index),
      ...incrementedValues.slice(index + 1, incrementedValues.length),
    ];

    setIncrementedValues(newArray);

    return handleOnChange(newArray);
  };

  const handleSumButton = () => {
    const newArray = incrementedValues.length > 0 ? [...incrementedValues, ''] : ['', ''];

    setIncrementedValues(newArray);
    onChange(newArray);
  };

  const handleOnTextboxChange = (input: string, index: number) => {
    const newArray = [
      ...incrementedValues.slice(0, index),
      input,
      ...incrementedValues.slice(index + 1, incrementedValues.length),
    ];

    setIncrementedValues(newArray);

    return handleOnChange(newArray);
  };

  const handleOnBaseTextboxChange = (inputValue: string) => {
    const newArray = [inputValue, ...incrementedValues.slice(1, incrementedValues.length)];

    setInputValue(inputValue);
    setIncrementedValues(newArray);
    onChange(newArray);
  };

  const handleOnChange = (newArray?: string[]) => {
    const values = newArray || incrementedValues;

    return onChange([...values]);
  };

  return (
    <Wrapper style={style}>
      <Row>
        <Textbox
          label={label}
          name={name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnBaseTextboxChange(e.target.value ?? '')}
          error={error}
          placeholder={placeholder}
          defaultValue={defaultValues?.[0]}
        />
        <SumButton onClick={handleSumButton}>
          <PlusIcon />
        </SumButton>
      </Row>
      {incrementedValues.map((textboxValue, index) =>
        defaultValues && defaultValues.length > 0 && index !== 0 ? (
          <Row key={`row-${index}`}>
            <TextboxInput
              onChange={handleOnTextboxChange}
              value={textboxValue}
              placeholder={placeholder}
              index={index}
            />
            <MinusButton onClick={() => removeIndexFromArray(index)}>
              <MinusIcon />
            </MinusButton>
          </Row>
        ) : null
      )}
    </Wrapper>
  );
};

export default IncrementalTextbox;
