import React, { ChangeEvent, useState } from "react";
import {
  ColumnLeft,
  ColumnRight,
  SettingsContainerLarge as SettingsContainerLargeComponent,
} from '../../../atoms/Container';
import { SettingsDescription, SettingsTitle } from "../../../atoms/Texts";
import { InputWithRadioButton } from "../../../atoms/InputWithRadio";
import { FormList, FormListItem } from "../../../atoms/List";
import styled from "styled-components";
import { RelyingPartyStore } from "../../../../store/RelyingPartyStore";
import { OrganizationStore } from "../../../../store/OrganizationStore";
import { PrimaryButton } from '../../../atoms/Button'
import { inject, observer } from "mobx-react";
import { isApiError } from "../../../../helper/ResponseHelper";
import { BACKEND_LOGIN_REDIRECT_URL } from "../../../../constants/Url";
import { toast } from "react-toastify";
import { useCurrentRoute } from "react-navi";
import { Color } from "../../../atoms/Color";

const SettingsContainerLarge = styled(SettingsContainerLargeComponent)({
  width: '100%'
});

const Left = styled(ColumnLeft)({
    width: '30%'
})

const Right = styled(ColumnRight)({
    width: '70%'
})

const HintText = styled.span({
    fontSize: '14px',
    color: '#FFFFFF'
})

const WarningHintText = styled(HintText)({
    color: Color.primaryRed
})

const DomainSettingsFormList = styled(FormList)({
    marginTop: 0,
    marginRight: 0
})

interface Props {
    relyingPartyStore?: RelyingPartyStore
    organizationStore?: OrganizationStore
}

type State = {
    selected: string
    domain: string
    valid: boolean
}

const WebauthnDomainSettingsComponent = (props: Props) => {
    const localTestingUrl = "http://localhost"
    const route = useCurrentRoute()
    const [state, setState] = useState<State>({
        selected: props.relyingPartyStore?.relyingParty?.origin === localTestingUrl ? "development" : "production",
        domain: props.relyingPartyStore?.relyingParty?.origin === localTestingUrl ? "" : props.relyingPartyStore?.relyingParty?.origin ?? "",
        valid: false
    })
    const [newCustomDomain, setNewCustomDomain] = useState("")
    const [isDomainButtonActive, setIsDomainButtonActive] = useState(false)
    const [isSavingSettings, setIsSavingSettings] = useState(false)

    const saveChanges = async (e: React.FormEvent) => {
        e.preventDefault()

        if (isSavingSettings) {
            return;
        }

        setIsSavingSettings(true);
        setIsDomainButtonActive(false);
        const organizationId = props.organizationStore?.organization?.id ?? ''
        const relyingPartyId = props.relyingPartyStore?.relyingParty?.id ?? ''
        let domain = state.domain
        if (state.selected == "development") {
            domain = localTestingUrl
        }

        const result = await props.relyingPartyStore?.update(organizationId, relyingPartyId, {origin: domain})
        if (!isApiError(result)) {
            toast.success("Domain updated")
        } else if (result.code === 401) {
            window.location.assign(BACKEND_LOGIN_REDIRECT_URL(route.url.href))
            setIsDomainButtonActive(true)
        } else {
            toast.error("Domain could not be updated")
            setIsDomainButtonActive(true)
        }

        setIsSavingSettings(false);
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const target = event.target
        const inputValid = target.validity.valid
        const stateChange = {[target.name]: target.value} as Pick<State, 'domain'>
        setNewCustomDomain(target.value);
        
        if (inputValid && target.value.length > 0) {
          setIsDomainButtonActive(true);
        } else {
          setIsDomainButtonActive(false);
        }

        return setState({
            ...state,
            ...stateChange,
            valid: inputValid
        })
    }

    const handleSelectionChange = (event: ChangeEvent<HTMLInputElement>) => {
        const initialSelectionValue = props.relyingPartyStore?.relyingParty?.origin === localTestingUrl ? "development" : "production";
        const value = event.target.value
        
        if ((value !== initialSelectionValue && value === "development") || (value === "production" && newCustomDomain.length > 0 && state.valid)) {
            setIsDomainButtonActive(true);
        } else {
            setIsDomainButtonActive(false);
        }

        return setState({
            ...state,
            selected: value
        })
    }

    return <SettingsContainerLarge>
        <SettingsTitle>Project domain</SettingsTitle>
        <Left>
            <SettingsDescription>Set up your project's domain, also known as WebAuthn Relying Party Identifier (RP ID)
                or origin.</SettingsDescription>
        </Left>
        <Right>
            <form onSubmit={saveChanges}>
                <DomainSettingsFormList>
                    <FormListItem><InputWithRadioButton type="url" label="Local development & testing"
                                                        radioValue="development"
                                                        readOnly
                                                        active={state.selected === "development"}
                                                        value={localTestingUrl} onRadioChange={handleSelectionChange} />
                    </FormListItem>
                    <FormListItem><InputWithRadioButton type="url" label="Domain" radioValue="production"
                                                        active={state.selected === "production"} value={state.domain}
                                                        name="domain" onChange={handleInputChange}
                                                        onRadioChange={handleSelectionChange}
                                                        pattern="^https://.*" placeholder="e.g. https://myservice.com"/>
                    </FormListItem>
                    <FormListItem><HintText>Set the domain to the effective domain where your application is hosted.
                        Must be HTTPS.</HintText></FormListItem>
                    <FormListItem><WarningHintText>Warning: </WarningHintText><HintText>The WebAuthn credentials of your
                        users will be bound (scoped) to this domain. Changing the domain makes all existing credentials
                        unusable.</HintText></FormListItem>
                    <FormListItem>
                        <PrimaryButton type="submit" style={{width: '160px', float: 'right'}}
                            isLoading={isSavingSettings} disabled={!isDomainButtonActive}>Save changes</PrimaryButton>
                    </FormListItem>
                </DomainSettingsFormList>
            </form>
        </Right>
    </SettingsContainerLarge>
}

export const WebauthnDomainSettings = inject('organizationStore', 'relyingPartyStore')(observer(WebauthnDomainSettingsComponent))