const API_URL = '/api';

export const ORGANIZATION_URL = (organizationId?: string) => API_URL + '/organizations/' + organizationId || '';
export const RELYING_PARTY_URL = (organizationId: string, relyingPartyId: string) => ORGANIZATION_URL(organizationId) + '/relying_parties/' + relyingPartyId

export const CREATE_ORGANIZATION_URL = API_URL + '/organizations'
export const GET_ORGANIZATIONS_URL = (userId: string) => API_URL + '/users/' + userId + '/organizations'

export const GET_USER_URL = () => API_URL + '/me'
export const PATCH_USER_URL = (userId: string) => API_URL + '/users/' + userId

export const CREATE_IDENTITY_PROJECT = (organizationId?: string) => `${API_URL}/organizations/${organizationId}/identity_tenants`;
export const GET_IDENTITY_URL = CREATE_IDENTITY_PROJECT
export const GET_IDENTITY_PROJECT_URL = (orgaId: string, rpId: string) => CREATE_IDENTITY_PROJECT(orgaId) + '/' + rpId
export const PATCH_IDENTITY_PROJECT_URL = GET_IDENTITY_PROJECT_URL
export const DELETE_IDENTITY_PROJECT_URL = GET_IDENTITY_PROJECT_URL
export const UPLOAD_IDENTITY_PROJECT_LOGO_URL = (orgaId: string, rpId: string) => GET_IDENTITY_PROJECT_URL(orgaId, rpId) + '/logo'

export const CREATE_API_PROJECT = (organizationId?: string) => `${API_URL}/organizations/${organizationId}/relying_parties`;
export const GET_RELYING_PARTIES_URL = CREATE_API_PROJECT
export const GET_RELYING_PARTY_URL = (orgaId: string, rpId: string) => CREATE_API_PROJECT(orgaId) + '/' + rpId
export const PATCH_RELYING_PARTY_URL = GET_RELYING_PARTY_URL
export const DELETE_RELYING_PARTY_URL = GET_RELYING_PARTY_URL
export const UPLOAD_RELYING_PARTY_LOGO_URL = (orgaId: string, rpId: string) => GET_RELYING_PARTY_URL(orgaId, rpId) + '/logo'

export const GET_RELYING_PARTY_USER_COUNTS = (orgaId: string, rpId: string) => GET_RELYING_PARTY_URL(orgaId, rpId) + '/user_count'
export const GET_RELYING_PARTY_ACTIVE_USERS_PER_DAY = (orgaId: string, rpId: string) => GET_RELYING_PARTY_URL(orgaId, rpId) + '/active_users_per_day'
export const GET_RELYING_PARTY_REQUESTS_PER_DAY = (orgaId: string, rpId: string) => GET_RELYING_PARTY_URL(orgaId, rpId) + '/requests_per_day'

export const GET_IDENTITY_USER_COUNTS = (orgaId: string, rpId: string) => GET_IDENTITY_PROJECT_URL(orgaId, rpId) + '/user_count'
export const GET_IDENTITY_ACTIVE_USERS_PER_DAY = (orgaId: string, rpId: string) => GET_IDENTITY_PROJECT_URL(orgaId, rpId) + '/active_users_per_day'
export const GET_IDENTITY_REQUESTS_PER_DAY = (orgaId: string, rpId: string) => GET_IDENTITY_PROJECT_URL(orgaId, rpId) + '/requests_per_day'

export const GET_RELYING_PARTY_TENANT_STATUS = (orgaId: string, rpId: string) => GET_RELYING_PARTY_URL(orgaId, rpId) + '/status'
export const GET_IDENTITY_PROJECT_TENANT_STATUS = (orgaId: string, rpId: string) => GET_IDENTITY_PROJECT_URL(orgaId, rpId) + '/status'

export const GET_TRUSTED_FACETS = (orgaId: string, rpId: string) => GET_RELYING_PARTY_URL(orgaId, rpId) + '/trusted_facets'
export const ADD_TRUSTED_FACET = GET_TRUSTED_FACETS
export const DELETE_TRUSTED_FACET = (orgaId: string, rpId: string, tfId: string) => GET_TRUSTED_FACETS(orgaId, rpId) + '/' + tfId

export const PUT_WEBAUTHN_VERIFICATION_SETTINGS = (orgaId: string, rpId: string) => GET_RELYING_PARTY_URL(orgaId,rpId) + '/webauthn/verification_settings'

export const GET_PROFILE_URL = API_URL + '/profile_url'


export const BACKEND_LOGIN_REDIRECT_URL = (requestedUrl?: string, isRegister?: string) => API_URL + '/login?requested_url=' + (requestedUrl ?? '') + '&is_register=' + (isRegister ?? '');

export const HANKO_MDS_GET_AUTHENTICATORS = API_URL + "/mds/fido2/names"
export const HANKO_MDS_GET_LATEST_STATEMENTS = API_URL + "/mds/fido2/statements"

export const GET_AUTHENTICATION_PRICES = API_URL + "/prices/authentication"
export const GET_IDENTITY_PRICES = API_URL + '/prices/identity'
export const UPGRADE_AUTHENTICATION = (orgaId: string, rpId: string) => ORGANIZATION_URL(orgaId) + '/authentications/' + rpId + '/subscription'
export const GET_BILLING_PORTAL_URL = (orgaId: string) => ORGANIZATION_URL(orgaId) + '/portal'

// Identity
export const GET_OPENID_CONFIGURATION = (orgaId: string) => ORGANIZATION_URL(orgaId) + '/clients/openid-configuration'
export const IDENTITY_TENANTS = (orgaId: string, rpId: string) => `${ORGANIZATION_URL(orgaId)}/identity_tenants/${rpId}`
export const GET_IDENTITY_TENANT_CUSTOM_DOMAIN_STATUS = (orgaId: string, rpId: string) => IDENTITY_TENANTS(orgaId, rpId) + '/custom_domain'
export const UPGRADE_IDENTITY = (orgaId: string, rpId: string) => IDENTITY_TENANTS(orgaId, rpId) + '/subscription'

export const UPLOAD_IMAGE_URL = API_URL + '/image'
// Relying Party Apps
export const RELYING_PARTY_APPS = (organizationId: string, relyingPartyId: string) => `${API_URL}/organizations/${organizationId}/identity_tenants/${relyingPartyId}/clients`
export const GET_APP_DETAILS = (organizationId: string, relyingPartyId: string, appId: string) => `${API_URL}/organizations/${organizationId}/identity_tenants/${relyingPartyId}/clients/${appId}`
export const REGENERATE_APP_SECRET = (organizationId: string, relyingPartyId: string, appId: string) => `${API_URL}/organizations/${organizationId}/identity_tenants/${relyingPartyId}/clients/${appId}/secret`

export const GET_OIDC_CONFIGURATION = (organizationId: string, identityTenantId: string) => `${API_URL}/organizations/${organizationId}/identity_tenants/${identityTenantId}/clients/openid-configuration`
