import React, { ChangeEvent, useState } from 'react'
import styled from 'styled-components'
import { RelyingPartyStore } from '../../../store/RelyingPartyStore'
import { inject, observer } from 'mobx-react'
import { Input } from '../../atoms/Input'
import { FormList, FormListItem } from '../../atoms/List'
import { DropZone } from '../../atoms/DropZone'
import { PrimaryButton } from '../../atoms/Button'
import { OrganizationStore } from '../../../store/OrganizationStore'
import { isApiError } from '../../../helper/ResponseHelper'
import { SettingsDescription, SettingsTitle } from '../../atoms/Texts'
import { toast } from 'react-toastify'
import { BACKEND_LOGIN_REDIRECT_URL } from "../../../constants/Url";
import { useCurrentRoute } from "react-navi";
import { Color } from "../../atoms/Color";

const Container = styled.div({
    width: 'calc(100% - 60px)',
    color: '#FFFFFF',
    backgroundColor: Color.darkBlue,
    padding: '30px',
    borderRadius: '5px'
})

interface Props {
    relyingPartyStore?: RelyingPartyStore
    organizationStore?: OrganizationStore
}

type State = {
    name: string,
    initialized: boolean,
    selectedFile?: File,
    fileUrl?: string
}

const RelyingPartySettingsComponent = (props: Props) => {
    const route = useCurrentRoute()
    const [state, setState] = useState<State>({
        name: '',
        initialized: false,
        fileUrl: undefined
    })

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        const {relyingPartyStore, organizationStore} = props
        const {name, selectedFile} = state
        const organizationId = organizationStore?.organization?.id ?? ''
        const relyingPartyId = relyingPartyStore?.relyingParty?.id ?? ''
        const result = await relyingPartyStore?.update(organizationId, relyingPartyId, {name: name})
        if (!isApiError(result)) {
            toast.success("Name updated")
            if (selectedFile) {
                const logoResult = await relyingPartyStore?.uploadFile(organizationId, relyingPartyId, selectedFile)
                if (!isApiError(logoResult)) {
                    setState({
                        ...state,
                        selectedFile: undefined,
                        fileUrl: logoResult?.icon_url
                    })
                    toast.success("Logo uploaded")
                } else if (logoResult.code === 401) {
                    window.location.assign(BACKEND_LOGIN_REDIRECT_URL(route.url.href))
                } else if (logoResult.code === 413) {
                    toast.error(`Logo too large: max. 1 MB please`)
                } else {
                    toast.error("Failed to upload logo")
                }
            }
        } else if (result.code === 401) {
            window.location.assign(BACKEND_LOGIN_REDIRECT_URL(route.url.href))
        } else {
            toast.error("Failed to update project name")
        }
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const target = event.target

        const stateChange = {[target.name]: target.value} as Pick<State, 'name'>
        setState({...state, ...stateChange})
    }

    const onFileChanged = (file?: File, fileUrl?: string) => {
        setState({
            ...state,
            selectedFile: file,
            fileUrl: fileUrl
        })
    }

    const {relyingPartyStore} = props
    const {name, initialized, selectedFile, fileUrl} = state

    if (name === '' && relyingPartyStore?.relyingParty && !initialized) {
        setState({
            ...state,
            name: relyingPartyStore?.relyingParty?.name ?? '',
            fileUrl: relyingPartyStore?.relyingParty?.icon_url,
            initialized: true
        })
    }

    const somethingChanged = relyingPartyStore?.relyingParty?.name !== name?.trim() || selectedFile !== undefined

    return <Container>
        <SettingsTitle>Brand</SettingsTitle>
        <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{width: '40%', textAlign: 'left'}}>
                <SettingsDescription>The project name and logo will be used in emails sent out by the Passlink API as
                    well as here in the Hanko Console to help you identify your project.</SettingsDescription>
            </div>
            <div style={{width: '60%'}}>
                <form onSubmit={onSubmit}>
                    <FormList style={{marginTop: 0, marginRight: 0}}>
                        <FormListItem>
                            <Input label="Project name" name="name" value={name} onChange={handleInputChange}/>
                        </FormListItem>
                        <FormListItem>
                            <DropZone onFileChanged={onFileChanged} fileUrl={fileUrl}/>
                        </FormListItem>
                        <FormListItem style={{textAlign: 'end'}}>
                            <PrimaryButton style={{maxWidth: '160px'}} type="submit" disabled={!somethingChanged}>Save
                                changes</PrimaryButton>
                        </FormListItem>
                    </FormList>
                </form>
            </div>
        </div>
    </Container>
}

export const AuthenticationApiBrandSettings = inject('organizationStore', 'relyingPartyStore')(observer(RelyingPartySettingsComponent))
