import React from "react";
import styled from "styled-components";
import { PasslinkRedirectUrlModel } from "../../../models/PasslinkRedirectUrlModel";

const ItemRow = styled.tr({
    borderTop: '1px solid #272F34',
    ':last-child': {
        borderBottom: '1px solid #272F34'
    },
    fontSize: '14px',
    color: '#FFFFFF',
    padding: '25px 0'
})

const TableCell = styled.td({
    padding: '25px 0',
    ':nth-child(2)': {
        width: '100px'
    },
    ':nth-child(3)': {
        width: '50px'
    }
})

const Url = styled.span({
    fontSize: '14px',
    fontFamily: 'IBM Plex Mono, monospace'
})

const Badge = styled.span({
    fontSize: '12px',
    padding: '5px 20px',
    border: '1px solid #FFFFFF',
    borderRadius: '3px',
    marginLeft: '20px'
})

const Link = styled.a({
    color: '#FFFFFF',
    textDecoration: 'underline',
    fontSize: '14px',
    fontWeight: 500,
})

interface Props {
    item: PasslinkRedirectUrlModel
    openRemoveModal: (redirectUrl: PasslinkRedirectUrlModel) => void
    makeDefault: (redirectUrl: PasslinkRedirectUrlModel) => void
}

export const PasslinksTableItem = (props: Props) => {

    const truncate = (str: string, n: number) => {
        return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
    }

    const openRemoveModal = () => {
        props.openRemoveModal(props.item)
    }

    const makeDefault = () => {
        props.makeDefault(props.item)
    }

    return <ItemRow>
        <TableCell><Url>{truncate(props.item.url, 85)}</Url>{props.item.default ? <Badge>Default</Badge> : null}
        </TableCell>
        <TableCell>{props.item.default ? '' : <Link href='#' onClick={makeDefault}>Make Default</Link>}</TableCell>
        <TableCell><Link href="#" onClick={openRemoveModal}>Delete</Link></TableCell>
    </ItemRow>
}