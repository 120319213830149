import React from 'react'
import styled from 'styled-components'
import ReactModal from 'react-responsive-modal'
import { Color } from '../../atoms/Color'
import { PrimaryButton } from "../../atoms/Button";
import { ReactComponent as ErrorIconRaw } from "../../../assets/error.svg";
import { inject, observer } from "mobx-react";
import { ApiKeyStore } from "../../../store/ApiKeyStore";
import { CopyableText } from "../../atoms/CopyableText";
import { useMedia } from 'react-media';
import { MEDIA_QUERIES } from '../../../constants/MediaQueries';
import ReactModalStyle from '../../../constants/ReactModalStyle';

const ErrorIcon = styled(ErrorIconRaw)({
    float: 'left',
    margin: 'auto 20px auto 0',
    height: '21px',
    width: '21px'
})

const Title = styled.h1({
    fontSize: '25px',
    color: '#FFFFFF',
    fontWeight: 'bold',
    letterSpacing: '0.28px',
    marginLeft: 0,
})

const Description = styled.p({
    color: '#FFFFFF',
    fontSize: '16px',
    letterSpacing: '0.18px',
    padding: '20px',
    border: Color.primaryRed,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '2px',
    marginTop: '25px',
})

const Table = styled.table({
    color: '#FFFFFF',
    margin: '40px 0px',
    borderCollapse: 'collapse',
    tableLayout: 'auto',
    width: '100%'
})

const ItemRow = styled.tr({
    borderTop: '1px solid #272F34',
    ':last-child': {
        borderBottom: '1px solid #272F34',
    },
    fontSize: '14px',
    color: '#FFFFFF',
    padding: '25px 0px'
})

const TableCell = styled.td({
    padding: '25px 0px',
    ':first-child': {
        minWidth: '55px'
    }
})

const Container = styled.div({
    margin: '80px 124px'
})

const ButtonWrapper = styled.div({
    display: 'flex',
    justifyContent: 'end',
})

const Button = styled(PrimaryButton)({
    width: '50%'
})


interface Props {
    isOpen: boolean
    onClose: () => void
    organizationId: string
    relyingPartyId: string
    apiKeyStore?: ApiKeyStore
}

export const AddApiKeyModalComponent = (props: Props) => {
    const isSmallScreen = useMedia({ query: MEDIA_QUERIES.tablet });

    return <ReactModal
        open={props.isOpen}
        onClose={props.onClose}
        styles={ReactModalStyle({isSmallScreen})}
        center={true}
        blockScroll
    >
        <Container>
            <Title>New API key created</Title>
            <Description>
                <ErrorIcon fill={Color.primaryRed}/>
                Please store the secret in
                a safe place. It cannot be retrieved after closing this dialog.
            </Description>
            <Table>
                <tbody>
                <ItemRow><TableCell>ID:</TableCell><TableCell><CopyableText
                    style={{width: '497px'}}>{props.apiKeyStore?.apiKey?.id ?? ''}</CopyableText></TableCell></ItemRow>
                <ItemRow><TableCell>Secret:</TableCell><TableCell><CopyableText
                    style={{width: '497px'}}>{props.apiKeyStore?.apiKey?.secret ?? ''}</CopyableText></TableCell></ItemRow>
                </tbody>
            </Table>
            <ButtonWrapper>
                <Button onClick={props.onClose}>Ok</Button>
            </ButtonWrapper>
        </Container>
    </ReactModal>
}

export const AddApiKeyModal = inject('apiKeyStore')(observer(AddApiKeyModalComponent))