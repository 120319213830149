import React, { useEffect, useState } from "react";
import ReactModal from 'react-responsive-modal'
import { useMedia } from 'react-media';
import styled from "styled-components";
import { TableContent, UpgradeTable } from "../atoms/UpgradeTable";
import { Price } from "../../models/Price";
import {
    GET_AUTHENTICATION_PRICES,
    GET_IDENTITY_PRICES,
    UPGRADE_AUTHENTICATION,
    UPGRADE_IDENTITY
} from "../../constants/Url";
import { toast } from "react-toastify";
import { PrimaryButton, SecondaryButton } from "../atoms/Button";
import { handleApiResponse, isApiError } from "../../helper/ResponseHelper";
import { MEDIA_QUERIES } from "../../constants/MediaQueries";
import ReactModalStyle from "../../constants/ReactModalStyle";

const Container = styled.div<{ isSmallDevice?: boolean }>`
  width: ${({ isSmallDevice }) => (isSmallDevice ? '800px' : 'auto')};
  margin: 48px 38px;
  color: #ffffff;
`;

export const CancelButton = styled(SecondaryButton)({
    color: '#E1E7EC',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    ':hover': {
        backgroundColor: 'transparent',
        borderColor: 'transparent'
    },
    width: '135px'
})

export const ButtonWrapper = styled.div({
    display: 'flex',
    flexDirection: 'row',
    marginRight: '15px'
})

export const ButtonWrapperWrapper = styled.div({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end',
})

interface Props {
    isOpen: boolean
    onClose: () => void
    organizationId: string
    relyingPartyId: string
    projectType: 'identity' | 'api'
}

export const UpgradeModal = (props: Props) => {
    const [prices, setPrices] = useState<Price[]>([])
    const isSmallScreen = useMedia({ query: MEDIA_QUERIES.tablet });

    const getPrices = async () => {
        const url = props.projectType === 'identity' ? GET_IDENTITY_PRICES : GET_AUTHENTICATION_PRICES
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            cache: 'no-cache',
            mode: 'cors'
        })
        const result = await handleApiResponse<Price[]>(response)
        if (!isApiError(result)) {
            setPrices(result)
        } else {
            toast.error("Internal server error")
            props.onClose()
        }
    }

    const startUpgrade = async () => {
        const url = props.projectType === 'identity' ? UPGRADE_IDENTITY(props.organizationId, props.relyingPartyId) : UPGRADE_AUTHENTICATION(props.organizationId, props.relyingPartyId)
        const response = await fetch(url + "?cancel_url=" + encodeURIComponent(window.location.toString()) + '&success_url=' + encodeURIComponent(window.location.toString() + '#success'), {
            method: 'POST',
            credentials: 'same-origin',
            cache: 'no-cache',
            mode: 'cors'
        })
        if (response.status === 201) {
            const redirectUrl = response.headers.get("location")
            window.location.assign(redirectUrl ?? "")
        } else {
            toast.error("Internal server error")
        }
    }

    useEffect(() => {
        getPrices()
    }, [])

    return (
      <ReactModal
        onClose={props.onClose}
        open={props.isOpen}
        styles={ReactModalStyle({isSmallScreen})}
        center={true}
        blockScroll
      >
        <Container isSmallDevice={isSmallScreen}>
          <UpgradeTable
            title={props.projectType === 'identity' ? 'Upgrade your Identity plan' : 'Upgrade your API plan'}
            content={props.projectType === 'identity' ? identityContent : authenticationContent}
            price={prices}
          />
          <div style={{ color: '#FFFFFF', fontSize: '12px', margin: '15px' }}>*Coming soon</div>
          <ButtonWrapperWrapper>
            <ButtonWrapper>
              <CancelButton onClick={props.onClose}>Cancel</CancelButton>
              <PrimaryButton style={{ width: '137px', marginLeft: '15px' }} onClick={startUpgrade}>
                Upgrade
              </PrimaryButton>
            </ButtonWrapper>
          </ButtonWrapperWrapper>
        </Container>
      </ReactModal>
    );
}

const authenticationContent: TableContent[] = [
    {
        title: "Passlink API",
        free: {
            included: true
        },
        pro: {
            included: true
        }
    },
    {
        title: "Passkey API (WebAuthn/FIDO2)",
        free: {
            included: true
        },
        pro: {
            included: true
        }
    },
    {
        title: "Passcode API (TOTP)*",
        free: {
            included: true
        },
        pro: {
            included: true
        }
    },
    {
        title: 'FIDO Push Auth API*',
        free: {
            included: true
        },
        pro: {
            included: true
        }
    },
    {
        title: "FIDO MDS with custom authenticators*",
        free: {
            included: true
        },
        pro: {
            included: true
        }
    },
    {
        title: "Passlink custom sender (SMTP/DKIM)*",
        free: {
            included: true
        },
        pro: {
            included: true
        }
    },
    {
        title: "",
        free: {},
        pro: {}
    },
    {
        title: "Passcode API (SMS, Messenger)*",
        free: {
            included: false
        },
        pro: {
            included: true
        }
    },
    {
        title: "Custom branded Authenticator App*",
        free: {
            included: false
        },
        pro: {
            included: true
        }
    }
]

const identityContent: TableContent[] = [
    {
        title: 'Custom branding',
        free: {
            included: true
        },
        pro: {
            included: true
        }
    },
    {
        title: 'Passlink authentication',
        free: {
            included: true
        },
        pro: {
            included: true
        }
    },
    {
        title: 'Passkey authentication (WebAuthn/FIDO2)',
        free: {
            included: true
        },
        pro: {
            included: true
        }
    },
    {
        title: 'FIDO Security Keys*',
        free: {
            included: true
        },
        pro: {
            included: true
        }
    },
    {
        title: 'Passcode 2FA (TOTP)*',
        free: {
            included: true
        },
        pro: {
            included: true
        }
    },
    {
        title: '3rd party logins*',
        free: {
            included: true
        },
        pro: {
            included: true
        }
    },
    {
        title: '',
        free: {},
        pro: {}
    },
    {
        title: 'Custom domain',
        free: {
            included: false
        },
        pro: {
            included: true
        }
    },
    {
        title: 'Passcode 2FA (SMS, Messenger)*',
        free: {
            included: false
        },
        pro: {
            included: true
        }
    },
    {
        title: 'Pre-integrated KYC providers*',
        free: {
            included: false
        },
        pro: {
            included: true
        }
    },
    {
        title: 'Mandatory 2FA*',
        free: {
            included: false
        },
        pro: {
            included: true
        }
    }
]
