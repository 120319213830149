import { observable, action } from 'mobx'
import {RELYING_PARTY_URL} from '../constants/Url';
import RequestTimeout from '../helper/RequestTimeout';
import { ApiError, handleApiResponse, isApiError } from '../helper/ResponseHelper'
import {PasslinkRedirectUrlModel} from "../models/PasslinkRedirectUrlModel";

export class PasslinkRedirectUrlStore {

    @observable passlinkRedirectUrl?: PasslinkRedirectUrlModel = undefined;
    @observable passlinkRedirectUrlList?: PasslinkRedirectUrlModel[] = undefined;

    @action
    create = async (organizationId: string, relyingPartyId: string, passlink: PasslinkRedirectUrlModel) => {
        const response = await RequestTimeout(RELYING_PARTY_URL(organizationId, relyingPartyId) + "/passlink/redirect_urls", {
            method: 'PUT',
            credentials: 'same-origin',
            cache: 'no-cache',
            mode: 'cors',
            body: JSON.stringify(passlink),
        });
        return handleApiResponse<PasslinkRedirectUrlModel>(response).then(this.success).catch(this.error)
    };

    @action
    delete = async (organizationId: string, relyingPartyId: string, passlink: PasslinkRedirectUrlModel) => {
        const response = await RequestTimeout(RELYING_PARTY_URL(organizationId, relyingPartyId) + "/passlink/redirect_urls", {
            method: 'DELETE',
            credentials: 'same-origin',
            cache: 'no-cache',
            mode: 'cors',
            body: JSON.stringify(passlink),
        });
        return handleApiResponse<PasslinkRedirectUrlModel>(response).then(this.success).catch(this.error)
    };

    @action
    list = async (organizationId: string, relyingPartyId: string) => {
        const response = await RequestTimeout(RELYING_PARTY_URL(organizationId, relyingPartyId) + "/passlink/redirect_urls", {
            method: 'GET',
            credentials: 'same-origin',
            cache: 'no-cache',
            mode: 'cors'
        });
        return handleApiResponse<PasslinkRedirectUrlModel[]>(response).then(this.listSuccess).catch(this.error)
    };

    @action.bound
    success = (result: PasslinkRedirectUrlModel | ApiError) => {
        if (!isApiError(result)) {
            this.passlinkRedirectUrl = result
        }
        return result
    };

    @action.bound
    listSuccess = (result: PasslinkRedirectUrlModel[] | ApiError) => {
        if (!isApiError(result)) {
            this.passlinkRedirectUrlList = result
        }
        return result
    };

    @action.bound
    error = (reason: any): ApiError => {
        return {
            code: 0,
            message: 'Could not get fetch.'
        }
    }
}

export const passlinkRedirectUrlStore = new PasslinkRedirectUrlStore();
