import React, { useState } from 'react'
import styled from 'styled-components'
import ReactModal from 'react-responsive-modal'
import { PrimaryButton, SecondaryButton } from "../../atoms/Button";
import { isApiError } from "../../../helper/ResponseHelper";
import { toast } from 'react-toastify'
import { FormList, FormListItem } from "../../atoms/List";
import { inject, observer } from "mobx-react";
import { BACKEND_LOGIN_REDIRECT_URL } from "../../../constants/Url";
import { useCurrentRoute } from "react-navi";
import { PasslinkRedirectUrlStore } from "../../../store/PasslinkRedirectUrlStore";
import { PasslinkRedirectUrlModel } from "../../../models/PasslinkRedirectUrlModel";
import { RelyingPartyStore } from "../../../store/RelyingPartyStore";
import { OrganizationStore } from "../../../store/OrganizationStore";
import { URLText } from "./PasslinkSettings";
import { useMedia } from 'react-media';
import { MEDIA_QUERIES } from '../../../constants/MediaQueries';
import ReactModalStyle from '../../../constants/ReactModalStyle';

const Title = styled.h1({
    fontSize: '25px',
    color: '#FFFFFF',
    fontWeight: 'bold',
    letterSpacing: '0.28px'
})

const Description = styled.p({
    color: '#FFFFFF',
    fontSize: '16px',
    fontWeight: 500,
    letterSpacing: '0.18px',
    marginTop: '29px',
})

const Container = styled.div({
    margin: '80px 128px',
    color: '#FFFFFF'
})

export const CancelButton = styled(SecondaryButton)({
    color: '#E1E7EC',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    ':hover': {
        backgroundColor: 'transparent',
        borderColor: 'transparent'
    },
    width: '50%'
})

export const ButtonWrapper = styled.div({
    display: 'flex',
    flexDirection: 'row',
    width: '70%',
})

export const ButtonWrapperWrapper = styled.div({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end',
})

export const Checkbox = styled.input({
    height: '20px',
    width: '20px'
})

export const CheckboxLabel = styled.label({
    fontSize: '16px',
    marginLeft: '8px'
})

export const CheckboxWrapper = styled.div({
    display: 'flex',
    alignItems: 'center'
})

export const CheckboxListItem = styled(FormListItem)({
    margin: '20px 0 48px 0'
})

interface Props {
    isOpen: boolean
    onClose: () => void
    redirectUrl: PasslinkRedirectUrlModel
    relyingPartyStore?: RelyingPartyStore
    organizationStore?: OrganizationStore
    passlinkRedirectUrlStore?: PasslinkRedirectUrlStore
}

const DeletePasslinkRedirectUrlModalComponent = (props: Props) => {
    const [deletionConfirmed, setDeletionConfirmed] = useState(false)
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const route = useCurrentRoute();
    const isSmallScreen = useMedia({ query: MEDIA_QUERIES.tablet });
    const deleteRedirectUrl = async () => {
        setIsDeleteLoading(true);
        const {organizationStore, relyingPartyStore} = props
        const relyingPartyId = relyingPartyStore?.relyingParty?.id || ""
        const organizationId = organizationStore?.organization?.id || ""
        const result = await props.passlinkRedirectUrlStore?.delete(organizationId, relyingPartyId, props.redirectUrl)
        if (!isApiError(result)) {
            props.onClose()
            toast.success("Redirect URL deleted")
        } else if (result.code === 401) {
            window.location.assign(BACKEND_LOGIN_REDIRECT_URL(route.url.href))
        } else {
            toast.error("Redirect URL could not be deleted")
        }

        setIsDeleteLoading(false);
    }

    const onClose = () => {
        setDeletionConfirmed(false)
        props.onClose()
    }

    const {isOpen} = props
    return <ReactModal open={isOpen} onClose={onClose} styles={ReactModalStyle({isSmallScreen, customStyles: {width: 'auto'}})} center={true} blockScroll>
        <Container>
            <Title>Delete Redirect URL</Title>
            <Description>
                Are you sure you want to delete the Redirect URL <URLText>{props.redirectUrl.url}</URLText>?
            </Description>
            <FormList style={{margin: 0}}>
                <CheckboxListItem>
                    <CheckboxWrapper>
                        <Checkbox type="checkbox" id="delete_confirm" checked={deletionConfirmed} onChange={() => {
                            setDeletionConfirmed(!deletionConfirmed)
                        }}/>
                        <CheckboxLabel htmlFor="delete_confirm">Yes I'm sure, delete Redirect URL</CheckboxLabel>
                    </CheckboxWrapper>
                </CheckboxListItem>
                <FormListItem>
                    <ButtonWrapperWrapper>
                        <ButtonWrapper>
                            <CancelButton type="button" onClick={onClose}>Cancel</CancelButton>
                            <PrimaryButton type="submit" onClick={deleteRedirectUrl}
                                isLoading={isDeleteLoading}
                                disabled={!deletionConfirmed}>
                                Delete
                            </PrimaryButton>
                        </ButtonWrapper>
                    </ButtonWrapperWrapper>
                </FormListItem>
            </FormList>
        </Container>
    </ReactModal>
}

export const DeletePasslinkRedirectUrlModal = inject('relyingPartyStore', 'organizationStore', 'passlinkRedirectUrlStore')(observer(DeletePasslinkRedirectUrlModalComponent))
