import styled from 'styled-components'

export const FormList = styled.ul({
    padding: 0,
    listStyle: 'none',
    margin: '25px'
})

export const FormListItem = styled.li({
    marginBottom: '35px',
    ":last-child": {
        marginBottom: 0
    }
})
