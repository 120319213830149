import React from 'react';
import styled from 'styled-components';
import { ReactComponent as InsertPhotoIcon } from '../../assets/insert_photo.svg';

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 110px;
  margin: 0 auto;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 50%;
  box-shadow: 0px 9px 28px rgba(0, 0, 0, 0.25);
  overflow: hidden;
`;

const Image = styled.img`
  width: 110px;
  height: 110px;
  max-width: calc(100% - 20px);
  max-height: calc(100% - 20px);
  object-fit: contain;
`;

type WrapperProps = {
    backgroundColor: string;
};

interface RelyingPartyImageProps {
    backgroundColor: string;
    src?: string;
}

export const RelyingPartyImage = (props: RelyingPartyImageProps) => {
    const { backgroundColor, src } = props;
    const randomNumber = Math.floor(Math.random() * 99999);
    let url = ''
    if (src !== undefined && src.startsWith("data:")) {
        url = src
    } else {
        url = src + '?cache=none&rand=' + randomNumber
    }

    return (
        <Wrapper backgroundColor={backgroundColor}>
            {src !== undefined && src !== '' ? (
                <Image src={url} alt="Relying Party Logo" />
            ) : (
                <InsertPhotoIcon fill={'#FFF'} style={{ width: '100%', height: 'auto', margin: '0 auto 0 auto' }} />
            )}
        </Wrapper>
    );
};
