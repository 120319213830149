import React, { CSSProperties, useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as MoreHorizontalIcon } from '../../assets/more_horiz.svg';
import { DeleteRelyingPartyModal } from '../relying_party/settings/DeleteRelyingPartyModal';
import useOnClickOutside from '../../helper/hooks/UseOnClickOutside';

const DropDownContainer = styled.div({
  position: 'absolute',
  right: '15px',
  top: '18px',
  cursor: 'pointer',
  display: 'inline-flex',
  zIndex: 2,
});

const MenuContainer = styled.div({
  position: 'relative',
  top: '32px',
  right: '272px',
  zIndex: 20,
});

const Menu = styled.div`
  min-width: 258px;
  position: absolute;
  border-radius: 4px;
  background-color: #545b61;
  overflow: hidden;
  padding: 5px;
  cursor: auto;
  filter: drop-shadow(0px 4px 29px rgba(0, 0, 0, 0.25));
`;

const MenuItem = styled.div({
  fontSize: '16px',
  fontWeight: 500,
  color: '#FFFFFF',
  borderRadius: '4px',
  padding: '29px',
  cursor: 'pointer',
  ':first-child': {
    borderBottomRightRadius: '0',
    borderBottomLeftRadius: '0',
    borderTop: 'none',
  },
  ':last-child': {
    borderTopRightRadius: '0',
    borderTopLeftRadius: '0',
    borderTop: 'none',
  },
  ':not(:first-child)': {
    borderTop: '1px solid #3e464c',
  },
  ':hover': {
    backgroundColor: '#303940',
  },
});

interface Props {
  style?: CSSProperties;
  projectType: 'identity' | 'api';
}

export const RelyingPartyContextMenu = (props: Props) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleClickOutside = () => {
    setIsOpen(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  return (
    <DropDownContainer style={props.style} ref={ref}>
      <div
        style={{ display: 'inline-flex' }}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <MoreHorizontalIcon style={{ fill: '#BBC4CC', height: '34px', width: '34px' }} />
      </div>
      {isOpen ? (
        <>
          <MenuContainer>
            <Menu>
              <MenuItem
                onClick={() => {
                  window.location.assign(window.location.href.replace('/dashboard', '/brand'));
                }}
              >
                Edit Name
              </MenuItem>
              <MenuItem
                style={{ color: '#FF2E4C' }}
                onClick={() => {
                  setIsOpen(false);
                  setIsModalOpen(true);
                }}
              >
                Delete Project
              </MenuItem>
            </Menu>
          </MenuContainer>
        </>
      ) : null}
      <DeleteRelyingPartyModal isOpen={isModalOpen} onClose={closeModal} projectType={props.projectType} />
    </DropDownContainer>
  );
};
