import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { OrganizationStore } from '../../store/OrganizationStore';
import { AppStateStore } from '../../store/AppStateStore';
import { RelyingPartyStore } from '../../store/RelyingPartyStore';
import { UserStore } from '../../store/UserStore';

import { ChooseRelyingPartyBox } from './ChooseRelyingPartyBox';
import { AddRelyingPartyBox } from './AddRelyingPartyBox';
import { inject, observer } from 'mobx-react';
import { useCurrentRoute } from 'react-navi';
import { isApiError } from '../../helper/ResponseHelper';
import { BACKEND_LOGIN_REDIRECT_URL } from '../../constants/Url';
import { Menu } from '../molecules/Menu';
import { menu } from '../organization/OrganizationSettings';
import { useMedia } from "react-media";
import { MEDIA_QUERIES } from "../../constants/MediaQueries";
import { ProjectCreationDiscontinuedModal } from "../molecules/ProjectCreationDiscontinuedModal";

const Container = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin-left: ${({isMobile}) => (isMobile ? '' : 'calc(24% + 12px)')};
  justify-content: ${({isMobile}) => (isMobile ? 'center' : '')};

  @supports (gap: 24px) {
    gap: 24px;
  }
}`;

type State = {
  isModalOpen: boolean;
  relyingPartiesLoaded: boolean;
};

interface Props {
  relyingPartyStore?: RelyingPartyStore;
  organizationStore?: OrganizationStore;
  organizationId: string;
  userStore?: UserStore;
  appStateStore?: AppStateStore;
}

const ChooseRelyingPartyComponent = (props: Props) => {
  const route = useCurrentRoute();
  const isMobile = useMedia({query: MEDIA_QUERIES.tablet});
  const [state, setState] = useState<State>({
    isModalOpen: false,
    relyingPartiesLoaded: false,
  });
  const organizationId = props.organizationStore?.organization?.id ?? '';

  const setUserActiveOrganization = async () => {
    props.userStore?.setActiveOrganizationId(organizationId);
    const result = await props.userStore?.update();
    if (isApiError(result) && result.code === 401) {
      window.location.assign(BACKEND_LOGIN_REDIRECT_URL(route.url.href));
    }
  };

  const openModal = () => {
    setState({
      ...state,
      isModalOpen: true,
    });
  };

  const closeModal = (rpId?: string) => {
    setState({
      ...state,
      isModalOpen: false,
    });

    // if (rpId != undefined) {
    //   window.location.assign(window.location.pathname + '/' + rpId + '/dashboard');
    // }

    props.organizationStore?.listRelyingParties(props.organizationStore?.organization?.id ?? '');
  };

  useEffect(() => {
    const contentContainer = document.getElementById('contentContainer');
    if (contentContainer && isMobile) {
      contentContainer.style['minWidth'] = '';
    }
    return function () {
      if (contentContainer) {
        contentContainer.style['minWidth'] = '1024px';
      }
    }
  }, [isMobile]);

  useEffect(() => {
    setUserActiveOrganization();
  });

  useEffect(() => {
    if (!props.organizationStore?.relyingParties || props.organizationStore?.relyingParties.length <= 0) {
      openModal();
    }
  }, []);

  if (props.organizationStore?.organization) {
    props.appStateStore?.setBreadcrumbs([
      {
        title: props.organizationStore.organization.name,
        link: '/organization/' + props.organizationStore.organization.id + '/relyingParty',
      },
    ]);
  }

  return (
    <>
      <Menu menuItems={menu(organizationId)} isProjectMenu/>
      <Container isMobile={isMobile}>
        {props.organizationStore?.relyingParties?.map((relyingParty, index) => (
          <ChooseRelyingPartyBox key={index} relyingParty={relyingParty} organizationId={props.organizationId}/>
        ))}
        <AddRelyingPartyBox onClick={openModal}/>
      </Container>
      <ProjectCreationDiscontinuedModal isOpen={state.isModalOpen} onClose={closeModal}/>
      {/*<AddRelyingPartyModal*/}
      {/*  orgaId={props.organizationStore?.organization?.id || props?.organizationId || ''}*/}
      {/*  isOpen={state.isModalOpen}*/}
      {/*  onClose={closeModal}*/}
      {/*/>*/}
    </>
  );
};

export const ChooseRelyingParty = inject(
  'organizationStore',
  'userStore',
  'appStateStore'
)(observer(ChooseRelyingPartyComponent));
