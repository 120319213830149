import { action, observable } from 'mobx';
import { BreadcrumbModel } from '../models/BreadcrumbModel';

export class AppStateStore {
  @observable breadcrumbs: BreadcrumbModel[] = [];
  @observable isProjectTypeLabelVisible: boolean = false;
  @observable isSidebarMenuVisible: boolean = false;

  @action
  setBreadcrumbs = (breadcrumbs: BreadcrumbModel[]) => {
    this.breadcrumbs = breadcrumbs;
  };

  @action
  setProjectTypeLabelVisibility = (isVisible: boolean) => {
    this.isProjectTypeLabelVisible = isVisible;
  };

  @action
  toggleSidebarMenu = () => {
    this.isSidebarMenuVisible = !this.isSidebarMenuVisible;
  };
}

export const appStateStore = new AppStateStore();
