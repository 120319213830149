import React, {useEffect} from 'react';
import styled, {keyframes} from 'styled-components';
import {Color} from '../atoms/Color';
import {SettingsDescriptionLink} from "../atoms/Link";

const Container = styled.div({
    display: 'flex',
    alignItems: 'center',
});

const StatusText = styled.span({
    fontSize: '14px',
    fontWeight: 500,
    marginLeft: '13px',
});

const ReadyIcon = styled.span({
    height: '22px',
    width: '22px',
    borderRadius: '50%',
    border: '3px solid #50C9BA',
    boxSizing: 'border-box',
});

const SuspendedIcon = styled.span({
    height: '22px',
    width: '22px',
    borderRadius: '50%',
    border: `3px solid ${Color.primaryRed}`,
    boxSizing: 'border-box',
})

const keyframe = keyframes`
from {
    transform: rotate(0deg);
}
to {
    transform: rotate(359deg)
}
`;
const LoadingWheel = styled.div`
  display: block;
  height: 22px;
  width: 22px;
  border: 3px rgba(0, 0, 0, 0.25) solid;
  border-top: 3px ${Color.primaryRed} solid;
  border-right: 3px ${Color.primaryRed} solid;
  border-bottom: 3px ${Color.primaryRed} solid;
  border-radius: 50%;
  animation: ${keyframe} 1s infinite linear;
  box-sizing: border-box;
`;

interface Props {
    updateTenantStatus: () => void;
    identityTenantStatus?: {
        available: boolean;
        oauth_available: boolean;
    };
    apiTenantStatus?: {
        available_replicas: number;
        ready_replicas: number;
        replicas: number;
    };
    isProjectIdentity: boolean;
}

var intervalId: NodeJS.Timeout | undefined = undefined;

const TenantStatus = (props: Props) => {
    useEffect(() => {
        if (!props.identityTenantStatus?.available && !props.apiTenantStatus?.ready_replicas) {
            if (intervalId === undefined) {
                intervalId = setInterval(() => {
                    props.updateTenantStatus();
                }, 5000);
            }
        }
    }, [props.identityTenantStatus, props.apiTenantStatus]);

    useEffect(() => {
        return () => {
            if (intervalId !== undefined) {
                clearInterval(intervalId);
                intervalId = undefined;
            }
        };
    }, []);

    let status = props.isProjectIdentity
        ? <StatusText>Hanko Identity is being discontinued. Please use our new hanko cloud console at<a
        style={{color: 'white'}} href="https://cloud.hanko.io">https://cloud.hanko.io</a></StatusText>
        : <StatusText>Your API is being set up, this will take a few seconds...</StatusText>;
    let statusIcon = <SuspendedIcon/>;

    if (
        props.identityTenantStatus?.available ||
        props.identityTenantStatus?.oauth_available ||
        props.apiTenantStatus?.ready_replicas
    ) {
        status = props.isProjectIdentity ?
            <StatusText>Your Identity project is ready to use</StatusText> : <StatusText>Your API is ready to use</StatusText>;
        statusIcon = <ReadyIcon/>;
    } else if (props.apiTenantStatus?.replicas === 0) {
        status =
            <StatusText>Your Project has been suspended due to inactivity. If you would like to get it back running <SettingsDescriptionLink
                href="mailto:admin@hanko.io"> contact us.</SettingsDescriptionLink></StatusText>
        statusIcon = <SuspendedIcon/>
    }

    return (
        <Container>
            {statusIcon}
            {status}
        </Container>
    );
};

TenantStatus.defaultProps = {
    isProjectIdentity: false,
};

export {TenantStatus};
