import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NoStylingButton } from './Button';
import { Color } from './Color';
import { ReactComponent as CopyIcon } from '../../assets/file_copy.svg';

const CopyButton = styled(NoStylingButton)`
  position: relative;
  display: flex;
`;

const CopySvg = styled(CopyIcon)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }: { size: Props['size'] }) => size ?? `23px`};

  &:hover {
    fill: #ffffff;
  }
`;

const Message = styled.div`
  position: absolute;
  z-index: 10;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  padding: 3px 10px;
  background: ${Color.iconGrey};
  color: #ffffff;
  font-weight: 500;
`;

type Props = {
  fill?: string;
  value: string;
  size?: string;
};

const CopyToClipboardButton = ({ fill, value, size }: Props) => {
  const MESSAGE_TIMER = 1500;
  const [isMessageActive, setIsMessageActive] = useState(false);

  const handleCopy = () => navigator.clipboard.writeText(value).then(() => setIsMessageActive(true));

  useEffect(() => {
    if (isMessageActive) {
      setTimeout(() => {
        setIsMessageActive(false);
      }, MESSAGE_TIMER);
    }
  }, [isMessageActive]);

  return (
    <CopyButton onClick={handleCopy}>
      <CopySvg size={size} fill={fill || '#BBC4CC'} />
      {isMessageActive ? <Message>Copied</Message> : null}
    </CopyButton>
  );
};

CopyToClipboardButton.defaultProps = {
  value: '',
};

export default CopyToClipboardButton;
