import React from 'react'
import styled from 'styled-components'

import { ReactComponent as AddIcon } from '../../assets/add.svg'
import { Color } from "../atoms/Color";

const Container = styled.div({
    padding: '30px',
    backgroundColor: Color.darkBlue,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '5px',
    width: '221px',
    cursor: 'pointer'
})
const ContentContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto'
})

const Text = styled.span({
    margin: '5px auto 0 auto',
    color: '#FFFFFF',
    fontSize: '16px',
    letterSpacing: 0,
    fontWeight: 500
})

interface Props {
    onClick: () => void
}

export const AddRelyingPartyBox = (props: Props) => {
    return <Container onClick={props.onClick}>
        <ContentContainer>
            <AddIcon height="120px" width="120px" fill="#BBC4CC" style={{
                margin: '0 auto'
            }}/>
            <Text>Create new project</Text>
        </ContentContainer>
    </Container>
}
