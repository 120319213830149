import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ExclamationIcon } from '../../assets/error.svg';
import { ReactComponent as InfoIcon } from '../../assets/info.svg';
import { Color } from './Color';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 12px 20px;
  border-radius: 2px;
`;

const Icon = styled.span`
  display: flex;
  margin-right: 20px;
`;

const Text = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

type Props = {
  children: React.ReactElement | string;
  customStyles?: {};
  color?: string;
  type: 'error' | 'warning' | 'success';
};

const AlertBox = ({ children, color, type }: Props) => {
  const renderIcon = (type: Props['type']) => {
    switch (type) {
      case 'error':
        return <ExclamationIcon fill={color || Color.primaryRed} />;
      case 'success':
        return <InfoIcon fill={color || Color.primaryRed} />;

      default:
        return 'exclamationFilled';
    }
  };

  return (
    <Wrapper
      style={{
        backgroundColor: type === 'success' ? `${Color.primaryGreen}11` : `${Color.intenseRed}11`,
        color: color ? color : type === 'success' ? Color.primaryGreen : Color.intenseRed,
      }}
    >
      <Icon>{renderIcon(type)}</Icon>
      <Text>{children}</Text>
    </Wrapper>
  );
};

export default AlertBox;
