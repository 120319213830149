import React from "react";
import { WebauthnTimeoutSettings } from "./WebauthnTimeoutSettings";
import { WebauthnSettings } from "./WebauthnSettings";
import {WebauthnDomainSettings} from "./WebauthnDomainSettings"
import {WebauthnMultipleOriginsSettings} from './WebauthnMultipleOriginsSettings'

export const WebauthnOverviewSettings = () => {
    return <div id="webauthn_settings_wrapper">
        <WebauthnDomainSettings/>
        <WebauthnMultipleOriginsSettings/>
        <WebauthnSettings/>
        <WebauthnTimeoutSettings/>
    </div>
}