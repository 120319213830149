import React from 'react'
import styled from "styled-components";

const ItemRow = styled.tr({
    borderTop: '1px solid #272F34',
    ':last-child': {
        borderBottom: '1px solid #272F34'
    },
    fontSize: '14px',
    color: '#FFFFFF',
    padding: '25px 0'
})

const TableCell = styled.td({
    padding: '25px 0',
    ':nth-child(2)': {
        width: '50px',
        textAlign: 'end',
    },
})

const Origin = styled.span({
    display: 'block',
    wordBreak: 'break-all',
    fontSize: '14px',
    fontFamily: 'IBM Plex Mono, monospace',
    maxWidth: '685px'
})

const Link = styled.a({
    color: '#FFFFFF',
    textDecoration: 'underline',
    fontSize: '14px',
    fontWeight: 500,
})

interface Props {
    item: string,
    openRemoveModal: (origin: string) => void
}

export const WebauthnOriginTableItem = (props: Props) => {

    const openRemoveModal = () => {
        props.openRemoveModal(props.item)
    }

    return <ItemRow>
        <TableCell><Origin>{props.item}</Origin></TableCell>
        <TableCell><Link href="#" onClick={openRemoveModal}>Delete</Link></TableCell>
    </ItemRow>
}


