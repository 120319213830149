import { action, observable } from 'mobx';
import moment from 'moment';

import {
  GET_IDENTITY_ACTIVE_USERS_PER_DAY,
  GET_IDENTITY_REQUESTS_PER_DAY,
  GET_IDENTITY_USER_COUNTS,
  GET_RELYING_PARTY_ACTIVE_USERS_PER_DAY,
  GET_RELYING_PARTY_REQUESTS_PER_DAY,
  GET_RELYING_PARTY_USER_COUNTS,
} from '../constants/Url';
import RequestTimeout from '../helper/RequestTimeout';
import { ApiError, handleApiResponse, isApiError } from '../helper/ResponseHelper';
import { ActiveUserCount, ActiveUsersCountSeriesEntry, RequestCountSeriesEntry } from '../models/LoginStatistic';

export class StatisticsStore {
  @observable currentMonthActiveUsers?: ActiveUserCount = undefined;
  @observable lastMonthActiveUsers?: ActiveUserCount = undefined;

  @observable activeUsersPerDay?: ActiveUsersCountSeriesEntry[] = undefined;
  @observable requestsPerDay?: RequestCountSeriesEntry[] = undefined;

  @action
  setStatsToZero = () => {
    if (this.currentMonthActiveUsers != undefined) {
      this.currentMonthActiveUsers.activeUsers = 0;
    }
    if (this.lastMonthActiveUsers != undefined) {
      this.lastMonthActiveUsers.activeUsers = 0;
    }

    this.activeUsersPerDay = this.activeUsersPerDay?.map((entry) => {
      let n = entry;
      n.activeUsers = 0;
      return n;
    });

    this.requestsPerDay = this.requestsPerDay?.map((entry) => {
      let n = entry;
      n.requestsCount = 0;
      return n;
    });
  };

  @action
  getActiveUserPerDay = async (
    projectType: 'api' | 'identity',
    orgaId: string,
    rpId: string,
    startTime: string,
    endTime: string
  ) => {
    const apiEndpoint =
      projectType === 'api'
        ? GET_RELYING_PARTY_ACTIVE_USERS_PER_DAY(orgaId, rpId)
        : GET_IDENTITY_ACTIVE_USERS_PER_DAY(orgaId, rpId);

    const response = await RequestTimeout(`${apiEndpoint}?start_time=${startTime}&end_time=${endTime}&operation=AUTH`, {
      method: 'GET',
      credentials: 'same-origin',
    });

    return handleApiResponse<ActiveUsersCountSeriesEntry[]>(response)
      .then(this.getActiveUsersPerDaySuccess)
      .catch(this.getActiveUsersPerDayError);
  };

  @action.bound
  getActiveUsersPerDaySuccess = (result: ActiveUsersCountSeriesEntry[] | ApiError) => {
    if (!isApiError(result)) {
      this.activeUsersPerDay = result;
    } else {
      this.activeUsersPerDay = this.activeUsersPerDay?.map((entry) => {
        let newEntry = entry;
        newEntry.activeUsers = 0;
        return newEntry;
      });
    }

    return result;
  };

  @action.bound
  getActiveUsersPerDayError = (reason: any): ApiError => {
    this.activeUsersPerDay = this.activeUsersPerDay?.map((entry) => {
      let newEntry = entry;
      newEntry.activeUsers = 0;
      return newEntry;
    });
    return {
      code: 0,
      message: 'Could not get fetch.',
    };
  };

  @action
  getRequestsPerDay = async (
    projectType: 'api' | 'identity',
    orgaId: string,
    rpId: string,
    startTime: string,
    endTime: string
  ) => {
    const apiEndpoint =
      projectType === 'api'
        ? GET_RELYING_PARTY_REQUESTS_PER_DAY(orgaId, rpId)
        : GET_IDENTITY_REQUESTS_PER_DAY(orgaId, rpId);

    const response = await RequestTimeout(
      apiEndpoint + '?start_time=' + startTime + '&end_time=' + endTime + '&operation=AUTH',
      {
        method: 'GET',
        credentials: 'same-origin',
      }
    );

    return handleApiResponse<RequestCountSeriesEntry[]>(response)
      .then(this.getRequestsPerDaySuccess)
      .catch(this.getRequestsPerDayError);
  };

  @action.bound
  getRequestsPerDaySuccess = (result: RequestCountSeriesEntry[] | ApiError) => {
    if (!isApiError(result)) {
      this.requestsPerDay = result;
    } else {
      this.requestsPerDay = this.requestsPerDay?.map((entry) => {
        let newEntry = entry;
        newEntry.requestsCount = 0;
        return newEntry;
      });
    }

    return result;
  };

  @action.bound
  getRequestsPerDayError = (reason: any): ApiError => {
    this.requestsPerDay = this.requestsPerDay?.map((entry) => {
      let newEntry = entry;
      newEntry.requestsCount = 0;
      return newEntry;
    });
    return {
      code: 0,
      message: 'Could not get fetch.',
    };
  };

  @action
  getCurrentMonthActiveUsers = async (projectType: 'api' | 'identity', orgaId: string, rpId: string) => {
    const startTime = moment.utc().startOf('month').toISOString();
    const endTime = moment.utc().endOf('month').toISOString();
    const query = '?start_time=' + startTime + '&end_time=' + endTime;
    const url =
      projectType === 'api'
        ? GET_RELYING_PARTY_USER_COUNTS(orgaId, rpId) + query
        : GET_IDENTITY_USER_COUNTS(orgaId, rpId) + query;
    const response = await RequestTimeout(url, {
      method: 'GET',
      credentials: 'same-origin',
    });
    return handleApiResponse<ActiveUserCount>(response).then(this.getCurrentMonthActiveUsersSuccess).catch(this.error);
  };

  @action.bound
  getCurrentMonthActiveUsersSuccess = (result: ActiveUserCount | ApiError) => {
    if (!isApiError(result)) {
      this.currentMonthActiveUsers = result;
    }
    return result;
  };

  @action
  getLastMonthActiveUsers = async (projectType: 'api' | 'identity', orgaId: string, rpId: string) => {
    const lastMonth = moment.utc().subtract(1, 'month');
    const startTime = lastMonth.startOf('month').toISOString();
    const endTime = lastMonth.endOf('month').toISOString();
    const query = '?start_time=' + startTime + '&end_time=' + endTime;
    const url =
      projectType === 'api'
        ? GET_RELYING_PARTY_USER_COUNTS(orgaId, rpId) + query
        : GET_IDENTITY_USER_COUNTS(orgaId, rpId) + query;
    const response = await RequestTimeout(url, {
      method: 'GET',
      credentials: 'same-origin',
    });
    return handleApiResponse<ActiveUserCount>(response).then(this.getLastMonthActiveUsersSuccess).catch(this.error);
  };

  @action.bound
  getLastMonthActiveUsersSuccess = (result: ActiveUserCount | ApiError) => {
    if (!isApiError(result)) {
      this.lastMonthActiveUsers = result;
    }
    return result;
  };

  @action.bound
  error = (reason: any): ApiError => {
    return {
      code: 0,
      message: 'Could not get fetch.',
    };
  };
}

export const statisticsStore = new StatisticsStore();
