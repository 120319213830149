import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';

type RangeProps = {
  customStyles?: any;
  defaultValue?: number;
  end: number;
  increments?: number;
  label: string;
  onChange: (val: number) => void;
  value?: number;
  start: number;
};

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
`;

const RangeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 185px;
  padding: 14px 16px 14px 10px;
  box-sizing: border-box;
  background-color: #272f34;
  border-radius: 2px;
  color: #ffffff;
  font-size: 14px;
`;

const RangeValue = styled.span`
  margin-left: 10px;
`;

const RangeElement = styled.input`
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: #818a93;
  height: 4px;
  border-radius: 4px;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 12px;
    height: 12px;
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
  }

  &:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  &::-ms-track {
    width: 100%;
    height: 4px;
    background: #818a93;
    border-color: transparent;
    border-radius: 4px;
    color: transparent;
    cursor: pointer;
  }
`;

const Range = ({ customStyles, defaultValue, end, increments, label, onChange, start, value }: RangeProps) => {
  const [rangeValue, setRangeValue] = useState(defaultValue || start);

  const labelConverter = (val: string) => val.toLowerCase().split(' ').join('_');

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);

    setRangeValue(value);
    onChange(value);
  };

  return (
    <div style={customStyles}>
      <Label htmlFor={labelConverter(label)}>{label}</Label>
      <RangeWrapper>
        <RangeElement
          type="range"
          name={labelConverter(label)}
          min={start}
          max={end}
          step={increments}
          defaultValue={defaultValue}
          value={value}
          onChange={handleOnChange}
        />
        <RangeValue>{value || rangeValue}</RangeValue>
      </RangeWrapper>
    </div>
  );
};

export default Range;
