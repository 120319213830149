import React from "react";
import styled from "styled-components";
import { FreeBadge, ProBadge } from "./SubscriptionBadge";
import { ReactComponent as CheckIconRaw } from "../../assets/check_circle.svg";
import { Price, PriceTier } from "../../models/Price";

const CheckIcon = styled(CheckIconRaw)({
    fill: '#50C9BA',
    width: '17px',
    height: '17px',
})

const NotIncluded = styled.span({
    display: 'inline-block',
    width: '9px',
    height: '0px',
    border: '1px solid #818A93'
})

const Column = styled.col({
    backgroundColor: '#545B61',
    borderRadius: '5px',
})

const TableCell = styled.td({
    fontSize: '16px',
    fontWeight: 500,
    padding: '8px 28px',
    ':not(:first-child)': {
        width: '135px',
        textAlign: 'center',
        fontSize: '13px',
        padding: 0,
    },
    ':last-child': {
        borderLeft: '2px solid #50C9BA',
        borderRight: '2px solid #50C9BA',
    }
})

const FirstTableCell = styled(TableCell)({
    borderRadius: '5px 5px 0 0',
    padding: '20px 28px 8px 28px',
    ':not(:first-child)': {
        padding: '20px 0 0 0'
    },
    ':last-child': {
        border: '2px solid #50C9BA',
        borderBottom: '0px'
    }
})

const LastTableCell = styled(TableCell)({
    borderRadius: '0 0 5px 5px',
    padding: '8px 28px 40px 28px',
    ':not(:first-child)': {
        padding: '0 0 40px 0'
    },
    ':last-child': {
        border: '2px solid #50C9BA',
        borderTop: '0px'
    }
})

const PriceTableCell = styled(TableCell)({
    paddingTop: '22px !important',
    paddingBottom: '13px !important',
    fontSize: '27px !important'
})

const HintText = styled.span({
    fontSize: '12px',
    color: '#BBC4CC'
})

export type Content = {
    included?: boolean,
    text?: string
}

export type TableContent = {
    title: string,
    free: Content
    pro: Content
}

interface Props {
    content: TableContent[]
    price: Price[]
    title: string
}

export const UpgradeTable = (props: Props) => {

    const getCellContent = (content: Content): any => {
        if (content.included === true) {
            return <CheckIcon/>
        } else if (content.text !== undefined) {
            return content.text
        }

        return <NotIncluded/>
    }

    const formatAmount = (amount: number, currency: string, fractionDigits: number): string => {
        const locale = new Intl.NumberFormat().resolvedOptions().locale
        return new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currency,
            maximumFractionDigits: fractionDigits,
            minimumFractionDigits: fractionDigits
        }).format(amount)
    }

    let baseFeePrice: Price | null = null

    const filteredBaseFeePrice = props.price.filter(value => {
        return value.tiers_mode === undefined || value.tiers_mode === ""
    })
    if (filteredBaseFeePrice.length > 0) {
        baseFeePrice = filteredBaseFeePrice[0]
    }

    const filteredTieredPrice = props.price.filter(value => value.tiers_mode === "graduated")
    let tieredPrice: Price | null = null
    if (filteredTieredPrice.length > 0) {
        tieredPrice = filteredTieredPrice[0]
    }
    let minTieredPrice: PriceTier | null = null
    if (tieredPrice !== null) {
        const tiers = tieredPrice.tiers.sort((p1, p2) => p2.unit_amount - p1.unit_amount)
        if (tiers.length > 0) {
            minTieredPrice = tiers[0]
        }
    }

    return <table style={{
        height: 'fit-content',
        borderCollapse: 'separate',
        borderSpacing: '15px 0',
        color: '#FFFFFF',
        width: '100%'
    }}>
        <colgroup>
            <Column/>
            <Column/>
            <Column/>
        </colgroup>
        <tbody>
        <tr>
            <FirstTableCell><span
                style={{fontSize: '20px', fontWeight: 'bold'}}>{props.title}</span></FirstTableCell>
            <FirstTableCell><FreeBadge/></FirstTableCell>
            <FirstTableCell><ProBadge/></FirstTableCell>
        </tr>
        <tr>
            <PriceTableCell></PriceTableCell>
            <PriceTableCell>{formatAmount(0, baseFeePrice?.currency ?? 'eur', 0)}<HintText> /mo</HintText></PriceTableCell>
            <PriceTableCell>{formatAmount((baseFeePrice?.unit_amount ?? 0) / 100, baseFeePrice?.currency ?? 'eur', 0)}<HintText> /mo</HintText></PriceTableCell>
        </tr>
        <tr>
            <TableCell>Monthly active users (MAU)</TableCell>
            <TableCell><HintText>Up to</HintText> 100</TableCell>
            <TableCell>Unlimited</TableCell>
        </tr>
        <tr>
            <TableCell>Price per MAU</TableCell>
            <TableCell>{formatAmount(0, baseFeePrice?.currency ?? 'eur', 0)}</TableCell>
            <TableCell><HintText>starts
                at</HintText> {formatAmount((minTieredPrice?.unit_amount ?? 0) / 100, baseFeePrice?.currency ?? 'eur', 2)}
            </TableCell>
        </tr>
        <tr>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
        </tr>
        {
            props.content.map((c, index) => {
                if (c.title === "") {
                    return <tr>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </tr>
                } else if (index == (props.content.length - 1)) {
                    return <tr>
                        <LastTableCell>{c.title}</LastTableCell>
                        <LastTableCell>{getCellContent(c.free)}</LastTableCell>
                        <LastTableCell>{getCellContent(c.pro)}</LastTableCell>
                    </tr>
                } else {
                    return <tr>
                        <TableCell>{c.title}</TableCell>
                        <TableCell>{getCellContent(c.free)}</TableCell>
                        <TableCell>{getCellContent(c.pro)}</TableCell>
                    </tr>
                }
            })
        }
        {/*<tr>*/}
        {/*    <LastTableCell>Price per MAU /Month</LastTableCell>*/}
        {/*    <LastTableCell>{formatAmount(0, baseFeePrice?.currency ?? 'eur', 0)}</LastTableCell>*/}
        {/*    <LastTableCell><HintText>starts*/}
        {/*        at</HintText> {formatAmount((minTieredPrice?.unit_amount ?? 0) / 100, baseFeePrice?.currency ?? 'eur', 2)}*/}
        {/*    </LastTableCell>*/}
        {/*</tr>*/}
        </tbody>
    </table>
}
