import React, { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 45px;
  padding: 3px 14px 3px 3px;
  box-sizing: border-box;
  background-color: #272f34;
  border-radius: 2px;
`;

const Label = styled.div`
  margin-bottom: 5px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
`;

type ColorPickerProps = {
  defaultColor: string;
  label: string;
  onChange: (val: string) => void;
};

const ColorWrapper = styled.div`
  position: relative;
  width: 74px;
  height: 39px;
`;

const Button = styled.input`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

const ColorLabel = styled.div<{ backgroundColor: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const ColorInput = styled.input`
  width: 64px;
  height: 25px;
  border: none;
  padding: 0;
  background: none;
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;

  :focus-visible {
    outline: none;
  }
`;

const ColorPicker = ({ defaultColor, label, onChange }: ColorPickerProps) => {
  const [currentColor, setCurrentColor] = useState('');
  const [previewColor, setPreviewColor] = useState('');
  const FALLBACK_COLOR = '#000000';

  const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const color = value[0] === '#' ? value : `#${value}`;
    const validColor = isColorValid(color) ? color : FALLBACK_COLOR;

    setCurrentColor(color);

    setPreviewColor(validColor);
    onChange(validColor);
  };

  const handleColorSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const color = e.target.value;

    setCurrentColor(color);
    setPreviewColor(color);
    onChange(color);
  };

  const handleOnBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (!isColorValid(value)) {
      setCurrentColor(FALLBACK_COLOR);
    }
  };

  const highlightText = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.setSelectionRange(0, e.target.value.length);
  };

  const isColorValid = (color: string) => {
    const colorRegex = /[0-9A-F]{6}$/i;

    return !!color.match(colorRegex);
  };

  return (
    <>
      <Label>{label}</Label>
      <Wrapper>
        <ColorWrapper>
          <ColorLabel backgroundColor={previewColor || currentColor || defaultColor} />
          <Button type="color" onChangeCapture={handleColorSelect} value={currentColor || defaultColor} />
        </ColorWrapper>
        <ColorInput
          onFocus={highlightText}
          onBlur={handleOnBlur}
          type="text"
          value={currentColor || defaultColor}
          onChange={handleColorChange}
          maxLength={7}
        />
      </Wrapper>
    </>
  );
};

export default ColorPicker;
