import React from 'react';
import styled, { CSSProperties } from 'styled-components';
import { CopyableText } from './CopyableText';
import { InputComponent, Label, Wrapper } from './Input';

const LabelRadio = styled(Label)({
  margin: '0 0 0 30px',
  fontWeight: 500,
  fontSize: '16px',
  color: '#FFFFFF',
  cursor: 'pointer',
});

const ErrorMessage = styled.span`
  margin-left: auto;
  color: #ff2e4d;
  font-size: 14px;
  font-weight: 700;
`;

const LabelWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '12px',
});

const RadioInput = styled.input`
  position: absolute;
  z-index: 1;
  margin: 0;
  opacity: 0;
  width: ${({ size }) => (size ? `${size}px` : '14px')};
  height: ${({ size }) => (size ? `${size}px` : '14px')};
  cursor: pointer;
`;

const RadioCircleWrapper = styled.div<{ size?: number; isActive: boolean }>`
  position: absolute;
  width: ${({ size }) => (size ? `${size}px` : '14px')};
  height: ${({ size }) => (size ? `${size}px` : '14px')};
  border: 1px solid ${({ isActive }) => (isActive ? '#ffffff' : '#818A93')};
  border-radius: 100%;
`;

const RadioCircleDot = styled.div<{ size: any }>`
  position: absolute;
  width: ${({ size }) => (size ? `${size - 4}px` : '10px')};
  height: ${({ size }) => (size ? `${size - 4}px` : '10px')};
  background: #ffffff;
  border-radius: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  & > * {
    width: 100%;
  }
`;

const Textbox = styled(InputComponent)<{ copyButtonValue: boolean }>`
  padding-right: ${(copyButtonValue) => (copyButtonValue ? '40px' : '20px')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CopyToClipboardWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  width: 30px;
  height: 25px;
  transform: translateY(-50%);
`;

interface PropsWithRadio {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  type?: string;
  name?: string;
  value?: string | number | string[];
  placeholder?: string;
  disabled?: boolean;
  size?: number;
  style?: CSSProperties;
  className?: string;
  min?: number | string;
  pattern?: string;
  error?: string;
  radioValue: string;
  active: boolean;
  onRadioChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  copyButtonValue?: string;
  readOnly?: boolean;
}

interface InputPropsWithRadio {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  type?: string;
  name?: string;
  value?: string | number | string[];
  placeholder?: string;
  disabled?: boolean;
  size?: number;
  min?: number | string;
  pattern?: string;
  error?: string;
  copyButtonValue?: string;
  readOnly?: boolean;
}

export const InputWithRadioButton = (props: PropsWithRadio) => {
  const {
    label,
    onChange,
    type,
    name,
    value,
    placeholder,
    size,
    disabled,
    min,
    pattern,
    error,
    radioValue,
    active,
    onRadioChange,
    copyButtonValue,
    readOnly,
  } = props;
  const inputProps: InputPropsWithRadio = {
    onChange,
    label,
    type,
    name,
    value,
    placeholder,
    size,
    disabled,
    min,
    pattern,
    error,
    copyButtonValue,
  };

  return (
    <Wrapper className={props.className} style={props.style}>
      <LabelWrapper>
        <RadioInput
          type="radio"
          id={props.label}
          value={radioValue}
          checked={active}
          onChange={onRadioChange}
          size={size}
          disabled={disabled}
        />
        <RadioCircleWrapper size={size} isActive={active}>
          {active ? <RadioCircleDot size={size} /> : null}
        </RadioCircleWrapper>
        <LabelRadio htmlFor={props.label}>{props.label}</LabelRadio>
        {error ? <ErrorMessage>{error}</ErrorMessage> : null}
      </LabelWrapper>
      <InputWrapper>
        {copyButtonValue ? (
          <CopyableText>{copyButtonValue || ''}</CopyableText>
        ) : (
          <Textbox {...inputProps} disabled={disabled || readOnly || !active} copyButtonValue={false} />
        )}
      </InputWrapper>
    </Wrapper>
  );
};
