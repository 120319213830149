import { inject, observer } from 'mobx-react';
import React, { Children, useState } from 'react';
import { Link, useCurrentRoute, useNavigation } from 'react-navi';
import styled from 'styled-components';
import { MenuItemModel } from '../../models/MenuItemModel';
import { Color } from '../atoms/Color';
import { MenuLink } from '../atoms/MenuLink';
import { UserStore } from '../../store/UserStore';
import LogoSvg from '../../assets/logo.svg';
import { ReactComponent as AppsIcon } from '../../assets/apps.svg';
import { ReactComponent as SettingsIcon } from '../../assets/settings.svg';
import { ReactComponent as PaymentIcon } from '../../assets/payment.svg';
import { ReactComponent as ExternalLinkIcon } from '../../assets/external_link.svg';
import { ReactComponent as ArrowLeftSvg } from '../../assets/arrow_left.svg';
import { OrganizationStore } from '../../store/OrganizationStore';
import { useMedia } from 'react-media';
import { MEDIA_QUERIES } from '../../constants/MediaQueries';
import { ReactComponent as BurgerIcon } from '../../assets/burger_icon.svg';
import { ReactComponent as UpDownArrowsSvg } from '../../assets/up_down_arrows.svg';
import { appStateStore, AppStateStore } from '../../store/AppStateStore';
import { DropdownNavBar } from './NavBarDropdown';

const SIDEBAR_SIDE_PADDING = '35px';

const Wrapper = styled.div({
  position: 'fixed',
  display: 'flex',
  flexDirection: 'row',
  top: 0,
  left: 0,
  width: '275px',
  height: '100vh',
  padding: `20px 0`,
  boxSizing: 'border-box',
  backgroundColor: Color.darkBlue,
});

const ListWrapper = styled.div<{ isMobile?: boolean }>((props) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: props.isMobile ? '' : 'flex-start',
  width: '100%',
  marginTop: props.isMobile ? '55px' : 0,
  overflow: props.isMobile ? 'scroll' : 'visible',
}));

const LogoLink = styled(Link)<{ isMobile?: boolean }>((props) => ({
  display: 'flex',
  padding: props.isMobile ? 0 : `0 ${SIDEBAR_SIDE_PADDING}`,
}));

const Logo = styled.img({
  width: '110px',
  height: '55px',
});

const List = styled.ul<{ isMobile?: boolean, isProjectMenu?: boolean }>((props) => ({
  position: 'relative',
  marginTop: props.isMobile && props.isProjectMenu ? '30px' : '120px',
  marginBottom: props.isMobile ? '30px' : 'auto',
  padding: props.isMobile ? `0 ${SIDEBAR_SIDE_PADDING}` : 0,
  listStyle: 'none',
}));

const OrganizationList = styled(List)<{ isMobile?: boolean }>((props) => ({
  padding: props.isMobile ? 0 : `0 ${SIDEBAR_SIDE_PADDING}`,
}));

const ListItem = styled.li({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '15px',
  ':last-child': {
    marginBottom: '0',
  },
});

const BottomList = styled.ul({
  // position: 'absolute',
  bottom: 0,
  width: '100%',
  padding: 0,
  color: '#ffffff',
  listStyle: 'none',
});

const BottomListItem = styled.li<{ isMobile?: boolean }>((props) => ({
  width: '100%',
  marginBottom: '30px',
  padding: `0 ${SIDEBAR_SIDE_PADDING}`,
  boxSizing: 'border-box'
}));

const BottomListLink = styled.a({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: '#ffffff',
  textDecoration: 'none',
  ':hover': {
    textDecoration: 'underline',
  },
  cursor: 'pointer'
});

const MenuLinkWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px 0',
});

const AllProjectsLink = styled(Link)<{ isMobile?: boolean }>((props) => ({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  left: 0,
  marginTop: '-80px',
  marginLeft: props.isMobile ? SIDEBAR_SIDE_PADDING : 0,
  padding: props.isMobile ? '12px 25px 12px 17px' : '12px 25px 12px 48px',
  backgroundColor: props.isMobile ? Color.darkBlue : '#545B61',
  borderTopRightRadius: '50px',
  borderBottomRightRadius: '50px',
  borderBottomLeftRadius: props.isMobile ? '50px' : 0,
  borderTopLeftRadius: props.isMobile ? '50px' : 0,
  color: '#ffffff',
  fontWeight: 500,
  textDecoration: 'none',
}));

const MenuItemsContainer = styled.div<{ isMobile?: boolean }>((props) => ({
  padding: props.isMobile ? 0 : `0 ${SIDEBAR_SIDE_PADDING}`,
}));

const ArrowLeftIcon = styled(ArrowLeftSvg)({
  marginRight: '19px',
});

const MobileWrapper = styled(Wrapper)<{ isMenuVisible?: boolean }>((props) => ({
  position: 'fixed',
  zIndex: 9,
  left: '100%',
  width: '355px',
  marginLeft: props.isMenuVisible ? '-355px' : '0',
  backgroundColor: '#545B61',
}));

const MobileHeaderWrapper = styled.div({
  zIndex: 9,
});

const MobileHeader = styled.header({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  maxWidth: '300px',
  height: '42px',
  marginTop: '-3px',
  paddingLeft: '35px'
});

const DropdownNavWrapper = styled.div({
  width: '250px',
});

const MenuButton = styled.button({
  marginLeft: '-1px',
  background: 'none',
  border: 'none',
  cursor: 'pointer',
});

const MobileDivider = styled.hr({
  position: 'absolute',
  top: '67px',
  right: 0,
  width: '100%',
  height: '1px',
  backgroundColor: Color.darkBlue,
  border: 'none',
});

const MobileFooter = styled.div({});

const ExpandableItemContainer = styled.div({
  marginBottom: '35px',
  padding: '25px 35px',
  backgroundColor: Color.darkBlue,
});

const ExpandableItemTitle = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: '#ffffff',
  fontSize: '18px',
  fontWeight: 600,
  cursor: 'pointer'
});

const ExpandableItemContent = styled.div<{isExpanded: boolean}>((props) => ({
  display: 'flex',
  flexDirection: 'column',
  height: props.isExpanded ? 'auto' : 0,
  overflow: 'hidden'
}));

const MobileAccountLinks = styled.div({});

const ExpandableItem = styled(BottomListLink)({
  marginTop: '30px',
  cursor: 'pointer'
});

const Item = styled(BottomListLink)({})

const OrganizationMenu = ({ organizationId }: { organizationId?: string }) => {
  const route = useCurrentRoute();
  const isMobile = useMedia({ query: MEDIA_QUERIES.tablet });
  const links = [
    {
      text: 'All projects',
      url: `/organization/${organizationId}/relyingParty`,
      icon: (fill: string) => <AppsIcon style={{ width: '23px', height: '23px', marginRight: '16px' }} fill={fill} />,
    },
    {
      text: 'Org settings',
      url: `/organization/${organizationId}/settings/general`,
      icon: (fill: string) => (
        <SettingsIcon style={{ width: '23px', height: '23px', marginRight: '16px' }} fill={fill} />
      ),
    },
  ];

  return (
    <OrganizationList isMobile={isMobile} isProjectMenu={true}>
      {links.map((link, index) => {
        const isActive = route.url.pathname.endsWith(link.url);

        return (
          <ListItem key={index}>
            <MenuLink highlight={isActive} href={link.url} onClick={isMobile ? () => appStateStore.toggleSidebarMenu() : undefined}>
              <MenuLinkWrapper>
                {link.icon(isActive ? Color.primaryRed : '#ffffff')}

                {link.text}
              </MenuLinkWrapper>
            </MenuLink>
          </ListItem>
        );
      })}
    </OrganizationList>
  );
};

interface Props {
  isProjectMenu?: boolean;
  menuItems: MenuItemModel[];
  appStateStore?: AppStateStore;
  userStore?: UserStore;
  organizationStore?: OrganizationStore;
}

/**
 * The Menu is a route aware component, so it uses the url to determine which menuItem is active. This said, it only works correctly when the child routes
 * are under the parent route.
 * Correct example:
 *      parent route: `/settings`
 *      child route: `/settings/uaf`
 * Incorrect example:
 *      parent route: `/settings`
 *      child route: `/settings-uaf`
 */
export const MenuComponent = (props: Props) => {
  const route = useCurrentRoute();
  const isMobile = useMedia({ query: MEDIA_QUERIES.tablet });
  const navigation = useNavigation();
  const [isOrganizationListExpanded, setIsOrganizationListExpanded] = useState(false);

  const goToAccount = async () => {
    const currentLocation = window.location.toString();
    window.location.assign((props.userStore?.user?.profile_url ?? '') + '?return_to=' + currentLocation);
  };

  const goToSignOut = () => {
    window.location.assign('/api/sign_out');
  };

  const getListFor = (menuItems: MenuItemModel[], path: string, first: boolean = false, hasLogo: boolean = true) => {
    return (
      <>
        {hasLogo ? (
          <LogoLink
            href={`/organization/${props?.organizationStore?.organization?.id}/relyingParty`}
            isMobile={isMobile}
          >
            <Logo src={LogoSvg} />
          </LogoLink>
        ) : null}
        <List isMobile={isMobile} isProjectMenu={props.isProjectMenu}>
          {props.isProjectMenu ? (
            <OrganizationMenu organizationId={props.userStore?.user?.active_organization_id || ''} />
          ) : (
            <>
              <AllProjectsLink
                href={`/organization/${props?.organizationStore?.organization?.id}/relyingParty`}
                isMobile={isMobile}
              >
                <ArrowLeftIcon />
                All projects
              </AllProjectsLink>
              <MenuItemsContainer isMobile={isMobile}>
                {menuItems.map((value) => {
                  const isActive = path.startsWith(value.link);
                  const isChildrenActive = value.children?.find((child) => path.startsWith(child.link)) !== undefined;

                  const icon = value.icon ? (
                    <value.icon
                      height="24px"
                      width="24px"
                      fill={isActive || isChildrenActive ? Color.primaryRed : '#FFFFFF'}
                      style={{ marginRight: '16px' }}
                    />
                  ) : undefined;
                  if (value.children) {
                    return (
                      <>
                        <ListItem key={value.link}>
                          {icon}
                          <MenuLink highlight={!isChildrenActive && isActive} href={value.link} onClick={isMobile ? () => appStateStore.toggleSidebarMenu() : undefined}>
                            {value.title}
                          </MenuLink>
                        </ListItem>
                        <ListItem key={value.link + '-1'}>{getListFor(value.children, path)}</ListItem>
                      </>
                    );
                  } else {
                    return (
                      <ListItem key={value.link}>
                        {icon}
                        <MenuLink highlight={isActive} href={value.link} onClick={isMobile ? () => appStateStore.toggleSidebarMenu() : undefined}>
                          {value.title}
                        </MenuLink>
                      </ListItem>
                    );
                  }
                })}
              </MenuItemsContainer>
            </>
          )}
        </List>
        <BottomList>
          <BottomListItem isMobile={isMobile}>
            <BottomListLink href="https://docs.hanko.io/">
              Documentation
              <ExternalLinkIcon />
            </BottomListLink>
          </BottomListItem>
          <BottomListItem isMobile={isMobile}>
            <BottomListLink href="http://hankocommunity.slack.com/">
              Slack community
              <ExternalLinkIcon />
            </BottomListLink>
          </BottomListItem>
          <BottomListItem isMobile={isMobile}>
            <BottomListLink href="https://github.com/teamhanko">
              GitHub
              <ExternalLinkIcon />
            </BottomListLink>
          </BottomListItem>
          {isMobile ? (
            <MobileFooter>
              <ExpandableItemContainer>
                <ExpandableItemTitle onClick={() => setIsOrganizationListExpanded(!isOrganizationListExpanded)}>
                  <span>
                    {props.userStore?.organizations?.find(
                      (orga) => orga?.id === props.userStore?.user?.active_organization_id
                    )?.name || ''}
                  </span>
                  <UpDownArrowsSvg fill="#ffffff" />
                </ExpandableItemTitle>
                <ExpandableItemContent isExpanded={isOrganizationListExpanded}>
                  {props.userStore?.organizations?.map((orga, index) =>
                    orga.id !== props.userStore?.user?.active_organization_id ? (
                      <ExpandableItem
                        key={index}
                        onClick={() => {
                          appStateStore.toggleSidebarMenu();
                          navigation.navigate('/organization/' + orga?.id + '/relyingParty');
                        }}
                      >
                        {orga?.name || ''}
                      </ExpandableItem>
                    ) : null
                  )}
                </ExpandableItemContent>
              </ExpandableItemContainer>
              <MobileAccountLinks>
                <BottomListItem isMobile>
                  <Item onClick={goToAccount}>Account & Security</Item>
                </BottomListItem>
                <BottomListItem isMobile>
                  <Item onClick={goToSignOut}>Sign out</Item>
                </BottomListItem>
              </MobileAccountLinks>
            </MobileFooter>
          ) : null}
        </BottomList>
      </>
    );
  };

  return isMobile ? (
    <MobileWrapper isMenuVisible={props?.appStateStore?.isSidebarMenuVisible}>
      <MobileHeaderWrapper>
        <MobileHeader>
          <DropdownNavWrapper>
            <DropdownNavBar showDropdown={false} />
          </DropdownNavWrapper>
          <MenuButton onClick={() => props?.appStateStore?.toggleSidebarMenu()}>
            <BurgerIcon fill="#ffffff" />
          </MenuButton>
        </MobileHeader>
        <MobileDivider />
      </MobileHeaderWrapper>
      <ListWrapper isMobile>{getListFor(props.menuItems, route.url.pathname, true, false)}</ListWrapper>
    </MobileWrapper>
  ) : (
    <Wrapper>
      <ListWrapper>{getListFor(props.menuItems, route.url.pathname, true)}</ListWrapper>
    </Wrapper>
  );
};

MenuComponent.defaultProps = {
  isProjectMenu: false,
};

export const Menu = inject('userStore', 'appStateStore', 'organizationStore')(observer(MenuComponent));
