import React from "react";
import { ApiKeyModel } from "../../../models/ApiKeyModel";
import { CopyableText } from "../../atoms/CopyableText";
import styled from "styled-components";

const ItemRow = styled.tr({
    borderTop: '1px solid #272F34',
    ':last-child': {
        borderBottom: '1px solid #272F34',
    },
    fontSize: '14px',
    color: '#FFFFFF',
    padding: '25px 0px'
})

const KeyIdTitle = styled.span({
    fontWeight: 500,
    marginRight: '30px',
    whiteSpace: 'nowrap',
})

const TableCell = styled.td({
    padding: '25px 0px',
    ':first-child': {
        width: '450px'
    }
})

const KeyIdWrapper = styled.div({
    display: 'flex',
    alignItems: 'center'
})

const DeleteButton = styled.button({
    padding: 0,
    margin: 0,
    background: 0,
    textDecoration: 'underline',
    border: 'none',
    color: '#FFFFFF',
    fontSize: '14px',
    cursor: 'pointer'
})

const CreatedAtText = styled.span({
    fontWeight: 600
})

interface Props {
    item: ApiKeyModel
    deleteApiKey: (id: string) => void
}

export const ApiKeyTableItem = (props: Props) => {

    const deleteApiKey = () => {
        props.deleteApiKey(props.item.id)
    }

    const createdAt = Intl.DateTimeFormat().format(new Date(props.item.created_at))

    return <ItemRow>
        <TableCell><KeyIdWrapper><KeyIdTitle>Key ID:</KeyIdTitle><CopyableText style={{width: "333px"}}>{props.item.id}</CopyableText></KeyIdWrapper></TableCell>
        <TableCell>Created at: <CreatedAtText>{createdAt}</CreatedAtText></TableCell>
        <TableCell><DeleteButton onClick={deleteApiKey}>Delete</DeleteButton></TableCell>
    </ItemRow>
}