import React from 'react';
import styled from 'styled-components';
import Dropzone, { DropEvent } from 'react-dropzone';
import { ReactComponent as MediaIconRaw } from '../../assets/perm_media.svg';
import { Color } from './Color';

type DropzoneDragProps = {
  hasError: boolean;
  isDragActive: boolean;
  isDragAccept: boolean;
  isDragReject: boolean;
  inline?: boolean;
};

const MediaIcon = styled(MediaIconRaw)<{ inline?: boolean }>`
  width: ${({ inline }) => (inline ? '28px' : '35px')};
  height: auto;
`;

const getColors = (props: DropzoneDragProps) => {
  if (props.isDragAccept) {
    return '#FFFFFF';
  }
  if (props.isDragReject) {
    return '#FF0000';
  }
  if (props.isDragActive) {
    return '#DDDDDD';
  }
  return '#272F34';
};

const StyledDiv = styled.div<DropzoneDragProps>((props) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  alignItems: 'center',
  borderWidth: '3px',
  borderTopRightRadius: props.inline ? '0' : '5px',
  borderBottomRightRadius: '0',
  borderTopLeftRadius: '5px',
  borderBottomLeftRadius: props.inline ? '5px' : '0',
  borderColor: props.hasError ? '#ff2e4d' : getColors(props),
  borderStyle: 'solid',
  backgroundColor: 'none',
  color: '#FFFFFF',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
  overflow: props.inline ? 'hidden' : 'initial',
}));

const ImageWrapper = styled.div<{ inline?: boolean }>`
  display: flex;
  justify-content: center;
  width: 100%;
  height: ${({ inline }) => (inline ? '46px' : '64px')};
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #545b61;
`;

const Wrapper = styled.div<{ inline?: boolean }>`
  display: flex;
  flex-direction: ${({ inline }) => (inline ? 'row' : 'column')};
  height: ${({ inline }) => (inline ? '52px' : 'auto')};
  color: #818a93;
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Label = styled.div`
  margin-bottom: 5px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
`;

const ErrorMessage = styled.span`
  color: #ff2e4d;
  font-size: 14px;
  font-weight: 700;
`;

const Footer = styled.div<{ inline?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ inline }) => (inline ? '4px 11px 4px 8px' : '4px 8px 8px')};
  box-sizing: border-box;
  background-color: #272f34;
  border-top-right-radius: ${({ inline }) => (inline ? '5px' : '0')};
  border-bottom-right-radius: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: ${({ inline }) => (inline ? '0' : '5px')};
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
`;

interface Props {
  onFileChanged: (file?: File, fileUrl?: string) => void;
  inline?: boolean;
  fileUrl?: string;
  label?: string;
  error?: string | false;
  acceptTypes?: string[];
}

export const DropZone = (props: Props) => {
  const onDropCallback = <T extends File>(acceptedFiles: T[], rejectedFiles: T[], event: DropEvent) => {
    // acceptedFiles can be accessed through FileReaderAPI
    if (acceptedFiles.length == 0) {
      return;
    }

    const reader = new FileReader();
    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.onload = () => {
      //const binaryStr = reader.result
      props.onFileChanged(acceptedFiles[0], reader.result as string);
    };
    reader.readAsDataURL(acceptedFiles[0]);
  };

  const { fileUrl, label, error } = props;
  let content = (
    <ImageWrapper inline={props.inline}>
      <MediaIcon fill={Color.iconGrey} inline={props.inline} />
    </ImageWrapper>
  );

  let description = 'Upload';

  if (fileUrl) {
    content = (
      <div
        style={{
          justifyContent: 'center',
          display: 'flex',
          width: '100%',
          maxHeight: props.inline ? '46px' : '64px',
          textAlign: 'left',
        }}
      >
        <ImageWrapper inline={props.inline}>
          <Image src={fileUrl} alt="project logo" />
        </ImageWrapper>
      </div>
    );
    description = 'Replace';
  }

  return (
    <>
      <LabelWrapper>
        <Label>{label}</Label>
        {error ? <ErrorMessage>{error}</ErrorMessage> : null}
      </LabelWrapper>
      <Dropzone accept={props.acceptTypes || ['image/png', 'image/jpeg']} onDrop={onDropCallback}>
        {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => (
          <Wrapper inline={props.inline}>
            <StyledDiv
              {...getRootProps()}
              hasError={!!error}
              isDragAccept={isDragAccept}
              isDragActive={isDragActive}
              isDragReject={isDragReject}
              inline={props.inline}
            >
              <input {...getInputProps()} />
              {content}
            </StyledDiv>
            <Footer {...getRootProps()} inline={props.inline}>
              {description}
            </Footer>
          </Wrapper>
        )}
      </Dropzone>
    </>
  );
};

DropZone.defaultProps = {
  error: false,
};
