import React from 'react'
import { inject, observer } from 'mobx-react'
import { AppStateStore } from '../../store/AppStateStore'
import { UserStore } from "../../store/UserStore";

interface Props {
    appStateStore?: AppStateStore
    userStore?: UserStore
}

export const ChooseOrganizationComponent = (props: Props) => {

    props.appStateStore?.setBreadcrumbs([{
        title: 'Create Organization',
        link: '/organization'
    }])

    if (props.userStore?.user?.active_organization_id != undefined && props.userStore?.user?.active_organization_id != "00000000-0000-0000-0000-000000000000") {
        window.location.assign("/organization/" + props.userStore?.user?.active_organization_id + "/relyingParty")
    } else if (props.userStore?.organizations != undefined && props.userStore?.organizations.length > 0) {
        window.location.assign("/organization/" + props.userStore?.organizations[0].id + "/relyingParty")
    }

    return <></>
}

export const ChooseOrganization = inject('appStateStore', 'userStore')(observer(ChooseOrganizationComponent))