import React from 'react';
import { compose, map, mount, redirect, route, withView } from 'navi';
import { View } from 'react-navi';

import { isApiError } from './helper/ResponseHelper';
import { withAuthentication } from './helper/AuthenticationHelper';
import { getActiveOrganizationUrl } from './helper/OrganizationHelper';
import { BACKEND_LOGIN_REDIRECT_URL } from './constants/Url';

import { organizationStore } from './store/OrganizationStore';
import { userStore } from './store/UserStore';
import { relyingPartyStore } from './store/RelyingPartyStore';
import { identityStore } from './store/IdentityProjectStore';
import { apiKeyStore } from './store/ApiKeyStore';
import { statisticsStore } from './store/StatisticsStore';
import { hankoMdsStore } from './store/HankoMdsStore';
import { oidcClientStore } from './store/OidcClientStore';

import { ChooseOrganization } from './components/organization/ChooseOrganization';
import { RelyingPartyContainer } from './components/relying_party/RelyingPartyContainer';
import { ChooseRelyingParty } from './components/relying_party/ChooseRelyingParty';
import { OrganizationGeneralSettings } from './components/organization/OrganizationGeneralSettings';
import { IdentityProjectBrandSettings } from './components/relying_party/IdentityProjectBrandSettings';
import { OrganizationSettings } from './components/organization/OrganizationSettings';
import { WebauthnOverviewSettings } from './components/relying_party/settings/fido_settings/WebauthnOverviewSettings';
import { PasslinkSettings } from './components/relying_party/settings/PasslinkSettings';
import { RelyingPartyApiKeys } from './components/relying_party/RelyingPartyApiKeys';
import { passlinkRedirectUrlStore } from './store/PasslinkRedirectUrlStore';
import { RelyingPartyBrand } from './components/relying_party/RelyingPartyBrand';
import { RelyingPartyCustomDomain } from './components/relying_party/RelyingPartyCustomDomain';
import { RelyingPartyProject } from './components/relying_party/RelyingPartyProject';
import { IdentityDashboard } from './components/relying_party/identity_project/IdentityDashboard';
import { ApiDashboard } from './components/relying_party/api_project/ApiDashboard';
import { appStateStore } from './store/AppStateStore';
import { AuthenticationApiBrandSettings } from './components/relying_party/settings/RelyingPartySettings';
import { IdentityEmailSettings } from './components/relying_party/identity_project/IdentityEmailSettings';
import { RelyingPartyAppDetails } from './components/relying_party/RelyingPartyAppDetails';
import { RelyingPartyApps } from './components/relying_party/RelyingPartyApps';
import { IdentityUserManagementCredentials } from './components/relying_party/identity_project/user_management/IdentityUserManagement';
import { IdentityUserManagementCredentialDetails } from './components/relying_party/identity_project/user_management/IdentityUserManagementCredentialDetails';
import GenericErrorPage from './pages/GenericErrorPage';
import moment from 'moment';

export const routes = mount({
  '/': withAuthentication(
    route({
      view: <></>,
      getView: () =>
        new Promise(() => {
          appStateStore.setProjectTypeLabelVisibility(false);
          window.location.assign(getActiveOrganizationUrl(userStore.user?.active_organization_id));
        }),
    })
  ),
  '/login': map(async (request) => {
    const user = await userStore.get();
    appStateStore.setProjectTypeLabelVisibility(false);
    return !isApiError(user)
      ? redirect(
          // user logged in, redirect to requested url or dashboard
          request.params.redirect_to ? decodeURIComponent(request.params.redirect_to) : '/'
        )
      : route({
          // user logged out, redirect to oidc redirect api
          getView: (request) =>
            new Promise(() => {
              // do not resolve this promise or the screen will flash before redirect
              window.location.assign(BACKEND_LOGIN_REDIRECT_URL(request.params.redirect_to, 'false'));
            }),
        });
  }),
  '/error': route({
    view: <GenericErrorPage />,
  }),
  '/organization': withAuthentication(
    route({
      getData: async () => {
        appStateStore.setProjectTypeLabelVisibility(false);
        await userStore.getOrganizations();
      },
      view: <ChooseOrganization />,
    })
  ),
  '/organization/:organizationId/settings': map(async () => {
    return compose(
      withView(
        <OrganizationSettings>
          <View />
        </OrganizationSettings>
      ),
      mount({
        '/general': withAuthentication(
          route({
            getData: async (req) => {
              appStateStore.setProjectTypeLabelVisibility(false);
              await organizationStore.get(req.params.organizationId);
            },
            view: <OrganizationGeneralSettings />,
          })
        ),
      })
    );
  }),
  '/organization/:organizationId/relyingParty': withAuthentication(
    route(async (req) => {
      const { organizationId } = req.params;
      await Promise.all([
        organizationStore.get(organizationId),
        organizationStore.listRelyingParties(organizationId),
        userStore.getOrganizations(),
      ]);
      appStateStore.setProjectTypeLabelVisibility(false);

      return {
        view: <ChooseRelyingParty organizationId={organizationId} />,
      };
    })
  ),
  '/organization/:organizationId/relyingParty/brand': withAuthentication(
    route(async (req) => {
      appStateStore.setProjectTypeLabelVisibility(false);
      const { organizationId } = req.params;
      await Promise.all([organizationStore.get(organizationId), organizationStore.listRelyingParties(organizationId)]);

      return {
        view: <RelyingPartyBrand organizationId={organizationId} />,
      };
    })
  ),
  '/organization/:organizationId/relyingParty/project': withAuthentication(
    route(async (req) => {
      appStateStore.setProjectTypeLabelVisibility(false);
      const { organizationId } = req.params;
      await Promise.all([organizationStore.get(organizationId), organizationStore.listRelyingParties(organizationId)]);

      return {
        view: <RelyingPartyProject organizationId={organizationId} />,
      };
    })
  ),
  '/organization/:organizationId/relyingParty/:projectId': map(async () => {
    appStateStore.setProjectTypeLabelVisibility(true);

    return compose(
      withView((request) => {
        return (
          <RelyingPartyContainer projectId={request.params.projectId}>
            <View />
          </RelyingPartyContainer>
        );
      }),
      mount({
        '/dashboard': withAuthentication(
          // @ts-ignore
          route(async (req) => {
            let projectType = undefined;
            const { organizationId, projectId } = req.params;
            const endTime = moment.utc().endOf('day');
            const startTime = moment.utc().subtract(30, 'days').startOf('day');
            const response = await relyingPartyStore.get(organizationId, projectId).then((res) => res);

            // @ts-ignore
            if (response?.id && response.id.length > 0) {
              projectType = 'API';
            } else {
              projectType = 'IDENTITY';
            }

            if (projectType === 'API') {
              await Promise.all([
                organizationStore.get(organizationId),
                relyingPartyStore.getTenantStatus(organizationId, projectId),
                statisticsStore.getCurrentMonthActiveUsers('api', organizationId, projectId),
                statisticsStore.getLastMonthActiveUsers('api', organizationId, projectId),
                userStore.getOrganizations()
              ]);

              return {
                view: <ApiDashboard endTime={endTime} startTime={startTime} />,
              };
            } else if (projectType === 'IDENTITY') {
              await Promise.all([
                identityStore.get(organizationId, projectId).then((res) => res),
                organizationStore.get(organizationId),
                identityStore.getTenantStatus(organizationId, projectId),
                statisticsStore.getCurrentMonthActiveUsers('identity', organizationId, projectId),
                statisticsStore.getLastMonthActiveUsers('identity', organizationId, projectId),
                oidcClientStore.list(organizationId, projectId),
                userStore.getOrganizations()
              ]);

              return {
                view: <IdentityDashboard endTime={endTime} startTime={startTime} />,
              };
            }
          })
        ),
        '/brand': withAuthentication(
          // @ts-ignore
          route(async (req) => {
            let projectType = undefined;
            const { organizationId, projectId } = req.params;
            await Promise.all([organizationStore.get(organizationId)]);
            const response = await relyingPartyStore.get(organizationId, projectId).then((res) => res);

            // @ts-ignore
            if (response?.id && response.id.length > 0) {
              projectType = 'API';
            } else {
              projectType = 'IDENTITY';
            }

            if (projectType === 'API') {
              await Promise.all([
                organizationStore.get(organizationId),
                relyingPartyStore.getTenantStatus(organizationId, projectId),
              ]);

              return {
                view: <AuthenticationApiBrandSettings />,
              };
            } else if (projectType === 'IDENTITY') {
              await Promise.all([organizationStore.get(organizationId), identityStore.get(organizationId, projectId)]);

              return {
                view: <IdentityProjectBrandSettings organizationId={organizationId} relyingPartyId={projectId} />,
              };
            }
            // return {
            //     view: <IdentityProjectBrandSettings organizationId={organizationId} relyingPartyId={projectId} />,
            // };
          })
        ),
        '/webauthn': withAuthentication(
          route({
            getData: async (req) => {
              const { organizationId, projectId } = req.params;
              await Promise.all([
                organizationStore.get(organizationId),
                relyingPartyStore.get(organizationId, projectId),
                hankoMdsStore.get(),
              ]);
            },
            view: <WebauthnOverviewSettings />,
          })
        ),
        '/passlinks': withAuthentication(
          route({
            getData: async (req) => {
              const { organizationId, projectId } = req.params;
              await Promise.all([
                organizationStore.get(organizationId),
                relyingPartyStore.get(organizationId, projectId),
                passlinkRedirectUrlStore.list(organizationId, projectId),
              ]);
            },
            view: <PasslinkSettings />,
          })
        ),
        '/api_keys': withAuthentication(
          route({
            getData: async (req) => {
              const { organizationId, projectId } = req.params;
              await Promise.all([
                organizationStore.get(organizationId),
                relyingPartyStore.get(organizationId, projectId),
                apiKeyStore.list(organizationId, projectId),
              ]);
            },
            view: <RelyingPartyApiKeys />,
          })
        ),
        '/custom_domain': withAuthentication(
          route(async (req) => {
            const { organizationId, projectId } = req.params;
            await Promise.all([identityStore.get(organizationId, projectId), organizationStore.get(organizationId)]);

            return {
              view: <RelyingPartyCustomDomain organizationId={organizationId} />,
            };
          })
        ),
        '/emails': withAuthentication(
          route(async (req) => {
            const { organizationId, projectId } = req.params;
            await Promise.all([identityStore.get(organizationId, projectId), organizationStore.get(organizationId)]);
            return {
              view: <IdentityEmailSettings />,
            };
          })
        ),
        '/apps': withAuthentication(
          route(async (req) => {
            const { organizationId, projectId } = req.params;

            await Promise.all([
              organizationStore.get(organizationId),
              organizationStore.listRelyingParties(organizationId),
              identityStore.get(organizationId, projectId),
              oidcClientStore.list(organizationId, projectId),
            ]);

            return {
              view: <RelyingPartyApps organizationId={organizationId} relyingPartyId={projectId} />,
            };
          })
        ),
        '/apps/:appId': withAuthentication(
          route(async (req) => {
            const { appId, organizationId, projectId } = req.params;
            await Promise.all([
              organizationStore.get(organizationId),
              identityStore.get(organizationId, projectId),
              oidcClientStore.get(organizationId, projectId, appId),
            ]);
            return {
              view: <RelyingPartyAppDetails organizationId={organizationId} relyingPartyId={projectId} appId={appId} />,
            };
          })
        ),
        '/um': withAuthentication(
          route(async (req) => {
            const { organizationId, projectId } = req.params;
            await Promise.all([
              organizationStore.get(organizationId),
              organizationStore.listRelyingParties(organizationId),
              identityStore.get(organizationId, projectId),
              oidcClientStore.list(organizationId, projectId),
            ]);

            return {
              view: <IdentityUserManagementCredentials organizationId={organizationId} relyingPartyId={projectId} />,
            };
          })
        ),
        '/um/:appId': withAuthentication(
          route(async (req) => {
            const { appId, organizationId, projectId } = req.params;
            await Promise.all([
              organizationStore.get(organizationId),
              organizationStore.listRelyingParties(organizationId),
              identityStore.get(organizationId, projectId),
              oidcClientStore.get(organizationId, projectId, appId),
              oidcClientStore.list(organizationId, projectId),
            ]);

            return {
              view: (
                <IdentityUserManagementCredentialDetails
                  organizationId={organizationId}
                  relyingPartyId={projectId}
                  appId={appId}
                />
              ),
            };
          })
        ),
      })
    );
  }),
});
