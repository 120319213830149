import React, { ChangeEvent, useState } from 'react';
import { Link, useNavigation } from 'react-navi';
import styled from 'styled-components';
import ReactModal from 'react-responsive-modal';
import { AddOverlayBanner } from '../atoms/OverlayBanner';
import { Color } from '../atoms/Color';
import { FormList, FormListItem } from '../atoms/List';
import { Input } from '../atoms/Input';
import { DropZone } from '../atoms/DropZone';
import { PrimaryButton } from '../atoms/Button';
import LeftArrowSvg from '../../assets/arrow_left.svg';
import { CREATE_API_PROJECT } from '../../constants/Url';
import { RelyingPartyStore } from '../../store/RelyingPartyStore';
import { RelyingPartyModel } from '../../models/RelyingPartyModel';
import { toast } from 'react-toastify';
import { InputWithRadioButton } from '../atoms/InputWithRadio';
import { useMedia } from 'react-media';
import { MEDIA_QUERIES } from '../../constants/MediaQueries';
import ReactModalStyle from '../../constants/ReactModalStyle';

const ModalContent = styled.div`
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 750px;
  padding: 35px 130px 75px;
  background-color: #3e464c;
  border-radius: 5px;
  border: none;
  box-shadow: 2px 1px 8px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  box-sizing: border-box;
`;

const Title = styled.h1`
  margin: 45px 0 35px;
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
`;

const Subtitle = styled.h2`
  margin-top: 0;
  margin-bottom: 7px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
`;

const SubtitleText = styled.p`
  margin-bottom: 35px;
  color: #ffffff;
  font-size: 14px;
  line-height: 21.5px;
`;

const RadioSectionWrapper = styled.div`
  margin-bottom: 60px;
`;

const RadioWrapper = styled.div`
  margin-top: 35px;
`;

const RadioSectionWarning = styled.p`
  margin-top: 25px;
  color: #ffffff;
  font-size: 14px;
  line-height: 21.5px;
`;

const BoldRed = styled.span`
  color: #ff2e4c;
  font-weight: 600;
`;

const NameLogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DropzoneWrapperStyle = {
  width: '170px',
  margin: '0 0 50px',
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto;
`;

const BackButtonStyles = {
  display: 'flex',
  alignItems: 'center',
  marginRight: '20px',
  marginLeft: '70px',
  color: '#ffffff',
  fontWeight: 600,
  textDecoration: 'none',
};

const LeftArrowIcon = styled.img`
  margin-right: 8px;
`;

type Props = {
  relyingPartyStore?: RelyingPartyStore;
  organizationId: string;
};

const RelyingPartyProject = ({ organizationId, relyingPartyStore }: Props) => {
  const DEFAULT_LOCAL_DOMAIN = 'http://localhost';
  const [projectName, setProjectName] = useState('');
  const [domain, setDomain] = useState<string>(DEFAULT_LOCAL_DOMAIN);
  const [logoFileUrl, setLogoFileUrl] = useState<string | undefined>(undefined);
  const [logoFileSelected, setLogoFileSelected] = useState<File | undefined>(undefined);
  const [nameHasError, setNameHasError] = useState(false);
  const [domainHasError, setDomainHasError] = useState(false);
  const [hasGeneralError, setHasGeneralError] = useState(false);
  const [pickedRadioDomain, setPickedRadioDomain] = useState<'localDev' | 'appUrl'>('localDev');
  const navigation = useNavigation();
  const isAppUrlValid = !!(domain && domain.match('https://'));
  const isSmallScreen = useMedia({ query: MEDIA_QUERIES.tablet });

  const onLogoFileChanged = (file?: File, fileUrl?: string) => {
    setLogoFileUrl(fileUrl);
    setLogoFileSelected(file);
  };

  const handleRadioChange = (radioDomain: 'localDev' | 'appUrl') => {
    if (radioDomain === 'localDev') {
      setDomain(DEFAULT_LOCAL_DOMAIN);
      setDomainHasError(false);
    }

    return setPickedRadioDomain(radioDomain);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const isNameValid = projectName.length > 0;
    const isDomainValid = (pickedRadioDomain === 'appUrl' && isAppUrlValid) || pickedRadioDomain !== 'appUrl';

    setNameHasError(false);
    setDomainHasError(false);
    setHasGeneralError(false);

    if (!isNameValid) {
      setNameHasError(true);
    }

    if (!isDomainValid) {
      setDomainHasError(true);
    }

    if (isNameValid && isDomainValid) {
      try {
        fetch(CREATE_API_PROJECT(organizationId), {
          method: 'POST',
          credentials: 'same-origin',
          cache: 'no-cache',
          mode: 'cors',
          body: JSON.stringify({
            name: projectName,
            origin: domain,
            icon_url: logoFileUrl,
          }),
        })
          .then((res) => {
            if (res.status === 200) {
              return res.json();
            } else if (res.status === 413) {
              toast.error(`Logo too large: max. 1 MB please`);
            }
          })
          .then((res: RelyingPartyModel) => {
            if (res?.id) {
              navigation.navigate(`/organization/${organizationId}/relyingParty/${res.id}/dashboard`);
            } else {
              setHasGeneralError(true);
            }
          });
      } catch (error) {
        console.error(error);
        setHasGeneralError(true);
      }
    }
  };

  return (
    <ReactModal open={true} onClose={() => {}} center={true} styles={ReactModalStyle({isSmallScreen, customStyles: {width: 'auto'}})} blockScroll>
      <AddOverlayBanner />
      <ModalContent>
        <Title>Create new Authentication API</Title>
        <form>
          <FormList style={{ margin: '0' }}>
            <NameLogoWrapper>
              <FormListItem>
                <Input
                  label="Project name*"
                  onChange={(e) => setProjectName(e.target.value)}
                  error={nameHasError ? 'Required' : undefined}
                  name="name"
                  placeholder=""
                  style={{ width: '305px' }}
                />
              </FormListItem>
              <FormListItem style={DropzoneWrapperStyle}>
                <DropZone label="Logo" onFileChanged={onLogoFileChanged} fileUrl={logoFileUrl} inline />
              </FormListItem>
            </NameLogoWrapper>
            <Subtitle>Project URL</Subtitle>
            <SubtitleText>Set up your project's URL (where your app is running). Can be changed later</SubtitleText>
            <RadioSectionWrapper>
              <RadioWrapper>
                <InputWithRadioButton
                  label="Local development & testing"
                  radioValue="localDev"
                  active={pickedRadioDomain === 'localDev'}
                  name="domain-radio"
                  onChange={(e) => setDomain(DEFAULT_LOCAL_DOMAIN)}
                  onRadioChange={() => handleRadioChange('localDev')}
                  value={DEFAULT_LOCAL_DOMAIN}
                  placeholder={DEFAULT_LOCAL_DOMAIN}
                  readOnly
                />
              </RadioWrapper>
              <RadioWrapper>
                <InputWithRadioButton
                  type="url"
                  label="Application URL"
                  radioValue="appUrl"
                  active={pickedRadioDomain === 'appUrl'}
                  name="domain-radio"
                  onChange={(e) => setDomain(e.target.value)}
                  onRadioChange={() => setPickedRadioDomain('appUrl')}
                  value={domain === DEFAULT_LOCAL_DOMAIN ? '' : domain}
                  placeholder="https://yourdomain.com"
                  error={domainHasError ? 'Must begin with "https://"' : undefined}
                />
              </RadioWrapper>
              <RadioSectionWarning>
                <BoldRed>WARNING:</BoldRed> WebAuthn credentials of your users will be bound (scoped) to this URL's
                domain. Changing the domain makes all existing credentials unusable
              </RadioSectionWarning>
            </RadioSectionWrapper>
            <ButtonWrapper>
              <FormListItem style={{ display: 'flex' }}>
                <Link href={`/organization/${organizationId}/relyingParty`} style={BackButtonStyles}>
                  <LeftArrowIcon src={LeftArrowSvg} alt="left arrow icon" />
                  Back
                </Link>
                <Link
                  href={`/organization/${organizationId}/relyingParty/brand`}
                  style={{ width: '215px', marginLeft: '35px', color: '#ffffff', textDecoration: 'none' }}
                >
                  <PrimaryButton onClick={handleSubmit}>Create</PrimaryButton>
                </Link>
              </FormListItem>
            </ButtonWrapper>
          </FormList>
        </form>
      </ModalContent>
    </ReactModal>
  );
};

export { RelyingPartyProject };
