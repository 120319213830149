const getTenantUrl = (relyingPartyId?: string): string => {
  let env = '';
  if (window.location.origin.includes('.dev.')) {
    env = '.dev';
  } else if (window.location.origin.includes('.stg.')) {
    env = '.stg';
  }

  return 'https://' + relyingPartyId + '.authentication' + env + '.hanko.io';
};

const getIdentityTenantUrl = (relyingPartyId: string): string => {  let env = '';
  if (window.location.origin.includes('.dev.')) {
    env = '.dev';
  } else if (window.location.origin.includes('.stg.')) {
    env = '.stg';
  }

  return 'https://' + relyingPartyId + '.identity' + env + '.hanko.io';
}

export { getTenantUrl, getIdentityTenantUrl };
