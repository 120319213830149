import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { Link, useCurrentRoute } from 'react-navi';
import { toast } from 'react-toastify';
import { isApiError } from '../../helper/ResponseHelper';

import { OrganizationRequest } from '../../models/OrganizationModel';
import { OrganizationStore } from '../../store/OrganizationStore';

import { Input } from '../atoms/Input';
import { PrimaryButton } from '../atoms/Button';
import { SettingsContainer } from '../atoms/Container';
import { BACKEND_LOGIN_REDIRECT_URL, GET_BILLING_PORTAL_URL } from '../../constants/Url';

import { ReactComponent as ExternalLinkSvg } from '../../assets/external_link.svg';

const Wrapper = styled.div({
  display: "flex",
  flexDirection: "column",
})

const Headline = styled.h2({
  marginTop: 0,
  marginBottom: "15px",
  color: "#ffffff",
  fontSize: "18px",
  fontWeight: 700,
  textAlign: "left",
  letterSpacing: 0,
})

const ContainerButton = styled(PrimaryButton)({
  maxWidth: "160px",
  marginTop: "30px",
})

const OrganizationSettingsContainer = styled(SettingsContainer)({
  width: "auto",
})

const BillingWrapper = styled(SettingsContainer)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "auto",
  color: "#ffffff",
})

const BillingLeftColumn = styled.div({});

const BillingHeadline = styled(Headline)({});

const BillingText = styled.p({
  fontSize: "14px",
})

const BillingRightColumn = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
})

const BillingLink = styled(Link)({
  textDecoration: "none",
})

const BillingButton = styled(PrimaryButton)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "max-content",
  padding: "13px 25px 13px 16px",
})

const ExternalLinkIcon = styled(ExternalLinkSvg)({
  marginLeft: "10px",
})

interface Props {
  error?: string;
  organizationStore?: OrganizationStore;
}

type State = {
  organizationNameChanged: boolean;
  newOrganizationName: string;
};

const OrganizationGeneralSettingsComponent = (props: Props) => {
  const route = useCurrentRoute();
  const [state, setState] = useState<State>({
    organizationNameChanged: false,
    newOrganizationName: props.organizationStore?.organization?.name ?? '',
  });
  const [billingUrl, setBillingUrl] = useState('');

  useEffect(() => {
    fetch(
      GET_BILLING_PORTAL_URL(props.organizationStore?.organization?.id ?? '') +
        '?return_url=' +
        encodeURIComponent(window.location.toString()),
      {
        method: 'GET',
        credentials: 'same-origin',
        cache: 'no-cache',
        mode: 'cors',
      }
    )
      .then((res) => {
        if (res.status === 201) {
          setBillingUrl(res.headers.get('location') || '');
        } else {
          toast.error('Internal server error');
        }
      })
      .catch(() => {
        toast.error('Internal server error');
      });
  }, []);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newOrganizationName = e.target.value;
    const oldOrganizationName = props.organizationStore?.organization?.name;

    if (oldOrganizationName !== newOrganizationName) {
      setState({
        organizationNameChanged: true,
        newOrganizationName: newOrganizationName,
      });
    } else {
      setState({
        organizationNameChanged: false,
        newOrganizationName: newOrganizationName,
      });
    }
  };

  const onButtonClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const organizationId = props.organizationStore?.organization?.id ?? '';
    const request: OrganizationRequest = {
      name: state.newOrganizationName.trim(),
    };

    const result = await props.organizationStore?.update(organizationId, request);
    if (!isApiError(result)) {
      toast.success('Organization name updated');
      setState({
        organizationNameChanged: false,
        newOrganizationName: result?.name ?? '',
      });
    } else if (result.code === 401) {
      window.location.assign(BACKEND_LOGIN_REDIRECT_URL(route.url.href));
    } else {
      toast.error('Organization details could not be updated');
      setState({
        ...state,
        organizationNameChanged: true,
      });
    }
  };

  return (
    <Wrapper>
      <OrganizationSettingsContainer>
        <Headline>Organization details</Headline>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <Input
            onChange={onInputChange}
            label="Organization name"
            value={state.newOrganizationName}
            style={{ width: '60%', maxWidth: '500px' }}
          />
          <ContainerButton disabled={!state.organizationNameChanged} onClick={onButtonClick}>
            Save changes
          </ContainerButton>
        </div>
      </OrganizationSettingsContainer>
      <BillingWrapper>
        <BillingLeftColumn>
          <BillingHeadline>Billing</BillingHeadline>
          <BillingText>View and edit your billing details, as well as manage your subscription</BillingText>
        </BillingLeftColumn>
        <BillingRightColumn>
          <BillingLink href={billingUrl}>
            <BillingButton>
              Billing portal <ExternalLinkIcon />
            </BillingButton>
          </BillingLink>
        </BillingRightColumn>
      </BillingWrapper>
    </Wrapper>
  );
};

export const OrganizationGeneralSettings = inject('organizationStore')(observer(OrganizationGeneralSettingsComponent));
