import React from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '../atoms/Button';
import { RelyingPartyImage } from './RelyingPartyImage';
import { Color } from '../atoms/Color';
import { useNavigation } from 'react-navi';
import { ProjectModel } from "../../models/ProjectModel";

const Container = styled.div`
  width: 241px;
  height: 338px;
  background-color: ${Color.darkBlue};
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 5px;

  @supports not (gap: 24px) {
    margin-bottom: 24px;

    :not(:nth-child(4)) {
      margin-right: 24px;
    }
  }
`;

const Wrapper = styled.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignSelf: 'center',
  maxWidth: '100%',
  margin: 'auto',
  color: 'white',
});

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  color: #e1e7ec;
  font-size: 15px;
  font-weight: 600;
`;

const Label = styled.span<{ planLabel: boolean }>`
  margin-right: -20px;
  padding: 5px 6px 5px 14px;
  background-color: ${({ planLabel }) => (planLabel ? '#50C9BA' : '#545b61')};
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  color: #e1e7ec;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.5px;
`;

const PartyName = styled.h3`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
`;

const OriginSpan = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  font-family: 'IBM Plex Mono', monospace;
  font-size: 14px
`;

interface Props {
  relyingParty: ProjectModel;
  organizationId: string;
}

export const ChooseRelyingPartyBox = (props: Props) => {
  const { relyingParty } = props;
  const navigation = useNavigation();
  const TENANT_SECONDARY_COLOR = '#3E464C';

  const onManageButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const url = `/organization/${props.organizationId}/relyingParty/${relyingParty.id}/dashboard`
      navigation.navigate(url)
  }

  return (
    <Container>
      <TopContainer>
        <span>{relyingParty.projectType === 'api' ? 'API' : 'Identity'}</span>
        <Label planLabel={relyingParty.subscription.active}>{relyingParty.subscription.active ? 'Pro' : 'Free'}</Label>
      </TopContainer>
      <RelyingPartyImage src={relyingParty.iconUrl} backgroundColor={relyingParty.subscription.active ? TENANT_SECONDARY_COLOR : '#ffffff'} />
      <Wrapper>
        <PartyName>{relyingParty.name}</PartyName>
        <OriginSpan>{relyingParty.domain}</OriginSpan>
      </Wrapper>
      <PrimaryButton
        style={{ marginTop: 'auto' }}
        onClick={onManageButtonClick}
      >
        Manage
      </PrimaryButton>
    </Container>
  );
};
