import styled from "styled-components";
import { Color } from "./Color";

export const ToggleSwitchWrapper = styled.div({
  position: "relative",
  width: "max-content",
  marginLeft: "auto",
})


export const ToggleSwitchLabel = styled.label({
  position: "absolute",
  top: 0,
  left: 0,
  width: "45px",
  height: "30px",
  borderRadius: "15px",
  background: Color.moreIntenseRed,
  cursor: "pointer",
  "&::after": {
    content: "''",
    display: "block",
    borderRadius: "50%",
    width: "22px",
    height: "22px",
    margin: "3px",
    marginLeft: "1px",
    background: Color.darkBlue,
    boxShadow: "1px 3px 3px 1px rgba(0, 0, 0, 0.2)",
    transition: "0.2s",
  }
})


export const ToggleSwitchBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 45px;
  height: 30px;
  margin: 0;
  &:checked + ${ToggleSwitchLabel} {
    background: #50C9BA;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 22px;
      height: 22px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

