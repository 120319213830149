import React from 'react'
import styled from 'styled-components'

import { ReactComponent as AddIcon } from '../../assets/add_circle_outline.svg'
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg'
import { Color } from "./Color";


const BannerWrapper = styled.div({
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    minWidth: '300px',
    width: '100%',
    height: '65px',
    maxHeight: '65px',
    backgroundColor: '#545B61',
    borderRadius: '5px 5px 0 0'
})

interface Props {
    children: React.ReactNode
}

const OverlayBanner = (props: Props) => {
    return <BannerWrapper>
        {props.children}
    </BannerWrapper>
}

const AddIconBackgroundDiv = styled.div({
    backgroundColor: Color.darkBlue,
    height: '77px',
    width: '77px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center'
})

export const AddOverlayBanner = (props: {}) => <OverlayBanner {...props}>
    <AddIconBackgroundDiv>
        <AddIcon height="55px" width="55px" fill="#818A93" style={{
            alignSelf: 'center'
        }}/>
    </AddIconBackgroundDiv>
</OverlayBanner>

export const DeleteOverlayBanner = (props: {}) => <OverlayBanner {...props}>
    <DeleteIcon height="50px" width="50px" fill="#818A93" style={{
        alignSelf: 'center'
    }}/>
</OverlayBanner>
