import React, { useState } from 'react'
import styled from 'styled-components'
import { RelyingPartyStore } from '../../../store/RelyingPartyStore'
import { inject, observer } from 'mobx-react'
import { PrimaryButton } from '../../atoms/Button'
import { OrganizationStore } from '../../../store/OrganizationStore'
import { SettingsContainerLarge as SettingsContainerLargeComponent } from '../../atoms/Container';
import { SettingsDescription, SettingsTitle } from '../../atoms/Texts'
import { AddPasslinkRedirectUrlModal } from "./AddPasslinkRedirectUrlModal";
import { DeletePasslinkRedirectUrlModal } from "./DeletePasslinkRedirectUrlModal";
import { PasslinkRedirectUrlStore } from "../../../store/PasslinkRedirectUrlStore";
import { PasslinkRedirectUrlModel } from "../../../models/PasslinkRedirectUrlModel";
import { PasslinksTableItem } from "./PasslinksTableItem";
import { isApiError } from "../../../helper/ResponseHelper";
import { toast } from "react-toastify";
import { BACKEND_LOGIN_REDIRECT_URL } from "../../../constants/Url";
import { useCurrentRoute } from "react-navi";

const SettingsContainerLarge = styled(SettingsContainerLargeComponent)({
  width: '100%',
});

const AddNewButton = styled(PrimaryButton)({
    width: "160px",
    fontWeight: 500
})

const RedirectUrlTable = styled.table({
    width: "100%",
    maxWidth: '100%',
    margin: '30px 0 20px 0',
    borderCollapse: 'collapse',
    tableLayout: 'fixed'
})

export const URLText = styled.span({
    wordBreak: "break-all",
    wordWrap: "break-word",
    fontStyle: "italic",
})

interface Props {
    relyingPartyStore?: RelyingPartyStore
    organizationStore?: OrganizationStore
    passlinkRedirectUrlStore?: PasslinkRedirectUrlStore
}

type State = {
    isAddModalOpen: boolean,
    isRemoveModalOpen: boolean,
    selectedRedirectUrl: PasslinkRedirectUrlModel,
}

const PasslinkSettingsComponent = (props: Props) => {
    const [state, setState] = useState<State>({
        isAddModalOpen: false,
        isRemoveModalOpen: false,
        selectedRedirectUrl: {} as PasslinkRedirectUrlModel,
    })
    const route = useCurrentRoute()

    const refreshRedirectUrlList = () => {
        const {organizationStore, relyingPartyStore, passlinkRedirectUrlStore} = props
        const relyingPartyId = relyingPartyStore?.relyingParty?.id || ""
        const organizationId = organizationStore?.organization?.id || ""
        passlinkRedirectUrlStore?.list(organizationId, relyingPartyId)
    }

    const openAddModal = async () => {
        setState({...state, isAddModalOpen: true})
    }

    const closeAddModal = () => {
        refreshRedirectUrlList()
        setState({...state, isAddModalOpen: false})
    }

    const openRemoveModal = (redirectUrl: PasslinkRedirectUrlModel) => {
        setState({
            ...state,
            isRemoveModalOpen: true,
            selectedRedirectUrl: redirectUrl,
        })
    }

    const closeRemoveModal = () => {
        refreshRedirectUrlList()
        setState({...state, isRemoveModalOpen: false})
    }

    const makeDefault = async (redirectUrl: PasslinkRedirectUrlModel) => {
        const {organizationStore, relyingPartyStore} = props
        const relyingPartyId = relyingPartyStore?.relyingParty?.id || ""
        const organizationId = organizationStore?.organization?.id || ""

        const result = await props.passlinkRedirectUrlStore?.create(organizationId, relyingPartyId, {
            ...redirectUrl,
            default: true
        })
        if (!isApiError(result)) {
            toast.success("Redirect URL set as default")
        } else if (result.code === 401) {
            window.location.assign(BACKEND_LOGIN_REDIRECT_URL(route.url.href))
        } else {
            toast.error("Redirect URL could not be set as default")
        }
    }

    return <SettingsContainerLarge>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div style={{marginRight: '150px'}}>
                <SettingsTitle>Redirect URLs</SettingsTitle>
                <p>
                    <SettingsDescription>
                        Please add all redirect URLs that your project should be able to use. For security reasons, only
                        redirect URLs that are specified here can be used in your requests. The default URL is used if
                        no URL or
                        an
                        unspecified URL is provided in the request.
                    </SettingsDescription>
                </p>
            </div>
            <div>
                <AddNewButton onClick={openAddModal}>Add new</AddNewButton>
            </div>
        </div>
        <RedirectUrlTable
            hidden={!props.passlinkRedirectUrlStore?.passlinkRedirectUrlList || props.passlinkRedirectUrlStore?.passlinkRedirectUrlList?.length === 0}>
            <tbody>
            {
                props.passlinkRedirectUrlStore?.passlinkRedirectUrlList?.map(value => {
                    return <PasslinksTableItem key={value.url} item={value} openRemoveModal={openRemoveModal}
                                            makeDefault={makeDefault}/>
                })
            }
            </tbody>
        </RedirectUrlTable>

        <AddPasslinkRedirectUrlModal
            isOpen={state.isAddModalOpen}
            onClose={closeAddModal}
        />
        <DeletePasslinkRedirectUrlModal
            isOpen={state.isRemoveModalOpen}
            onClose={closeRemoveModal}
            redirectUrl={state.selectedRedirectUrl}
        />

    </SettingsContainerLarge>
}

export const PasslinkSettings = inject('organizationStore', 'relyingPartyStore', 'passlinkRedirectUrlStore')(observer(PasslinkSettingsComponent))
