import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {ReactComponent as ArrowIcon} from "../../assets/arrow_down_thin.svg";
import {ReactComponent as ArrowUpIcon} from "../../assets/arrow_up_thin.svg";
import {inject, observer} from "mobx-react";
import {UserStore} from "../../store/UserStore";
import {Color} from "../atoms/Color";
import {useNavigation} from "react-navi";
import { useMedia } from 'react-media';
import { MEDIA_QUERIES } from '../../constants/MediaQueries';

const IconContainer = styled.div({
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '15px'
})

const Icon = styled(ArrowIcon)({
    width: "12px",
    height: "auto",
    cursor: "pointer",
})

const MenuContainer = styled.div({
    position: "relative",
    top: "27.5px",
    left: "-200px"
})

const Menu = styled.div({
    zIndex: 10,
    width: "198px",
    position: "absolute",
    borderRadius: "5px",
    backgroundColor: "#545B61",
    overflow: "hidden",
})

const Item = styled.div({
    textAlign: "left",
    width: "auto",
    color: "#EEE",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "0.17px",
    fontFamily: "IBM Plex Sans",
    padding: "10px 10px 10px 10px",
    cursor: "pointer",
    ':hover': {
        backgroundColor: Color.darkBlue,
    },
    ':not(:last-child)': {
        borderBottom: "1px solid " + Color.darkBlue,
    },
})

const Photo = styled.img({
    height: '37px',
    width: '37px',
    borderRadius: '50%',
    float: "left",
    margin: '0 10px 0 0',
    padding: 0,
    cursor: "pointer",
})

const UserName = styled.div<{isMobile?: boolean}>((props) => ({
    cursor: 'pointer',
    height: "24px",
    width: "auto",
    maxWidth: props.isMobile ? "190px" : 'auto',
    color: "#EEE",
    fontFamily: "IBM Plex Sans",
    fontSize: "18px",
    fontWeight: "bold",
    letterSpacing: "0.24px",
    lineHeight: "24px",
    float: "left",
    margin: "0 14px 0 0",
    textAlign: "left",
    whiteSpace: "nowrap",
    overflow: props.isMobile ? "hidden" : "visible",
    textOverflow: props.isMobile ? "ellipsis" : "unset"
}))

const DropDownNavBarContainer = styled.div({
    cursor: 'pointer',
    display: 'inline-flex',
    justifyContent: 'end',
    alignItems: 'center'
})

interface Props {
    userStore?: UserStore
    showDropdown?: boolean
}

const DropdownNavBarComponent = (props: Props) => {
    const [isActive, setIsActive] = useState(false);
    const dropDownRef: React.RefObject<HTMLDivElement> = useRef(null)
    const navigation = useNavigation()
    const isMobile = useMedia({ query: MEDIA_QUERIES.tablet });

    const goToAccount = async () => {
        const currentLocation = window.location.toString()
        window.location.assign((props.userStore?.user?.profile_url ?? '') + '?return_to=' + currentLocation)
    }

    const goToOrganizations = () => {
        navigation.navigate("/organization/" + props.userStore?.user?.active_organization_id + "/settings/general")
    }

    const goToSignOut = () => {
        window.location.assign("/api/sign_out")
    }

    const getUserName = (): string => {
        const {userStore} = props
        let name = userStore?.user?.first_name
        if (name !== undefined) {
            if (userStore?.user?.last_name !== undefined) {
                name += " " + userStore?.user?.last_name
            }
        } else {
            name = userStore?.user?.email || ''
        }
        return name
    }

    useEffect(() => {
        const pageClickEvent = (event: MouseEvent) => {
            if (!dropDownRef.current?.contains(event.target as Node)) {
                setIsActive(!isActive);
            }
        };

        if (isActive) {
            window.addEventListener('click', pageClickEvent);
        }

        return () => {
            window.removeEventListener('click', pageClickEvent);
        }

    }, [isActive]);

    const showMenu = (event: MouseEvent) => {
        event.preventDefault();
        setIsActive(!isActive);
    }

    return props.userStore?.user?.id ? (
      <DropDownNavBarContainer onClick={(e: React.MouseEvent<HTMLElement>) => props.showDropdown ? showMenu(e.nativeEvent) : () => {}}>
        {isMobile ? (
          <>
            <Photo src="https://www.gravatar.com/avatar/15bd0ad26072c21eea5a894e74a69165?d=mp" />
            <UserName isMobile={isMobile}>{getUserName()}</UserName>
          </>
        ) : (
          <>
            <UserName isMobile={isMobile}>{getUserName()}</UserName>
            <Photo src="https://www.gravatar.com/avatar/15bd0ad26072c21eea5a894e74a69165?d=mp" />
            <IconContainer>
              {isActive ? (
                <ArrowUpIcon style={{width: "12px"}} fill="#EEEEEE" />
              ) : (
                <Icon style={{width: "12px"}} fill="#EEEEEE" />
              )}
            </IconContainer>
          </>
        )}
        {isActive ? (
          <MenuContainer>
            <Menu>
              <Item onClick={goToAccount}>Account & Security</Item>
              <Item onClick={goToOrganizations}>Manage organization</Item>
              <Item onClick={goToSignOut}>Sign out</Item>
            </Menu>
          </MenuContainer>
        ) : null}
      </DropDownNavBarContainer>
    ) : null;
}

DropdownNavBarComponent.defaultProps = {
    showDropdown: true,
};

export const DropdownNavBar = inject('userStore')(observer(DropdownNavBarComponent))
