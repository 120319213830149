import React, { Suspense, useEffect, useState } from 'react';
import { Provider } from 'mobx-react';
import { NotFoundBoundary, Router, View } from 'react-navi';
import './App.css';
import { userStore } from './store/UserStore';
import { organizationStore } from './store/OrganizationStore';
import { appStateStore } from './store/AppStateStore';
import { relyingPartyStore } from './store/RelyingPartyStore';
import { identityStore } from './store/IdentityProjectStore';
import { hankoMdsStore } from './store/HankoMdsStore';
import { passlinkRedirectUrlStore } from './store/PasslinkRedirectUrlStore';

import { apiKeyStore } from './store/ApiKeyStore';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import styled from 'styled-components';
import { Color } from './components/atoms/Color';
import { routes } from './routes';
import { NotFound } from './NotFound';
import { NavBar } from './components/molecules/NavBar';
import { statisticsStore } from './store/StatisticsStore';
import { Breadcrumbs } from './components/atoms/Breadcrumbs';
import { AccountTypeLabel } from './components/atoms/AccountTypeLabel';
import { oidcClientStore } from './store/OidcClientStore';

const StyledToastContainer = styled(ToastContainer).attrs({})`
  .Toastify__toast--success {
    background: ${Color.primaryGreen};
  }
  .Toastify__toast--error {
    background: ${Color.primaryRed};
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: calc(75% - 12px);
  margin-left: calc(24% + 12px);
`;

const App: React.FC = () => {
  return (
    <Provider
      userStore={userStore}
      organizationStore={organizationStore}
      appStateStore={appStateStore}
      identityStore={identityStore}
      relyingPartyStore={relyingPartyStore}
      apiKeyStore={apiKeyStore}
      statisticsStore={statisticsStore}
      hankoMdsStore={hankoMdsStore}
      passlinkRedirectUrlStore={passlinkRedirectUrlStore}
      oidcClientStore={oidcClientStore}
    >
      <Router routes={routes}>
        <Suspense fallback={null}>
          <div className="App" style={{ flexGrow: 1, backgroundColor: '#161D21' }}>
            <NavBar />
            <div id="contentContainer" style={{ margin: '40px auto 48px auto', minWidth: '1024px', maxWidth: '1705px' }}>
              <HeaderContainer id="headerContainer">
                <Breadcrumbs organization={organizationStore?.organization} />
                <AccountTypeLabel />
              </HeaderContainer>
              <NotFoundBoundary render={() => <NotFound />}>
                <View />
              </NotFoundBoundary>
            </div>
            <StyledToastContainer position="bottom-right" autoClose={5000} hideProgressBar pauseOnHover />
          </div>
        </Suspense>
      </Router>
    </Provider>
  );
};

export default App;
