async function RequestTimeout(url: string, headers?: {}, milliseconds: number = 5000) {
  const controller = new AbortController();
  const foobar = setTimeout(() => {
    controller.abort();
    window.location.href = `${window.location.origin}/error`;
  }, milliseconds);

  return fetch(url, { ...headers, signal: controller.signal }).then((response) => {
    clearTimeout(foobar);

    return response;
  });
}

export default RequestTimeout;
