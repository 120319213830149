import {observable, action} from 'mobx'
import {UserModel} from '../models/UserModel'
import { GET_ORGANIZATIONS_URL, GET_USER_URL, PATCH_USER_URL } from '../constants/Url';
import {ApiError, handleApiResponse, isApiError} from '../helper/ResponseHelper'
import { OrganizationModel } from "../models/OrganizationModel";
import RequestTimeout from '../helper/RequestTimeout';

export class UserStore {

    @observable user?: UserModel = undefined;
    @observable organizations?: OrganizationModel[] = undefined

    @action
    get = async () => {
        const response = await RequestTimeout(GET_USER_URL(), {
            method: 'GET',
            credentials: 'same-origin',
            cache: 'no-cache',
            mode: 'cors'
        });
        return handleApiResponse<UserModel>(response).then(this.success).catch(this.error)
    };

    @action
    update = async () => {
        if (this.user?.id) {
            const response = await RequestTimeout(PATCH_USER_URL(this.user?.id), {
                method: 'PATCH',
                credentials: 'same-origin',
                cache: 'no-cache',
                mode: 'cors',
                body: JSON.stringify({"active_organization_id": this.user.active_organization_id})
            });
            return handleApiResponse<UserModel>(response).then(this.success).catch(this.error)
        }
    };

    @action
    getOrganizations = async () => {
        const response = await RequestTimeout(GET_ORGANIZATIONS_URL(this.user?.id || ''), {
            method: 'GET',
            credentials: 'same-origin',
        })

        return handleApiResponse<OrganizationModel[]>(response).then(this.getOrganizationsSuccess).catch(this.error)
    }

    setActiveOrganizationId = (id: string) => {
        if (this.user) {
            this.user.active_organization_id = id
        }
    };

    @action.bound
    success = (result: UserModel | ApiError) => {
        if (!isApiError(result)) {
            this.user = result
        }
        return result
    };


    @action.bound
    getOrganizationsSuccess = (result: OrganizationModel[] | ApiError) => {
        if (!isApiError(result)) {
            this.organizations = result
        }
        return result
    };

    @action.bound
    error = (reason: any): ApiError => {
        return {
            code: 0,
            message: 'Could not get fetch.'
        }
    }
}

export const userStore = new UserStore();
